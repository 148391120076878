/** @format */

import { useRef, useState, useEffect } from 'react';
function toTitleCase(str) {
	return str.toLowerCase().replace(/\b\w/g, function (char) {
		return char.toUpperCase();
	});
}

const FilterBadges = ({ options, field, handleRemove, optionLength }) => {
	const badgeRef = useRef(null);
	const [showRightArrow, setShowRightArrow] = useState(false);

	const scrollLeft = () => {
		if (badgeRef.current) {
			badgeRef.current.scroll({
				left: badgeRef.current.scrollLeft - 100,
				behavior: 'smooth',
			});
		}
	};

	const scrollRight = () => {
		if (badgeRef.current) {
			badgeRef.current.scroll({
				left: badgeRef.current.scrollLeft + 100,
				behavior: 'smooth',
			});
		}
	};

	useEffect(() => {
		if (badgeRef.current) {
			setShowRightArrow(
				badgeRef.current.scrollLeft <
					badgeRef.current.scrollWidth - badgeRef.current.clientWidth
			);
		}
	}, [options]);

	return (
		<div className='px-2 pt-1 relative'>
			{showRightArrow && (
				<div className='absolute bottom-4 right-0 flex gap-2 items-center'>
					<div onClick={scrollLeft} className='cursor-pointer'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='m15 18-6-6 6-6' />
						</svg>
					</div>

					<div onClick={scrollRight} className='cursor-pointer'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='m9 18 6-6-6-6' />
						</svg>
					</div>
				</div>
			)}

			<div className='overflow-hidden'>
				<div
					ref={badgeRef}
					style={{ scrollbarWidth: 'none' }}
					className='flex overflow-x-auto whitespace-nowrap gap-1 items-center'
				>
					{options.map((titlef, i) => (
						<div
							key={i}
							onClick={() =>
								typeof titlef === 'string'
									? handleRemove(field, titlef)
									: handleRemove(field, titlef.value)
							}
							className='bg-blue-600 hover:bg-red-600 cursor-pointer text-white whitespace-nowrap flex items-center gap-1 text-2xs font-medium rounded-full py-1 px-2'
						>
							{typeof titlef === 'string' ? titlef : titlef.value}
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='3'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-2 h-2'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default FilterBadges;
