/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../Context/AuthContext';
import { CREATE_SUB_ADMIN, EDIT_SUB_ADMIN } from '../../../Context/Types';
import { NotificationManager } from 'react-notifications';

const AddSubadmin = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
		props?.selectedRow?.access_tabs || []
	);
	const { dispatch } = useContext(AuthContext);
	const [info, setInfo] = useState({
		name: props.selectedRow?.name,
		email: props.selectedRow?.email,
		password: '',
	});

	useEffect(() => {
		return () => {
			props.setSelectedRow(null);
		};
	}, []);

	const handleSubmit = () => {
		if (props.selectedRow) {
			let values = {
				...info,
				access_tabs: selectedOption,
			};
			if (!info.password || info.password === '') {
				values = {
					name: info.name,
					email: info.email,
					access_tabs: selectedOption,
				};
			}
			dispatch({
				type: EDIT_SUB_ADMIN,
				id: props.selectedRow._id,
				values: values,
				setIsModal: props.setIsModal,
				updateState: props.updateState,
				setIsLoading: setIsLoading,
			});
		} else {
			if (!info.password || !info.name || !info.email) {
				NotificationManager.error('Please fill all the details.');
				return;
			}
			dispatch({
				type: CREATE_SUB_ADMIN,
				values: {
					...info,
					access_tabs: selectedOption,
				},
				setIsModal: props.setIsModal,
				updateState: props.updateState,
				setIsLoading: setIsLoading,
			});
		}
	};

	const adminTabs = [
		'Sub Admins',
		'Companies',
		'Pending Signups',
		'Subscriptions',
	];

	return (
		<div className='mt-4'>
			<div>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Name:
				</h2>
				<input
					type='text'
					value={info?.name}
					onChange={(e) => setInfo({ ...info, name: e.target.value })}
					placeholder='Enter name'
					required
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
			</div>
			<div className='mt-2'>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Email:
				</h2>
				<input
					type='text'
					value={info?.email}
					onChange={(e) => setInfo({ ...info, email: e.target.value })}
					placeholder='Enter email'
					required
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
			</div>
			<div className='mt-2'>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Password:
				</h2>
				<input
					type='text'
					value={info?.password}
					onChange={(e) => setInfo({ ...info, password: e.target.value })}
					placeholder='Enter password'
					required
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
				{props?.selectedRow && (
					<p className='mt-0.5 ml-1 text-blue-600 text-left text-2xs font-medium'>
						Enter password to change existing one
					</p>
				)}
			</div>
			<div className='mt-3'>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Tabs:
				</h2>
				<div className='mt-2 flex flex-wrap gap-x-2 gap-y-3 items-center'>
					{adminTabs.length > 0 &&
						adminTabs.map((item, i) => (
							<div
								key={i}
								className={`rounded-full ${
									selectedOption.includes(item)
										? 'border-check-active'
										: 'border-check-light'
								}`}
							>
								<h3
									onClick={() => {
										setSelectedOption((prevSelectedOption) => {
											if (prevSelectedOption.includes(item)) {
												return prevSelectedOption.filter(
													(option) => option !== item
												);
											} else {
												return [...prevSelectedOption, item];
											}
										});
									}}
									className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
								>
									<div className='custom-checkbox-filter'>
										<input
											type='checkbox'
											className='mt-0.5'
											checked={selectedOption.includes(item)}
										/>
										<div className='check-mark'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												class='w-2.5 h-2.5'
												viewBox='0 0 20 20'
												fill='currentColor'
												stroke='currentColor'
												stroke-width='1'
											>
												<path
													fill-rule='evenodd'
													d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
													clip-rule='evenodd'
												></path>
											</svg>
										</div>
									</div>
									{item}
								</h3>
							</div>
						))}
				</div>
			</div>

			<button
				disabled={false}
				onClick={handleSubmit}
				className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
			>
				Submit{' '}
				{isLoading && <CircularProgress sx={{ color: 'white' }} size={14} />}
			</button>
		</div>
	);
};

export default AddSubadmin;
