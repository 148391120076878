/** @format */
import React, { useContext, useEffect, useState } from 'react';
import { SPECIALITY_SPECIALITY_TYPE } from '../../Context/filterCompo/filterTypes';
import { MainContext } from '../../Context/FilterContext/FilterContext';
import { AuthContext } from '../../Context/AuthContext';
import debounce from 'debounce-promise';
import { CircularProgress } from '@mui/material';

const HCPFilter = () => {
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setSortField,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		anyFilterFlag,
	} = useContext(AuthContext);

	const [specialityType, setSpecialityTypeOptions] = useState([]);
	const [firstRender, setFirstRender] = useState(false);
	const [firstTimeRun, setFirstTimeRun] = useState(false);
	const [isSpecialitySearch, setIsSpecialitySearch] = useState(false);

	const getSpeciality = debounce((searchValue) => {
		setIsSpecialitySearch(true);
		const getData = async () => {
			let search = '';
			if (searchValue) {
				search = searchValue;
			}
			const data = await globalFilterKeyToApi[SPECIALITY_SPECIALITY_TYPE](
				search
			);
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setSpecialityTypeOptions(options);
			setIsSpecialitySearch(false);
			return options;
		};
		getData();
	}, 300);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const handleSelectAll = () => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		var field = 'specialityType';

		if (filter?.specialityType?.length === 9) {
			setGlobalSelectFilter((prev) => {
				return { ...prev, specialityType: [] };
			});
			setFilter((prev) => {
				return { ...prev, specialityType: [] };
			});
		} else {
			for (const selectedOption of specialityType) {
				setGlobalSelectFilter((prev) => {
					if (!prev[field] || prev[field]?.length === 0) {
						return { ...prev, [field]: [selectedOption.value] };
					} else {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							return { ...prev };
						} else {
							return {
								...prev,
								[field]: [...prev[field], selectedOption.value],
							};
						}
					}
				});
				setFilter((prev) => {
					if (!prev[field] || prev[field]?.length === 0) {
						return { ...prev, [field]: [selectedOption.value] };
					} else {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							return { ...prev };
						} else {
							return {
								...prev,
								[field]: [...prev[field], selectedOption.value],
							};
						}
					}
				});
			}
		}

		setSortField({});
	};

	const handleMostSearchML = () => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		var field = 'specialityType';

		if (
			filter?.specialityType?.length === 4 &&
			['Dentist', 'Healthcare Professional', 'Nurse', 'Physician'].every(
				(value) => {
					return filter?.specialityType.some((element) => {
						return element === value;
					});
				}
			)
		) {
			setGlobalSelectFilter((prev) => {
				return { ...prev, specialityType: [] };
			});
			setFilter((prev) => {
				return { ...prev, specialityType: [] };
			});
		} else {
			for (const selectedOption of specialityType) {
				if (
					selectedOption.value === 'Dentist' ||
					selectedOption.value === 'Healthcare Professional' ||
					selectedOption.value === 'Nurse' ||
					selectedOption.value === 'Physician'
				) {
					setGlobalSelectFilter((prev) => {
						if (!prev[field] || prev[field]?.length === 0) {
							return { ...prev, [field]: [selectedOption.value] };
						} else {
							const index = prev[field]?.findIndex((item) => {
								return item === selectedOption.value;
							});

							if (index !== -1) {
								return { ...prev };
							} else {
								return {
									...prev,
									[field]: [...prev[field], selectedOption.value],
								};
							}
						}
					});
					setFilter((prev) => {
						if (!prev[field] || prev[field]?.length === 0) {
							return { ...prev, [field]: [selectedOption.value] };
						} else {
							const index = prev[field]?.findIndex((item) => {
								return item === selectedOption.value;
							});

							if (index !== -1) {
								return { ...prev };
							} else {
								return {
									...prev,
									[field]: [...prev[field], selectedOption.value],
								};
							}
						}
					});
				} else {
					setGlobalSelectFilter((prev) => {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							const updatedArray = [...prev[field]];
							updatedArray.splice(index, 1);
							return { ...prev, [field]: updatedArray };
						} else {
							return { ...prev };
						}
					});
					setFilter((prev) => {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							const updatedArray = [...prev[field]];
							updatedArray.splice(index, 1);
							return { ...prev, [field]: updatedArray };
						} else {
							return { ...prev };
						}
					});
				}
			}
		}

		setSortField({});
	};

	useEffect(() => {
		getSpeciality();
	}, []);

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>HCP Type</h1>
				<div className='w-full my-2 px-1 rounded-lg'>
					{isSpecialitySearch ? (
						<div className='px-1 text-start text-xs font-medium text-blue-600 flex gap-2 items-center'>
							<CircularProgress size={12} style={{ color: '#2f5eff' }} />
							Loading...
						</div>
					) : (
						<>
							<div className='flex items-center gap-2'>
								<div
									className={`rounded-full ${
										filter?.specialityType &&
										filter?.specialityType?.length === 9
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={handleSelectAll}
										className='flex gap-1 cursor-pointer justify-center items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
									>
										<div className='custom-checkbox-filter'>
											<input
												type='checkbox'
												className='mt-0.5'
												checked={
													filter?.specialityType &&
													filter?.specialityType?.length === 9
												}
											/>
											<div className='check-mark m-0 p-0'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													class='w-2.5 h-2.5'
													viewBox='0 0 20 20'
													fill='currentColor'
													stroke='currentColor'
													stroke-width='1'
												>
													<path
														fill-rule='evenodd'
														d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
														clip-rule='evenodd'
													></path>
												</svg>
											</div>
										</div>
										Select All
									</h3>
								</div>
								<div
									className={`rounded-full ${
										filter?.specialityType?.length === 4 &&
										[
											'Dentist',
											'Healthcare Professional',
											'Nurse',
											'Physician',
										].every((value) => {
											return filter.specialityType.some((element) => {
												return element === value;
											});
										})
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={handleMostSearchML}
										className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
									>
										<div className='custom-checkbox-filter'>
											<input
												type='checkbox'
												className='mt-0.5'
												checked={
													filter?.specialityType?.length === 4 &&
													[
														'Dentist',
														'Healthcare Professional',
														'Nurse',
														'Physician',
													].every((value) => {
														return filter?.specialityType.some((element) => {
															return element === value;
														});
													})
												}
											/>
											<div className='check-mark'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													class='w-2.5 h-2.5'
													viewBox='0 0 20 20'
													fill='currentColor'
													stroke='currentColor'
													stroke-width='1'
												>
													<path
														fill-rule='evenodd'
														d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
														clip-rule='evenodd'
													></path>
												</svg>
											</div>
										</div>
										Most Searched HCP
									</h3>
								</div>
							</div>

							<div className='mt-3 flex flex-wrap gap-x-1 gap-y-2 items-center'>
								{specialityType.length > 0 &&
									specialityType.map((level, i) => (
										<div
											key={i}
											className={`rounded-full ${
												filter?.specialityType &&
												filter?.specialityType?.findIndex((e) => {
													if (typeof e === 'number') {
														return e === level.value;
													} else {
														return e === level.label;
													}
												}) !== -1
													? 'border-check-active'
													: 'border-check-light'
											}`}
										>
											<h3
												onClick={() => handleSelect('specialityType', level)}
												className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
											>
												<div className='custom-checkbox-filter'>
													<input
														type='checkbox'
														className='mt-0.5'
														checked={
															filter?.specialityType &&
															filter?.specialityType?.findIndex((e) => {
																if (typeof e === 'number') {
																	return e === level.value;
																} else {
																	return e === level.label;
																}
															}) !== -1
														}
													/>
													<div className='check-mark'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															class='w-2.5 h-2.5'
															viewBox='0 0 20 20'
															fill='currentColor'
															stroke='currentColor'
															stroke-width='1'
														>
															<path
																fill-rule='evenodd'
																d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																clip-rule='evenodd'
															></path>
														</svg>
													</div>
												</div>
												{level.label}
											</h3>
										</div>
									))}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default HCPFilter;
