/** @format */

import React, { useContext, useState } from 'react';
import logo from '../../assests/image/companylogo.png';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { LOGIN_USER } from '../../Context/Types';
import useBrowser from '../../hooks/useBrowser';
import useArea from '../../hooks/useArea';
import { NotificationManager } from 'react-notifications';
import Info from './Info';

const LoginScreen = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);
	const { dispatch } = useContext(AuthContext);
	const { browserName } = useBrowser();
	const { area } = useArea();
	let captchaRef;

	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState({
		email: '',
		password: '',
	});

	const handleSubmit = (values, resetForm) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(initialValues?.email)) {
			NotificationManager.error('Invalid email format');
			return;
		}
		if (initialValues?.password?.length < 6) {
			NotificationManager.error('Password must be at least 6 characters long');
			return;
		}

		const withCaptcha = {
			...initialValues,
			captcha_token: captchaToken,
			browserType: browserName,
			location: area.city ? area.city : '',
		};
		dispatch({
			type: LOGIN_USER,
			payload: withCaptcha,
			resetForm: resetForm,
			setIsLoading: setIsLoading,
			handleCaptchaChange: handleCaptchaChange,
		});
	};

	const onChangeCaptcha = (value) => {
		setIsCaptchaVerified(true);
		setCaptchaToken(value);
	};

	const handleCaptchaChange = () => {
		// alert("CAPTCHA RESET");
		if (isCaptchaVerified) {
			captchaRef.reset();
			setIsCaptchaVerified(false);
		}
	};

	return (
		<>
			<div className='min-h-screen w-full grid grid-cols-2 lg:grid-cols-3 bg-white text-left'>
				<div class='min-h-screen max-w-[36rem] mx-auto flex flex-col size-full bg-white col-span-2'>
					<header class='mb-auto z-50 w-full py-4'>
						<nav class='px-4 sm:px-6 lg:px-8'>
							<img src={logo} alt='HealthDBi' className='h-12 w-auto' />
						</nav>
					</header>

					<main id='content'>
						<div class='py-10 px-4 sm:px-6 lg:px-8'>
							<h1 class='block text-xl font-bold text-gray-800'>
								Login to your HealthDBi account
							</h1>
							<p class='mt-0.5 text-gray-600 text-base'>Welcome Back!</p>

							<div className='mt-4'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Email:
								</h2>
								<input
									type='text'
									value={initialValues?.email}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											email: e.target.value,
										})
									}
									placeholder='Enter your email'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3 relative'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Password:
								</h2>
								<input
									type={passwordType}
									value={initialValues?.password}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											password: e.target.value,
										})
									}
									placeholder='Enter your password'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
								<p
									onClick={() => {
										navigate('/forgotpassword');
									}}
									className='mt-1 text-right text-blue-600 font-medium text-xs cursor-pointer hover:underline hover:text-blue-700'
								>
									Forgot Password?
								</p>
								{passwordType === 'password' ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										onClick={() => setPasswordType('text')}
										class='size-5 absolute text-gray-700 top-9 right-4 cursor-pointer'
									>
										<path d='M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0' />
										<circle cx='12' cy='12' r='3' />
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										onClick={() => setPasswordType('password')}
										class='size-5 absolute text-gray-700 top-9 right-4 cursor-pointer'
									>
										<path d='M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49' />
										<path d='M14.084 14.158a3 3 0 0 1-4.242-4.242' />
										<path d='M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143' />
										<path d='m2 2 20 20' />
									</svg>
								)}
							</div>
							<div className='mt-2'>
								<ReCAPTCHA
									ref={(r) => {
										if (r) {
											return (captchaRef = r);
										}
									}}
									sitekey='6LeT9UwhAAAAAHIV4xebP9-8WibYs5iufcHiYGW8'
									onChange={onChangeCaptcha}
								/>
							</div>

							<button
								onClick={handleSubmit}
								class='mt-5 w-full !py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
							>
								{isLoading ? (
									<div
										class='animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full'
										role='status'
										aria-label='loading'
									>
										<span class='sr-only'>Loading...</span>
									</div>
								) : (
									'Login'
								)}
							</button>
						</div>
					</main>
					<footer class='mt-auto text-left pb-5'>
						<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
							<p class='text-sm text-gray-700'>
								Don't have an account yet?{' '}
								<span
									onClick={() => {
										navigate('/signUp');
									}}
									className='text-blue-600 cursor-pointer hover:underline hover:text-blue-700'
								>
									Sign up here
								</span>
							</p>
						</div>
					</footer>
				</div>

				<Info />
			</div>
		</>
	);
};

export default LoginScreen;
