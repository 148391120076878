/** @format */

import { NotificationManager } from 'react-notifications';
import { editProfileRequest, getProfileRequest } from '../APIs';

export const getProfile = async (setProfile) => {
	try {
		const res = await getProfileRequest();
		setProfile(res.data);
		localStorage.setItem('role', res.data.type);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	}
};

export const editProfile = async (
	values,
	setIsLoading,
	setIsEdit,
	setProfile
) => {
	setIsLoading(true);
	try {
		const res = await editProfileRequest({
			...values,
			mobile: values.mobile === 'null' ? '' : values.mobile,
		});
		if (res.data) {
			NotificationManager.success(res.data);
			getProfile(setProfile);
			setIsEdit(false);
		}
	} catch (e) {
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
		console.log(e);
	} finally {
		setIsLoading(false);
	}
};
