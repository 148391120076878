/** @format */

import {
	getInvoices,
	getCompanyInvoicesAdmin,
	payInvoice,
	markAsPaidInvoice,
} from '../APIs';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export const getInvoicesReducer = async (
	updateState,
	setIsLoading,
	setallData
) => {
	setIsLoading(true);
	try {
		const res = await getInvoices();

		updateState(res.data);
		setallData(res.data);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const getCompanyInvoicesAdminReducer = async (
	id,
	updateState,
	setIsLoading,
	setallData
) => {
	setIsLoading(true);
	try {
		const res = await getCompanyInvoicesAdmin(id);
		const formatedData = res.data.map((item) => {
			return {
				...item,
				amount: `${item.amount}`,
			};
		});
		updateState(formatedData);
		setallData(formatedData);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const markAsPaidInvoiceReducer = async (
	values,
	setIsLoading,
	updateState,
	setallData,
	closeModal
) => {
	setIsLoading(true);
	try {
		const res = await markAsPaidInvoice(values);
		if (res.data) {
			NotificationManager.success(res.data);
			closeModal();
			getCompanyInvoicesAdminReducer(
				values.company_id,
				updateState,
				() => {},
				setallData
			);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const payInvoiceReducer = async (values, setIsLoading) => {
	setIsLoading(true);
	try {
		const res = await payInvoice(values);
		if (res.data) {
			window.location = res.data.link;
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
