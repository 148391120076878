/** @format */
import React, { useContext, useEffect, useState } from 'react';

import { MainContext } from '../../Context/FilterContext/FilterContext';
import { AuthContext } from '../../Context/AuthContext';
import { FILTER_CONTACTS } from '../../Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { CircularProgress } from '@mui/material';

const OfficeFilter = () => {
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setSortField,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		anyFilterFlag,
	} = useContext(AuthContext);

	const [firstTimeRun, setFirstTimeRun] = useState(false);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const officetypeHandler = (value) => {
		if (value !== 'Hospital' && value !== 'Offices') {
			setGlobalSelectFilter((prev) => {
				return { ...prev, officetype: [] };
			});
			setFilter((prev) => {
				return {
					...prev,
					officetype: [],
				};
			});
		} else if (value === 'Hospital') {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, officetype: ['Hospital'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					officetype: ['Hospital'],
				};
			});
		} else {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, officetype: ['Offices'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					officetype: ['Offices'],
				};
			});
		}
	};

	const emailtypeHandler = (value) => {
		if (value !== 'Personal Email' && value !== 'B2B') {
			setGlobalSelectFilter((prev) => {
				return { ...prev, emailtype: [] };
			});
			setFilter((prev) => {
				return {
					...prev,
					emailtype: [],
				};
			});
		} else if (value === 'Personal Email') {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, emailtype: ['Personal Email'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					emailtype: ['Personal Email'],
				};
			});
		} else {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, emailtype: ['B2B'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					emailtype: ['B2B'],
				};
			});
		}
	};

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Office Based</h1>
				<div className='w-full my-2 rounded-lg'>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
						<div
							className={`rounded-full ${
								filter?.officetype && filter?.officetype?.length === 0
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => officetypeHandler('All')}
								className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradionull'}
									type='radio'
									checked={
										filter?.officetype && filter?.officetype?.length === 0
									}
									defaultChecked
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
								/>
								All
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.officetype &&
								filter?.officetype?.length > 0 &&
								filter?.officetype[0] === 'Hospital'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => officetypeHandler('Hospital')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradio1'}
									type='radio'
									checked={
										filter?.officetype &&
										filter?.officetype?.length > 0 &&
										filter?.officetype[0] === 'Hospital'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 '
								/>
								Hospital
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.officetype &&
								filter?.officetype?.length > 0 &&
								filter?.officetype[0] === 'Offices'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => officetypeHandler('Offices')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradio2'}
									type='radio'
									checked={
										filter?.officetype &&
										filter?.officetype?.length > 0 &&
										filter?.officetype[0] === 'Offices'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 '
								/>
								Offices
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>

			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Email Type</h1>
				<div className='w-full my-2 rounded-lg'>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
						<div
							className={`rounded-full ${
								filter?.emailtype && filter?.emailtype?.length === 0
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => emailtypeHandler('All')}
								className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'emailradionull'}
									type='radio'
									checked={filter?.emailtype && filter?.emailtype?.length === 0}
									defaultChecked
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 '
								/>
								All
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.emailtype &&
								filter?.emailtype?.length > 0 &&
								filter?.emailtype[0] === 'Personal Email'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => emailtypeHandler('Personal Email')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'emailradio1'}
									type='radio'
									checked={
										filter?.emailtype &&
										filter?.emailtype?.length > 0 &&
										filter?.emailtype[0] === 'Personal Email'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 '
								/>
								Personal Email
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.emailtype &&
								filter?.emailtype?.length > 0 &&
								filter?.emailtype[0] === 'B2B'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => emailtypeHandler('B2B')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'emailradio2'}
									type='radio'
									checked={
										filter?.emailtype &&
										filter?.emailtype?.length > 0 &&
										filter?.emailtype[0] === 'B2B'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 '
								/>
								B2B
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OfficeFilter;
