import { NotificationManager } from "react-notifications";
import { forgetPassword, resendEmail } from "../APIs";

export const handleEmail = async (email, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await resendEmail(email);
    NotificationManager.success(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const handleForgetPassword = async (email, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await forgetPassword(email);
    if (res.data) {
      NotificationManager.success(res.data);
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};
