import { NotificationManager } from "react-notifications";
import { resetPassword, setNewPassword, signUp, teamSignUp } from "../APIs";
//signup
export const handleSignup = async (
  values,
  resetForm,
  setIsLoading,
  navigate
) => {
  setIsLoading(true);
  let data;

  if(values?.fpr && String(values?.fpr)?.length > 0) {
    data = {
      email: String(values.email).trim(),
      name: String(values.name).trim(),
      password: values.confirm_password,
      confirm_password: values?.confirm_password,
      mobile: String(values.mobile).trim(),
      company_name: String(values.company_name).trim(),
      captcha_token: values?.captcha_token,
      fpr: values?.fpr
    }
  }else {
    data = {
      email: String(values.email).trim(),
      name: String(values.name).trim(),
      password: values.confirm_password,
      confirm_password: values?.confirm_password,
      mobile: String(values.mobile).trim(),
      captcha_token: values?.captcha_token,
      company_name: String(values.company_name).trim(),
    }
  }
  try {
    const res = await signUp(data);
    if (res.data === "Account Activation Mail Sent!") {
      localStorage.removeItem("fpr_health");
      navigate("/email", {
        state: {
          email: String(values.email).trim(),
          name: String(values.name).trim(),
          password: values.confirm_password,
          mobile: String(values.mobile).trim(),
          companyName: String(values.company_name).trim(),
        },
      });
      // resetForm({ values: "" });
    }
  } catch (e) {
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};
export const changePassword = async (values, resetForm, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await resetPassword(values);
    if (res.data) {
      NotificationManager.success(res.data);
      resetForm({ values: "" });
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const newPassword = async (values, setIsloading) => {
  setIsloading(true);
  try {
    const res = await setNewPassword(values);
    if (res.data) {
      NotificationManager.success(res.data);
      setTimeout(() => {
        window.location.replace("/");
      }, 2000);
    }
  } catch (e) {
    console.log(e.response);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsloading(false);
  }
};

//Team Sign up

export const handleTeamSignup = async (
  values,
  resetForm,
  setIsLoading,
  navigate
) => {
  setIsLoading(true);
  try {
    const res = await teamSignUp(values);
    console.log(res);
    if (res.data === "Sign Up Successful!") {
      NotificationManager.success(res.data);
      resetForm({ values: "" });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
