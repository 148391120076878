/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import DataTable from './DataTable';
import { CircularProgress } from '@mui/material';
import SaveSearchModel from './Components/SaveSearchModel';
import PartyEmoji from '../../assests/image/welcome.png';
import ConfettiExplosion from 'react-confetti-explosion';
import AddPopUpModel from './Components/AddPopUpModel';
import { AuthContext } from '../../Context/AuthContext';
import {
	FILTER_CONTACTS,
	GET_PROFILE,
	GET_DOWNLOAD_QUEUE,
	GET_SAVE_SEARCH,
} from '../../Context/Types';
import { DownloadPopUpModel } from './Components/DownloadOptions';
import Filter from './Filter';
import { MainContext } from '../../Context/FilterContext/FilterContext';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../Context/filterCompo/filterTypes';
import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import OverlayModal from '../../Elements/Misc/Modal';
import { QueuePopUpModel } from './Components/DownloadQueue';
import WelcomeTour from '../../Elements/Misc/WelcomeTour';

const executiveColumns = [
	{
		field: '_id',
		headerName: '_id',
		filterField: '_id',
		hide: false,
	},

	{
		field: 'FullName',
		headerName: 'Name',
		filterField: 'contact_name',
		hide: false,
	},

	{
		field: 'Title',
		headerName: 'Job Title',
		filterField: 'tabletitle',
		hide: false,
	},
	{
		field: 'Hospital_Doctor',
		headerName: 'Hospital/Clinic',
		filterField: 'tablehospitalName',
		hide: false,
	},

	{
		field: 'EmailAddress',
		headerName: 'Email',
		filterField: 'EmailAddress',
		hide: false,
	},
	{
		field: 'PhoneNumber',
		headerName: 'Phone',
		filterField: 'PhoneNumber',
		hide: false,
	},
	{
		field: 'Department',
		headerName: 'Department',
		filterField: 'tabledepartment',
		hide: true,
	},
	{
		field: 'HospitalType',
		headerName: 'Facility Type',
		filterField: 'tablehospitalType',
		hide: false,
	},

	{
		field: 'City',
		headerName: 'City',
		filterField: 'tablecity',
		hide: true,
	},
	{
		field: 'State',
		headerName: 'State',
		filterField: 'tablestate',
		hide: false,
	},
	{
		field: 'FirmType',
		headerName: 'Firm Type',
		filterField: 'tablefirmType',
		hide: false,
	},
	{
		field: 'WebAddress',
		headerName: 'Web Address',
		filterField: 'tablewebaddress',
		hide: false,
	},

	{
		field: 'Ownership',
		headerName: 'Ownership',
		filterField: 'tableownership',
		hide: false,
	},
	{
		field: 'BedsRange',
		headerName: 'Beds Range',
		filterField: 'tablebedrange',
		hide: true,
	},
	{
		field: 'Action',
		headerName: 'Action',
		filterField: 'Action',
		hide: false,
	},
];
const physicianColumns = [
	{
		field: '_id',
		headerName: '_id',
		filterField: '_id',
		hide: false,
	},
	{
		field: 'FullName',
		headerName: 'Name',
		filterField: 'contact_name',
		hide: false,
	},
	{
		field: 'Specialty',
		headerName: 'Specialty',
		filterField: 'tablespeciality',
		hide: false,
	},
	{
		field: 'SpecialtyGroup1',
		headerName: 'Specialty Group',
		filterField: 'tablespecialityGroup',
		hide: true,
	},
	{
		field: 'SpecialtyType',
		headerName: 'HCP Type',
		filterField: 'tablespecialityType',
		hide: true,
	},
	{
		field: 'Hospital_Doctor',
		headerName: 'Hospital/Clinic',
		filterField: 'tablehospitalName',
		hide: false,
	},
	{
		field: 'EmailAddress',
		headerName: 'Email',
		filterField: 'EmailAddress',
		hide: false,
	},
	{
		field: 'PhoneNumber',
		headerName: 'Phone',
		filterField: 'PhoneNumber',
		hide: false,
	},

	{
		field: 'City',
		headerName: 'City',
		filterField: 'tablecity',
		hide: true,
	},
	{
		field: 'State',
		headerName: 'State',
		filterField: 'tablestate',
		hide: false,
	},

	{
		field: 'NPINumber',
		headerName: 'NPI Number',
		filterField: 'tablenpi',
		hide: true,
	},
	{
		field: 'degree',
		headerName: 'Degree',
		filterField: 'tabledegree',
		hide: true,
	},
	{
		field: 'Action',
		headerName: 'Action',
		filterField: 'Action',
		hide: false,
	},
];

const ContactSearchManagement = (props) => {
	const {
		dispatch,
		setAllContacts,
		setsearchTerm,
		setSelectedLeads,
		filter,
		setFilter,
		numOfRecordsFound,
		profile,
		page,
		rowsPerPage,
		anyFilterFlag,
		setSortField,
		typesOfFilter,
		setTypesOfFilter,
		numOfRecordsFoundLoading,

		setFirstTimeApiCall,
		setFirstTimeFilter,
		setPage,
		setTitleInput,

		setCountryInput,

		setCityInput,
		setStateInput,

		availableStats,
		availableLoading,

		setValue,
		setSpecialityInputValue,
		setSpecialityInputValue1,
		setSpecialityInputValue2,
		setTypeInputValue,
		setZipInput,
		setLicenseInput,
		setLevelInput,
		setFacilityInput,
		setNetworkInput,
		setOwnerInput,
		setGeoInput,
		setSelectedVal,
	} = useContext(AuthContext);
	const { globalSelectFilter, setGlobalSelectFilter } = useContext(MainContext);
	var a = true;

	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isExploding, setIsExploding] = useState(false);
	const [filterToggle, setfilterToggle] = useState(false);
	const [addlistmodel, setaddlistmodel] = useState(false);
	const [saveSearch, setSaveSearch] = useState(false);
	const [download, setdownload] = useState(false);
	const [downloadQueues, setDownloadQueues] = useState([]);
	const [moreOptions, setMoreOptions] = useState(false);
	const [showCols, setshowCols] = useState(false);
	const [filterShow, setFilterShow] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const location = useLocation();
	const [modalOpen, setModalOpen] = useState(false);
	const [welcomeModal, setWelcomeModal] = useState(false);
	const [startTour, setStartTour] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
		}, 200);
	};

	useEffect(() => {
		let dataType = 0;
		if (typesOfFilter === PHYSICIAN_FILTER) {
			dataType = 0;
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			dataType = 1;
		}
		dispatch({
			type: GET_SAVE_SEARCH,
			updateState: setRows,
			setIsLoading: setIsLoading,
			dataType: dataType,
		});
	}, [typesOfFilter]);

	useEffect(() => {
		if (isLoading === false) {
			setTimeout(() => {
				setWelcomeModal(!profile?.isOnboard);
				setIsExploding(true);
			}, 700);
		}
	}, [profile, isLoading]);

	const closeWelcomeModal = () => {
		setTimeout(() => {
			setWelcomeModal(false);
			setTimeout(() => {
				setStartTour(true);
			}, 500);
		}, 200);
	};

	const handleClickFilter = () => {
		return setfilterToggle((prev) => !prev);
	};

	const handleDefaultFilter = (key, value) => {
		setFilter((prev) => {
			return {
				...prev,
				[key[0]]: Array.isArray(value[0]) ? value[0] : [value[0].value],
				[key[1]]: Array.isArray(value[1]) ? value[1] : [value[1].value],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				[key[0]]: Array.isArray(value[0]) ? value[0] : [value[0].value],
				[key[1]]: Array.isArray(value[1]) ? value[1] : [value[1].value],
			};
		});
		handleFilterSubmit();
	};

	const filterItems = JSON.parse(localStorage.getItem('myFilters'));

	const handleClearSearch = () => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setSpecialityInputValue(''),
			setSpecialityInputValue1(''),
			setSpecialityInputValue2(''),
			setTypeInputValue(''),
			setCountryInput(''),
			setStateInput(''),
			setCityInput(''),
			setZipInput(''),
			setLicenseInput(''),
			setTitleInput(''),
			setLevelInput(''),
			setSelectedVal('');
		setFacilityInput(''),
			setNetworkInput(''),
			setOwnerInput(''),
			setGeoInput(''),
			setSortField({});
		setValue([0, 99]);
		setSortField({
			contact_name_sort: null,
			address_sort: null,
			city_sort: null,
			state_sort: null,
			country_sort: null,
			zipcode_sort: null,
			speciality_sort: null,
			specialityType_sort: null,
			specialityGroup_sort: null,
			gender_sort: null,
			title_sort: null,
			hospitalType_sort: null,
			firmType_sort: null,
			levelID_sort: null,
			ownership_sort: null,
			geographic_sort: null,
			experience_sort: null,
			licenseState_sort: null,
			npi_sort: null,
			degree_sort: null,
			hospitalName_sort: null,
			department_sort: null,
			webaddress_sort: null,
			bedrange_sort: null,
		});
		setFilter({
			contact_name: [],
			address: [],
			city: [],
			state: [],

			tabletitle: [],
			tablehospitalName: [],
			tablehospitalType: [],
			tabledepartment: [],
			tablecity: [],
			tablestate: [],
			tablespeciality: [],
			tablespecialityGroup: [],
			tablespecialityType: [],
			tablewebaddress: [],
			tablefirmType: [],
			tableownership: [],
			tablebedrange: [],
			tabledegree: [],
			tablenpi: [],

			country: [],
			zipcode: [],
			speciality: [],
			specialityType: [],
			specialityGroup: [],
			gender: [],
			title: [],
			hospitalType: [],
			firmType: [],
			levelID: [],
			ownership: [],
			geographic: [],
			experience: [],
			licenseState: [],
			npi: [],
			//new
			emailtype: [],
			degree: [],
			hospitalName: [],
			department: [],
			webaddress: [],
			bedrange: [],
		});
		let emptyGlobalSelectFilter = {};
		for (const [key, value] of Object.entries(globalSelectFilter)) {
			emptyGlobalSelectFilter[key] = [];
		}
		setGlobalSelectFilter(emptyGlobalSelectFilter);
		setAllContacts([]);
		setSelectedLeads([]);
		anyFilterFlag.current = false;
		// setTypesOfFilter(PHYSICIAN_FILTER);
		// dispatch({
		//   type: FILTER_CONTACTS,
		//   setAllContacts: setAllContacts,
		//   page: page,
		//   limit: rowsPerPage,
		// });
	};

	useEffect(() => {
		if (typesOfFilter === PHYSICIAN_FILTER) {
			setColumns(physicianColumns);
			if (location.state !== 'FROM_SAVE_SEARCH') handleClearSearch();
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			setColumns(executiveColumns);
			if (location.state !== 'FROM_SAVE_SEARCH') handleClearSearch();
		}
	}, [typesOfFilter]);

	useEffect(() => {
		return () => {
			handleClearSearch();
			setTypesOfFilter(PHYSICIAN_FILTER);
		};
	}, []);

	function areAllArraysEmpty(obj) {
		for (let key in obj) {
			if (Array.isArray(obj[key]) && obj[key].length > 0) {
				return false;
			}
		}
		return true;
	}

	useEffect(() => {
		dispatch({ type: GET_PROFILE });
		dispatch({
			type: GET_DOWNLOAD_QUEUE,
			updateState: setDownloadQueues,
			setIsLoading: setIsLoading,
		});
	}, []);

	const handleFilterSubmit = () => {
		setFirstTimeApiCall(false);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setPage(1);
		dispatch({
			type: FILTER_CONTACTS,
		});
		anyFilterFlag.current = true;
	};

	const navigate = useNavigate();

	useEffect(() => {
		if (areAllArraysEmpty(filter)) {
			handleClose();
		}
	}, [filter]);

	const [isDownloadOpen, setIsDownloadOpen] = useState(false);

	const toggleDownloadDropdown = () => {
		setIsDownloadOpen(!isDownloadOpen);
	};

	const [isQueueOpen, setIsQueueOpen] = useState(false);

	const toggleQueueDropdown = () => {
		setIsQueueOpen(!isQueueOpen);
	};

	useEffect(() => {
		if (refresh) {
			dispatch({
				type: GET_DOWNLOAD_QUEUE,
				updateState: setDownloadQueues,
				setIsLoading: setIsLoading,
			});
		}
		if (!isDownloadOpen) {
			dispatch({
				type: GET_DOWNLOAD_QUEUE,
				updateState: setDownloadQueues,
				setIsLoading: setIsLoading,
			});
		}
	}, [refresh, isDownloadOpen]);

	return (
		<div className='relative w-full flex h-full overflow-hidden'>
			<Filter
				navigate={navigate}
				hideShow={filterToggle}
				hideShowClick={handleClickFilter}
				isResult={anyFilterFlag?.current}
				setSaveSearch={setSaveSearch}
			></Filter>
			{!anyFilterFlag?.current ? (
				<div className='ml-48 pr-4 w-full sm:px-6 lg:px-8 flex justify-center items-center'>
					<div className='w-1/2 text-center'>
						<h2 className='text-gray-900 font-semibold text-xl'>
							Welcome {profile ? profile?.name.split(' ')[0] : 'Folk'}, Make a
							Quick Search
						</h2>
						<p className='text-xs text-gray-600 font-medium mt-2'>
							Easily find what you're looking for with these most searched
							criteria. Simply select one and explore your data hassle-free!
						</p>
						{typesOfFilter === EXECUTIVE_FILTER ? (
							<div className='mt-4 grid grid-cols-2 gap-2'>
								<div
									onClick={() =>
										handleDefaultFilter(
											['title', 'country', 'type'],
											[
												{
													value: 'Manager',
													label: 'Manager',
												},
												{
													value: 'USA',
													label: 'USA',
												},
												{
													value: '1',
													label: '1',
												},
											]
										)
									}
									className='bg-white text-start rounded-lg border border-gray-300 cursor-pointer welcomesearch'
								>
									<span className='mx-2 my-2 flex gap-2 items-center text-sm text-gray-900 welcomesearchtitle font-semibold'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-4 h-4'
										>
											<circle cx='11' cy='11' r='8' />
											<path d='m21 21-4.3-4.3' />
										</svg>
										Managers in USA
									</span>
									<div className='flex justify-between items-center px-2 my-1 mt-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											Job Title
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											Manager
										</div>
									</div>
									<div className='flex justify-between items-center px-2 my-1 mb-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											Country
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											USA
										</div>
									</div>
								</div>
								<div
									onClick={() =>
										handleDefaultFilter(
											['title', 'firmType', 'type'],
											[
												{
													value: 'Director',
													label: 'Director',
												},
												{
													value: 'Hospital',
													label: 'Hospital',
												},
												{
													value: '1',
													label: '1',
												},
											]
										)
									}
									className='bg-white text-start rounded-lg border border-gray-300 cursor-pointer welcomesearch'
								>
									<span className='mx-2 my-2 flex gap-2 items-center text-sm text-gray-900 welcomesearchtitle font-semibold'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-4 h-4'
										>
											<circle cx='11' cy='11' r='8' />
											<path d='m21 21-4.3-4.3' />
										</svg>
										Hospital Directors
									</span>
									<div className='flex justify-between items-center px-2 my-1 mt-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											Job Title
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											Director
										</div>
									</div>
									<div className='flex justify-between items-center px-2 my-1 mb-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											Network
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											Hospital
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className='mt-4 grid grid-cols-2 gap-2'>
								<div
									onClick={() =>
										handleDefaultFilter(
											['specialityType', 'country', 'type'],
											[
												{
													value: 'Physician',
													label: 'Physician',
												},
												{
													value: 'USA',
													label: 'USA',
												},
												{
													value: '0',
													label: '0',
												},
											]
										)
									}
									className='bg-white text-start rounded-lg border border-gray-300 cursor-pointer welcomesearch'
								>
									<span className='mx-2 my-2 flex gap-2 items-center text-sm text-gray-900 welcomesearchtitle font-semibold'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-4 h-4'
										>
											<circle cx='11' cy='11' r='8' />
											<path d='m21 21-4.3-4.3' />
										</svg>
										Physicians in USA
									</span>
									<div className='flex justify-between items-center px-2 my-1 mt-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											HCP
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											Physician
										</div>
									</div>
									<div className='flex justify-between items-center px-2 my-1 mb-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											Country
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											USA
										</div>
									</div>
								</div>
								<div
									id='step2'
									onClick={() =>
										handleDefaultFilter(
											['specialityType', 'country', 'type'],
											[
												{
													value: 'Healthcare Professional',
													label: 'Healthcare Professional',
												},
												{
													value: 'USA',
													label: 'USA',
												},
												{
													value: '0',
													label: '0',
												},
											]
										)
									}
									className='bg-white text-start rounded-lg border border-gray-300 cursor-pointer welcomesearch'
								>
									<span className='mx-2 my-2 flex gap-2 items-center text-sm text-gray-900 welcomesearchtitle font-semibold'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-4 h-4'
										>
											<circle cx='11' cy='11' r='8' />
											<path d='m21 21-4.3-4.3' />
										</svg>
										Healthcare Professionals in USA
									</span>
									<div className='flex justify-between items-center px-2 my-1 mt-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											HCP
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											Healthcare Professional
										</div>
									</div>
									<div className='flex justify-between items-center px-2 my-1 mb-2'>
										<p className='text-xs font-medium text-gray-800 welcomesearchtext'>
											Country
										</p>
										<div className='rounded-full py-1 px-2 bg-blue-600 welcomesearchfilter text-white text-2xs'>
											USA
										</div>
									</div>
								</div>
							</div>
						)}
						<p className='text-xs text-gray-600 font-medium mt-4'>
							Or select from your{' '}
							<a
								href='/mylist?active=search'
								className='anchor text-blue-600 hover:!text-blue-700'
							>
								saved searches.
							</a>
						</p>
					</div>
				</div>
			) : (
				<div class='ml-48 pr-4 w-full mt-10 sm:px-6 lg:px-8 lg:mt-16 overflow-hidden'>
					<div className='bg-white border-none border-slate-200 rounded-lg overflow-hidden'>
						<div class='px-2 py-2 grid gap-1 md:flex md:justify-between md:items-center border-b border-slate-200'>
							<div id='step7' className='flex gap-8 items-center'>
								<div className='text-left'>
									<h2 class='text-lg font-extrabold text-gray-800'>
										{numOfRecordsFoundLoading ? (
											<CircularProgress
												size={18}
												style={{ color: '#0f172a' }}
											/>
										) : (
											numOfRecordsFound?.toLocaleString('en-US')
										)}
									</h2>
									<p
										id='my-tooltip-total'
										data-tooltip-delay-hide={100}
										data-tooltip-html={
											'Total number of contacts based on<br /> your search criteria.'
										}
										class='text-xs font-medium text-gray-700 flex items-center cursor-pointer'
									>
										Total Contacts{' '}
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='flex-shrink-0 w-3 h-3 ml-1'
										>
											<circle cx='12' cy='12' r='10' />
											<path d='M12 16v-4' />
											<path d='M12 8h.01' />
										</svg>
									</p>
									<Tooltip
										anchorSelect='#my-tooltip-total'
										place='bottom-start'
										style={{
											backgroundColor: '#0f172a',
											color: 'white',
											fontSize: '12px',
											zIndex: 10,
										}}
									/>
								</div>
								<div className='text-left'>
									<h2 class='text-lg font-extrabold text-gray-800'>
										{availableLoading ? (
											<CircularProgress
												size={18}
												style={{ color: '#0f172a' }}
											/>
										) : (
											availableStats?.toLocaleString('en-US')
										)}
									</h2>
									<p
										id='my-tooltip-netnew'
										data-tooltip-delay-hide={100}
										data-tooltip-html={
											'Total contacts excluding your previously<br /> unlocked/downloaded leads.'
										}
										class='text-xs font-medium text-gray-700 flex items-center cursor-pointer'
									>
										Net New{' '}
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='flex-shrink-0 w-3 h-3 ml-1'
										>
											<circle cx='12' cy='12' r='10' />
											<path d='M12 16v-4' />
											<path d='M12 8h.01' />
										</svg>
									</p>
									<Tooltip
										anchorSelect='#my-tooltip-netnew'
										place='bottom-start'
										style={{
											backgroundColor: '#0f172a',
											color: 'white',
											fontSize: '12px',
											zIndex: 10,
										}}
									/>
								</div>
							</div>

							<div>
								<div id='step8' class='inline-flex gap-x-2'>
									<div
										class='cursor-pointer cta py-2 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white hover:bg-gray-100 text-gray-800 disabled:opacity-50 disabled:pointer-events-none'
										onClick={toggleDownloadDropdown}
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='flex-shrink-0 w-3 h-3'
										>
											<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
											<polyline points='7 10 12 15 17 10' />
											<line x1='12' x2='12' y1='15' y2='3' />
										</svg>
										Download
									</div>
									<div
										onClick={toggleQueueDropdown}
										class='py-2 px-2 cta relative inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 cursor-pointer'
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-4 h-4 text-gray-700'
										>
											<path d='M9 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v.5' />
											<path d='M12 10v4h4' />
											<path d='m12 14 1.535-1.605a5 5 0 0 1 8 1.5' />
											<path d='M22 22v-4h-4' />
											<path d='m22 18-1.535 1.605a5 5 0 0 1-8-1.5' />
										</svg>
										{!isLoading && downloadQueues?.length > 0 && (
											<span class='flex absolute top-0 right-0 w-2 h-2 -mt-1.5 -mr-1.5'>
												<span class='animate-ping absolute inline-flex w-full h-full rounded-full bg-green-200 opacity-75'></span>
												<span class='relative inline-flex rounded-full w-2 h-2 bg-green-500'></span>
											</span>
										)}
									</div>
									<div
										class='py-2 px-2 cta cursor-pointer inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none'
										onClick={() => setaddlistmodel(true)}
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-3 h-3'
										>
											<path d='m3 17 2 2 4-4' />
											<path d='m3 7 2 2 4-4' />
											<path d='M13 6h8' />
											<path d='M13 12h8' />
											<path d='M13 18h8' />
										</svg>
										Create List
									</div>
									<div
										onClick={openModal}
										class='py-2 px-2 cta cursor-pointer inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none'
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='flex-shrink-0 w-3 h-3'
										>
											<rect width='7' height='9' x='3' y='3' rx='1' />
											<rect width='7' height='5' x='14' y='3' rx='1' />
											<rect width='7' height='9' x='14' y='12' rx='1' />
											<rect width='7' height='5' x='3' y='16' rx='1' />
										</svg>
										Customize
									</div>
								</div>
							</div>
						</div>

						<DataTable columns={columns} />
					</div>
				</div>
			)}
			{isDownloadOpen && (
				<div
					className='fixed inset-0 bg-slate-900 opacity-60 z-50'
					onClick={toggleDownloadDropdown}
				></div>
			)}
			<div
				id='hs-overlay-top'
				className={`hs-overlay ${
					isDownloadOpen ? 'translate-x-0' : 'translate-x-full'
				} fixed top-0 right-0 transition-all duration-300 transform min-h-screen w-1/4 z-max bg-white border-b`}
				tabIndex='-1'
			>
				<div className='flex justify-between items-center py-2 px-3 border-b'>
					<h3 className='font-bold text-sm text-gray-800'>Download Contacts</h3>
					<button
						type='button'
						onClick={toggleDownloadDropdown}
						className='flex justify-center items-center hover:border-none outline-none focus:outline-none w-5 h-5 text-sm font-semibold rounded-lg border-none bg-gray-200 text-gray-800 hover:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none'
						data-hs-overlay='#hs-overlay-top'
					>
						<span className='sr-only'>Close modal</span>
						<svg
							className='flex-shrink-0 w-3 h-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</button>
				</div>
				<div className='px-3 my-2'>
					<DownloadPopUpModel setDownload={setIsDownloadOpen} />
				</div>
			</div>
			{isQueueOpen && (
				<div
					className='fixed inset-0 bg-slate-900 opacity-60 z-50'
					onClick={toggleQueueDropdown}
				></div>
			)}
			<div
				id='hs-overlay-top'
				className={`hs-overlay ${
					isQueueOpen ? 'translate-x-0' : 'translate-x-full'
				} fixed top-0 right-0 transition-all duration-300 transform min-h-screen w-1/4 z-max bg-white border-b`}
				tabIndex='-1'
			>
				<div className='flex justify-between items-center py-2 px-3 border-b'>
					<h3 className='font-bold text-sm text-gray-800'>
						My Downloads Queue
					</h3>
					<button
						type='button'
						onClick={toggleQueueDropdown}
						className='flex justify-center items-center hover:border-none outline-none focus:outline-none w-5 h-5 text-sm font-semibold rounded-lg border-none bg-gray-200 text-gray-800 hover:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none'
						data-hs-overlay='#hs-overlay-top'
					>
						<span className='sr-only'>Close modal</span>
						<svg
							className='flex-shrink-0 w-3 h-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</button>
				</div>
				<div className='px-3 my-2'>
					<QueuePopUpModel
						queueData={downloadQueues}
						setRefresh={setRefresh}
						isLoading={isLoading}
					/>
				</div>
			</div>
			<OverlayModal
				isOpen={addlistmodel}
				onClose={() => {
					setTimeout(() => {
						setaddlistmodel(false);
					}, 200);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Create or Add to List
						</h3>
						<div
							onClick={() => {
								setTimeout(() => {
									setaddlistmodel(false);
								}, 200);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<AddPopUpModel
						setaddlistmodel={setaddlistmodel}
						closeModal={() => {
							setTimeout(() => {
								setaddlistmodel(false);
							}, 200);
						}}
					/>
				</div>
			</OverlayModal>
			<OverlayModal
				isOpen={saveSearch}
				onClose={() => {
					setTimeout(() => {
						setSaveSearch(false);
					}, 200);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>Save Search</h3>
						<div
							onClick={() => setSaveSearch(false)}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<SaveSearchModel
						setSaveSearch={setSaveSearch}
						closeModal={() => setSaveSearch(false)}
					/>
				</div>
			</OverlayModal>
			<OverlayModal isOpen={modalOpen} onClose={closeModal}>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Customize Columns
						</h3>
						<div
							onClick={closeModal}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					<div className='mt-4 flex flex-wrap gap-x-2 gap-y-3 items-center'>
						{columns.length > 0 &&
							columns.map(
								(col, i) =>
									col?.field !== '_id' &&
									col?.field !== 'Action' && (
										<div
											key={i}
											className={`rounded-full ${
												!col.hide ? 'border-check-active' : 'border-check-light'
											}`}
										>
											<h3
												onClick={() => {
													const copyOfColumns = [...columns];
													const changedColumn = copyOfColumns.find(
														(item) => item.field === col.field
													);
													changedColumn.hide = !changedColumn.hide;
													setColumns(copyOfColumns);
												}}
												// onClick={() => handleSelect('levelID', level)}
												className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
											>
												<div className='custom-checkbox-filter'>
													<input
														type='checkbox'
														className='mt-0.5'
														checked={!col.hide}
													/>
													<div className='check-mark'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															class='w-2.5 h-2.5'
															viewBox='0 0 20 20'
															fill='currentColor'
															stroke='currentColor'
															stroke-width='1'
														>
															<path
																fill-rule='evenodd'
																d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																clip-rule='evenodd'
															></path>
														</svg>
													</div>
												</div>
												{col.headerName}
											</h3>
										</div>
									)
							)}
					</div>
				</div>
			</OverlayModal>
			<OverlayModal isOpen={welcomeModal} onClose={closeWelcomeModal}>
				<div className='w-80 p-0.5'>
					<div className='w-full h-28 overflow-hidden relative rounded-lg flex justify-center items-center'>
						<img src={PartyEmoji} className='z-1 w-24' />
						<div className='w-96 h-96 !absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center starburst starburst--welcome animate-round'></div>
					</div>
					<div className='mt-3'>
						<h1 className='text-lg font-semibold text-gray-800'>
							Welcome to HealthDBi!
						</h1>
						<p className='px-2 text-xs text-gray-900'>
							HealthDBi provides comprehensive data of professionals in the
							healthcare sector. Explore our features and maximize your access
							to valuable healthcare insights!
						</p>
					</div>
					<button
						onClick={closeWelcomeModal}
						className='mt-4 text-sm font-medium w-full py-2.5 px-4 text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none rounded-lg text-center'
					>
						{isExploding && (
							<ConfettiExplosion
								zIndex={80}
								force={1}
								particleCount={400}
								duration={4200}
								width={2000}
							/>
						)}
						Get Started
					</button>
				</div>
			</OverlayModal>
			<WelcomeTour startTour={startTour} />
		</div>
	);
};

export default ContactSearchManagement;
