/** @format */

import { useContext, useState, useEffect } from 'react';
import {
	BLOCK_UNBLOCK_MEMBER,
	DELETE_INVITE,
	DELETE_INVITE_ADMIN,
	DELETE_MEMBER,
	GET_ALL_TEAM_MEMBERS,
	GET_COMPANY_MEMBERS_ADMIN,
} from '../../../Context/Types';
import { AuthContext } from '../../../Context/AuthContext';
import TableSkeltonLoader from '../../../Elements/TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../../Elements/Misc/DTable';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import OverlayModal from '../../../Elements/Misc/Modal';
import AssignCredit from './AssignCredits';
import RevokeCredit from './RevokeCredits';
import AddTeam from './AddTeam';

const TeamMembers = (props) => {
	const { dispatch, typeOfUser } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [allData, setallData] = useState([]);
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isAssign, setIsAssign] = useState(false);
	const [Upgrade, setUpgrade] = useState(false);
	const [userDetail, setUserDetail] = useState(false);
	const [isAdd, setIsAdd] = useState(false);
	const [isRevoke, setIsRevoke] = useState(false);
	const [selectedTeamMember, setSelectedTeamMember] = useState(null);
	const [activityLogModel, setActivityLogModel] = useState(false);

	useEffect(() => {
		if (props?.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_MEMBERS_ADMIN,
				id: props?.selectedCompanyByAdmin,
				updateState: setRows,
				setallData: setallData,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_ALL_TEAM_MEMBERS,
				updateState: setRows,
				setallData: setallData,
				setIsLoading: setIsLoading,
			});
		}
	}, []);

	const deleteMember = (id) => {
		dispatch({
			type: DELETE_MEMBER,
			id: id,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	};

	const deleteInvite = (id) => {
		if (props?.selectedCompanyByAdmin) {
			dispatch({
				type: DELETE_INVITE_ADMIN,
				companyId: props?.selectedCompanyByAdmin,
				inviteId: id,
				updateState: setRows,
				setallData: setallData,
			});
		} else {
			dispatch({
				type: DELETE_INVITE,
				id: id,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		}
	};

	const handleBlockAndUnBlock = (id) => {
		dispatch({
			type: BLOCK_UNBLOCK_MEMBER,
			id: id,
			updateState: setRows,
		});
	};

	const columns = [
		{ title: 'Member' },
		{ title: 'Available Credits' },
		{ title: 'Login IP' },
		{ title: 'Invitation Status' },
		{ title: 'Status' },
		{ title: 'Creation' },
		{ title: 'Actions' },
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'ID') {
			return row?._id;
		} else if (col.title === 'Member') {
			return (
				<>
					<p>{row?.name}</p>
					<p>{row?.email}</p>
				</>
			);
		} else if (col.title === 'Available Credits') {
			return row.credits;
		} else if (col.title === 'Creation') {
			return new Date(row.createdAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		} else if (col.title === 'Actions') {
			return (
				<>
					{row?.status === false ? (
						<div className='flex items-center !gap-4'>
							<div
								onClick={() => {
									deleteInvite(row._id);
								}}
								className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									className='size-4'
								>
									<path d='M3 6h18' />
									<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
									<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
									<line x1='10' x2='10' y1='11' y2='17' />
									<line x1='14' x2='14' y1='11' y2='17' />
								</svg>
								Delete
							</div>
						</div>
					) : (
						<div className='flex items-center !gap-4'>
							{row?.blocked ? (
								<div
									onClick={() => {
										handleBlockAndUnBlock(row._id);
									}}
									className='cursor-pointer flex items-center gap-1 text-teal-600 hover:underline'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='size-4'
									>
										<path d='M2 21a8 8 0 0 1 13.292-6' />
										<circle cx='10' cy='8' r='5' />
										<path d='m16 19 2 2 4-4' />
									</svg>
									Unblock
								</div>
							) : (
								<div
									onClick={() => {
										handleBlockAndUnBlock(row._id);
									}}
									className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='size-4'
									>
										<path d='M2 21a8 8 0 0 1 11.873-7' />
										<circle cx='10' cy='8' r='5' />
										<path d='m17 17 5 5' />
										<path d='m22 17-5 5' />
									</svg>
									Block
								</div>
							)}
							<div
								onClick={() => {
									setIsAssign(true);
									setSelectedTeamMember(row);
								}}
								className='cursor-pointer flex items-center gap-1 text-green-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<path d='M5 12h14' />
									<path d='M12 5v14' />
								</svg>
								Assign
							</div>
							<div
								onClick={() => {
									setIsRevoke(true);
									setSelectedTeamMember(row);
								}}
								className='cursor-pointer flex items-center gap-1 text-rose-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<path d='M5 12h14' />
								</svg>
								Revoke
							</div>
							<div
								onClick={() => {
									deleteMember(row._id);
								}}
								className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									className='size-4'
								>
									<path d='M3 6h18' />
									<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
									<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
									<line x1='10' x2='10' y1='11' y2='17' />
									<line x1='14' x2='14' y1='11' y2='17' />
								</svg>
								Delete
							</div>
						</div>
					)}
				</>
			);
		} else if (col.title === 'Login IP') {
			return row.login_ip;
		} else if (col.title === 'Invitation Status') {
			return row?.status === true ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-emerald-100 text-emerald-700 rounded-full'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'></path>
							<path d='m9 12 2 2 4-4'></path>
						</svg>
						Accepted
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-orange-100 text-orange-600 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3'
						>
							<path d='M10.1 2.18a9.93 9.93 0 0 1 3.8 0' />
							<path d='M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7' />
							<path d='M21.82 10.1a9.93 9.93 0 0 1 0 3.8' />
							<path d='M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69' />
							<path d='M13.9 21.82a9.94 9.94 0 0 1-3.8 0' />
							<path d='M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7' />
							<path d='M2.18 13.9a9.93 9.93 0 0 1 0-3.8' />
							<path d='M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69' />
							<circle cx='12' cy='12' r='1' />
						</svg>
						Pending
					</span>
				</div>
			);
		} else if (col.title === 'Status') {
			return row?.blocked === true ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-100 text-red-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3 rotate-45'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						Inactive
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-emerald-100 text-emerald-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='m9 12 2 2 4-4' />
						</svg>
						Active
					</span>
				</div>
			);
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				<div
					className={`flex items-center px-4 pt-3 ${
						typeOfUser === 'FREE' ? 'justify-between' : 'justify-end'
					}`}
				>
					{typeOfUser === 'FREE' && (
						<div
							class='bg-red-100 border !border-red-400 text-sm text-red-600 rounded-lg px-2 py-2'
							role='alert'
						>
							<div class='flex gap-2'>
								<svg
									class='flex-shrink-0 w-3 h-3 mt-0.5'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								>
									<circle cx='12' cy='12' r='10'></circle>
									<path d='M12 16v-4'></path>
									<path d='M12 8h.01'></path>
								</svg>

								<p class='text-xs text-start font-medium'>
									Upgrade your membership to add team member.
								</p>
							</div>
						</div>
					)}
					<button
						className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2 disabled:opacity-60 disabled:pointer-events-none'
						onClick={() => {
							if (typeOfUser === 'FREE') {
							} else {
								setIsAdd(true);
							}
						}}
						disabled={typeOfUser === 'FREE'}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-4'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						Add New Member
					</button>
				</div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={3} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>

			<OverlayModal
				isOpen={isAssign}
				onClose={() => {
					setIsAssign(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Assign Credits
						</h3>
						<div
							onClick={() => {
								setIsAssign(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<AssignCredit
						close={() => {
							setIsAssign(false);
						}}
						selectedTeamMember={selectedTeamMember}
						setRows={setRows}
					/>
				</div>
			</OverlayModal>
			<OverlayModal
				isOpen={isRevoke}
				onClose={() => {
					setIsRevoke(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Revoke Credits
						</h3>
						<div
							onClick={() => {
								setIsRevoke(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<RevokeCredit
						selectedTeamMember={selectedTeamMember}
						setRows={setRows}
						close={() => {
							setIsRevoke(false);
						}}
					/>
				</div>
			</OverlayModal>

			<AddTeam
				open={isAdd}
				setRows={setRows}
				close={() => {
					setIsAdd(false);
				}}
				selectedCompanyByAdmin={props?.selectedCompanyByAdmin}
			></AddTeam>
		</>
	);
};

export default TeamMembers;
