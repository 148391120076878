/** @format */
import logo from '../../assests/image/companylogo.png';

const ScreenSizeAlert = () => {
	return (
		<div class='max-w-[50rem] min-h-screen flex flex-col mx-auto size-full'>
			<header class='mb-auto flex justify-center z-50 w-full py-4'>
				<nav class='px-4 sm:px-6 lg:px-8'>
					<img
						src={logo}
						alt='HealthDBi'
						className='cursor-pointer h-12 w-auto'
					/>
				</nav>
			</header>

			<main id='content'>
				<div class='text-center py-10 px-4 sm:px-6 lg:px-8'>
					<h1 class='block text-4xl font-bold text-gray-800 sm:text-9xl'>
						Desktop Only Access
					</h1>
					<p class='mt-3 text-gray-600'>
						For the best experience, please access our website from a desktop
						device.
					</p>
					<p class='text-gray-600'>
						Mobile support is not available at this time.
					</p>
				</div>
			</main>
			<footer class='mt-auto text-center py-5'>
				<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
					<p class='text-sm text-gray-600'>
						© All Rights Reserved. 2022-{new Date().getFullYear()}.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default ScreenSizeAlert;
