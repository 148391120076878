/** @format */
import React, { useContext, useEffect, useState } from 'react';
import {
	FIRM_TYPE,
	HOSPITAL_TYPE,
} from '../../Context/filterCompo/filterTypes';
import { MainContext } from '../../Context/FilterContext/FilterContext';
import { AuthContext } from '../../Context/AuthContext';
import { FILTER_CONTACTS } from '../../Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { CircularProgress } from '@mui/material';

const NetworkFilter = () => {
	const [expanded, setExpanded] = useState(false);

	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setSortField,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		anyFilterFlag,
	} = useContext(AuthContext);

	const [firstTimeRun, setFirstTimeRun] = useState(false);
	const [networkOptions, setNetworkOptions] = useState([]);
	const [facilityLoading, setFacilityLoading] = useState(false);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const loadOptions = debounce((searchValue) => {
		setFacilityLoading(true);
		const getData = async () => {
			const data = await globalFilterKeyToApi[HOSPITAL_TYPE]('');
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setNetworkOptions(options);
			setFacilityLoading(false);
			return options;
		};
		return getData();
	}, 300);

	const handleSelectAll = () => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		var field = 'hospitalType';

		if (filter?.hospitalType?.length === 10) {
			setGlobalSelectFilter((prev) => {
				return { ...prev, hospitalType: [] };
			});
			setFilter((prev) => {
				return { ...prev, hospitalType: [] };
			});
		} else {
			for (const selectedOption of networkOptions) {
				setGlobalSelectFilter((prev) => {
					if (!prev[field] || prev[field]?.length === 0) {
						return { ...prev, [field]: [selectedOption.value] };
					} else {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							return { ...prev };
						} else {
							return {
								...prev,
								[field]: [...prev[field], selectedOption.value],
							};
						}
					}
				});
				setFilter((prev) => {
					if (!prev[field] || prev[field]?.length === 0) {
						return { ...prev, [field]: [selectedOption.value] };
					} else {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							return { ...prev };
						} else {
							return {
								...prev,
								[field]: [...prev[field], selectedOption.value],
							};
						}
					}
				});
			}
		}

		setSortField({});
	};

	const handleMostSearchML = () => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		var field = 'hospitalType';

		if (
			filter?.hospitalType?.length === 4 &&
			[
				'Long Term Acute Care Hospital',
				'Health System',
				'Short Term Acute Care Hospital',
				'VA Hospital',
			].every((value) => {
				return filter?.hospitalType.some((element) => {
					return element === value;
				});
			})
		) {
			setGlobalSelectFilter((prev) => {
				return { ...prev, hospitalType: [] };
			});
			setFilter((prev) => {
				return { ...prev, hospitalType: [] };
			});
		} else {
			for (const selectedOption of networkOptions) {
				if (
					selectedOption.value === 'Long Term Acute Care Hospital' ||
					selectedOption.value === 'Health System' ||
					selectedOption.value === 'Short Term Acute Care Hospital' ||
					selectedOption.value === 'VA Hospital'
				) {
					console.log(selectedOption);
					setGlobalSelectFilter((prev) => {
						if (!prev[field] || prev[field]?.length === 0) {
							return { ...prev, [field]: [selectedOption.value] };
						} else {
							const index = prev[field]?.findIndex((item) => {
								return item === selectedOption.value;
							});

							if (index !== -1) {
								return { ...prev };
							} else {
								return {
									...prev,
									[field]: [...prev[field], selectedOption.value],
								};
							}
						}
					});
					setFilter((prev) => {
						if (!prev[field] || prev[field]?.length === 0) {
							return { ...prev, [field]: [selectedOption.value] };
						} else {
							const index = prev[field]?.findIndex((item) => {
								return item === selectedOption.value;
							});

							if (index !== -1) {
								return { ...prev };
							} else {
								return {
									...prev,
									[field]: [...prev[field], selectedOption.value],
								};
							}
						}
					});
				} else {
					setGlobalSelectFilter((prev) => {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							const updatedArray = [...prev[field]];
							updatedArray.splice(index, 1);
							return { ...prev, [field]: updatedArray };
						} else {
							return { ...prev };
						}
					});
					setFilter((prev) => {
						const index = prev[field]?.findIndex((item) => {
							return item === selectedOption.value;
						});

						if (index !== -1) {
							const updatedArray = [...prev[field]];
							updatedArray.splice(index, 1);
							return { ...prev, [field]: updatedArray };
						} else {
							return { ...prev };
						}
					});
				}
			}
		}

		setSortField({});
	};

	useEffect(() => {
		loadOptions();
	}, []);

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>
					Network (Firm Type)
				</h1>
				<div className='w-full my-2 px-1 rounded-lg'>
					<div className='mt-3 flex flex-wrap gap-x-2 gap-y-2 items-center'>
						<div
							className={`rounded-full ${
								filter?.firmType &&
								filter?.firmType?.findIndex((e) => {
									return e === 'Health System';
								}) !== -1
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() =>
									handleSelect('firmType', {
										value: 'Health System',
										label: 'Health System',
									})
								}
								className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
							>
								<div className='custom-checkbox-filter'>
									<input
										type='checkbox'
										className='mt-0.5'
										checked={
											filter?.firmType &&
											filter?.firmType?.findIndex((e) => {
												return e === 'Health System';
											}) !== -1
										}
									/>
									<div className='check-mark'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-2.5 h-2.5'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								Health System
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.firmType &&
								filter?.firmType?.findIndex((e) => {
									return e === 'Hospital';
								}) !== -1
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() =>
									handleSelect('firmType', {
										value: 'Hospital',
										label: 'Hospital',
									})
								}
								className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
							>
								<div className='custom-checkbox-filter'>
									<input
										type='checkbox'
										className='mt-0.5'
										checked={
											filter?.firmType &&
											filter?.firmType?.findIndex((e) => {
												return e === 'Hospital';
											}) !== -1
										}
									/>
									<div className='check-mark'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-2.5 h-2.5'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								Hospital
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>

			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Facility Type</h1>
				<div className='w-full my-2 px-1 rounded-lg'>
					{facilityLoading ? (
						<div className='px-1 text-start text-xs font-medium text-blue-600 flex gap-2 items-center'>
							<CircularProgress size={12} style={{ color: '#2f5eff' }} />
							Loading...
						</div>
					) : (
						<>
							<div className='flex items-center gap-2'>
								<div
									className={`rounded-full ${
										filter?.hospitalType && filter?.hospitalType?.length === 10
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={handleSelectAll}
										className='flex gap-1 cursor-pointer justify-center items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
									>
										<div className='custom-checkbox-filter'>
											<input
												type='checkbox'
												className='mt-0.5'
												checked={
													filter?.hospitalType &&
													filter?.hospitalType?.length === 10
												}
											/>
											<div className='check-mark m-0 p-0'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													class='w-2.5 h-2.5'
													viewBox='0 0 20 20'
													fill='currentColor'
													stroke='currentColor'
													stroke-width='1'
												>
													<path
														fill-rule='evenodd'
														d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
														clip-rule='evenodd'
													></path>
												</svg>
											</div>
										</div>
										Select All
									</h3>
								</div>
								<div
									className={`rounded-full ${
										filter?.hospitalType?.length === 4 &&
										[
											'Long Term Acute Care Hospital',
											'Health System',
											'Short Term Acute Care Hospital',
											'VA Hospital',
										].every((value) => {
											return filter.hospitalType.some((element) => {
												if (typeof element === 'number') {
													return element === value;
												} else {
													return element === value;
												}
											});
										})
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={handleMostSearchML}
										className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
									>
										<div className='custom-checkbox-filter'>
											<input
												type='checkbox'
												className='mt-0.5'
												checked={
													filter?.hospitalType?.length === 4 &&
													[
														'Long Term Acute Care Hospital',
														'Health System',
														'Short Term Acute Care Hospital',
														'VA Hospital',
													].every((value) => {
														return filter?.hospitalType.some((element) => {
															if (typeof element === 'number') {
																return element === value;
															} else {
																return element === value;
															}
														});
													})
												}
											/>
											<div className='check-mark'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													class='w-2.5 h-2.5'
													viewBox='0 0 20 20'
													fill='currentColor'
													stroke='currentColor'
													stroke-width='1'
												>
													<path
														fill-rule='evenodd'
														d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
														clip-rule='evenodd'
													></path>
												</svg>
											</div>
										</div>
										Most Searched Facilities
									</h3>
								</div>
							</div>
							<div className='mt-3 flex flex-wrap gap-x-1 gap-y-2 items-center'>
								{networkOptions.length > 0 &&
									networkOptions.map((level, i) => (
										<div
											key={i}
											className={`rounded-full ${
												filter?.hospitalType &&
												filter?.hospitalType?.findIndex((e) => {
													return e === level.label;
												}) !== -1
													? 'border-check-active'
													: 'border-check-light'
											}`}
										>
											<h3
												onClick={() => handleSelect('hospitalType', level)}
												className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
											>
												<div className='custom-checkbox-filter'>
													<input
														type='checkbox'
														className='mt-0.5'
														checked={
															filter?.hospitalType &&
															filter?.hospitalType?.findIndex((e) => {
																return e === level.label;
															}) !== -1
														}
													/>
													<div className='check-mark'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															class='w-2.5 h-2.5'
															viewBox='0 0 20 20'
															fill='currentColor'
															stroke='currentColor'
															stroke-width='1'
														>
															<path
																fill-rule='evenodd'
																d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																clip-rule='evenodd'
															></path>
														</svg>
													</div>
												</div>
												{level.label}
											</h3>
										</div>
									))}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default NetworkFilter;
