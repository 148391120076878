/** @format */

import { getTransactions, getCompanyTransactions } from '../APIs';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export const getTransactionsReducer = async (
	updateState,
	setIsLoading,
	setallData
) => {
	setIsLoading(true);
	try {
		const res = await getTransactions();

		updateState(res.data);
		setallData(res.data);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const getCompanyTransactionsReducer = async (
	id,
	updateState,
	setIsLoading,
	setallData
) => {
	setIsLoading(true);
	try {
		const res = await getCompanyTransactions(id);

		updateState(res.data);
		setallData(res.data);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
