import { NotificationManager } from "react-notifications";
import { getAllCategories, uploadLeadCsv, getLeadByCategory } from "../APIs";

export const getActiveCategoriesReducer = async (updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await getAllCategories();

    const formatedData = res.data.data
      .filter((item) => item.status === true)
      .map((item) => {
        return {
          label: item.name,
          value: item._id,
        };
      });
    updateState(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const uploadLeadCsvReducer = async (values, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await uploadLeadCsv(values);
    if (res.data.message === "Leads Data Uploaded Successfully") {
      NotificationManager.success(res.data.message);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const getLeadByCategoryReducer = async (
  searchParams,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await getLeadByCategory(
      searchParams.id,
      searchParams.page,
      searchParams.limit
    );
    const formatedData = res.data.leads.map((item) => {
      return {
        _id: item._id,
        speciality: item.Specility,
        name: `${item.FirstName} ${item.LastName}`,
        email: item.EmailAddress,
        gender: item.Gender,
      };
    });
    updateState.setRows(formatedData);
    updateState.setPagination({
      current: searchParams.page,
      pageSize: searchParams.limit,
      total: res.data.count,
    });
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
