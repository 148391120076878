/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';
import { NotificationManager } from 'react-notifications';

// for live server
const API = axios.create({
	baseURL: 'https://api.healthdbi.com/',
});

API.interceptors.request.use((req) => {
	if (Cookies.get('token')) {
		req.headers['token'] = `${Cookies.get('token')}`;
	}
	return req;
});

API.interceptors.response.use(
	(res) => res,
	async (error) => {
		if (
			error.config.url === 'leads/download' &&
			error?.response?.status === 0
		) {
			NotificationManager.success(
				'You will get an email once the file is processed'
			);
		} else if (
			error.response.data === 'Access Denied' ||
			error.response.data === 'Invalid Token' ||
			error.response.data === 'Account not found!' ||
			error.response.data === 'Account not found' ||
			error.response.data === 'Token Expired!' ||
			error.response.data === 'Please login again!'
		) {
			Cookies.remove('token');
			window.location.reload();
		} else {
			if (error?.response?.data?.message === 'Human verification failed') {
				NotificationManager.error(error?.response?.data?.message);
			} else if (error.response.data) {
				NotificationManager.error(JSON.stringify(error?.response?.data));
			} else {
				if (
					!error?.response?.data?.message ||
					!error?.response?.data?.error ||
					!error.response.data
				) {
				} else {
					NotificationManager.error(
						error?.response?.data?.message ||
							error?.response?.data?.error ||
							error.response.data
					);
				}
			}
		}
	}
);

export const loginUser = (values) => {
	return API.post('/auth/login', values);
};

export const getTest = (values) => {
	return API.get('/company/auth/');
};

export const signUp = (values) => {
	return API.post('/company/auth/register', values);
};

export const resendEmail = (email) => {
	return API.post('company/auth/resendActivation', {
		email: email,
	});
};

export const forgetPassword = (email) => {
	return API.post('auth/forgotPassword', email);
};

export const resetPassword = (values) => {
	return API.post('profile/changePassword', values);
};
export const setNewPassword = (values) => {
	return API.post('auth/resetPassword', values);
};
export const allContacts = (page, limit) => {
	return API.post('/leads/getLeads', {
		page: page,
		limits: limit,
	});
};

export const filterContacts = (filterOptions, page, limit) => {
	return API.post(`leads/leadColumnFilter`, {
		...filterOptions,
		page: page,
		limit: limit,
	});
};
export const filterContactsStats = (filterOptions) => {
	return API.post(`leads/leadColumnFilterStats`, {
		...filterOptions,
	});
};
export const filterContactsStatsAvailable = (filterOptions) => {
	return API.post(`leads/leadColumnAvailableStats`, {
		...filterOptions,
	});
};

export const getZipCode = () => {
	return API.get('location/getZipCode');
};

export const getAllListContactsRequest = (folder_id) => {
	return API.get(`folders/oneFolder?folder_id=${folder_id}`);
};

export const getTeamMembers = () => {
	return API.get('/company/members/');
};

export const inviteTeamMembers = (values) => {
	return API.post('company/members/invite', values);
};
//  subscription
export const getAllSubscriptionPlans = (id) => {
	return API.get(`admin/subscription/getAll?company_id=${id}`);
};
export const getSubscriptionPlan = (id) => {
	return API.get(`admin/subscription/get/${id}`);
};
export const postSubscriptionPlan = (values) => {
	return API.post('admin/subscription/create', values);
};
export const updateSubscriptionPlan = (id, values) => {
	return API.put(`admin/subscription/update/${id}`, values);
};
export const deleteSubscriptionPlan = (id) => {
	return API.delete(`admin/subscription/delete/${id}`);
};
export const getSubscriptionFeatures = () => {
	return API.get('admin/features');
};
export const buySubscription = (payload) => {
	return API.post('company/plans/buyPlan', payload);
};

//categories
export const getAllCategories = () => {
	return API.get('admin/category/getAll');
};
export const getCategory = (id) => {
	return API.get(`admin/category/get/${id}`);
};
export const postCategory = (values) => {
	return API.post('admin/category/create', values);
};
export const updateCategory = (id, values) => {
	return API.put(`admin/category/update/${id}`, values);
};
export const deleteCategory = (id) => {
	return API.delete(`admin/category/delete/${id}`);
};
// activity logs for admin and company
export const getActivityLogs = (page, limit, date) => {
	return API.get(
		`miscellaneous/logs?page=${page}&limits=${limit}&date=${date}`
	);
};

export const getActivityLogsMember = (id, page, limit) => {
	return API.get(
		`miscellaneous/memberlogs?id=${id}&page=${page}&limits=${limit}`
	);
};
export const unlock = (leads_ids, clickedOn, dataType) => {
	return API.post(
		`/leads/unlock/${clickedOn}`,
		typeof leads_ids === 'object'
			? {
					leads_ids,
					dataType: dataType,
			  }
			: {
					leads_ids: [leads_ids],
					dataType: dataType,
			  }
	);
};

export const createListRequest = (value) => {
	return API.post('folders/create', value);
};

export const getAllList = (dataType) => {
	return API.get('folders', {
		params: {
			type: dataType,
		},
	});
};

export const addItem = (values, folder_id, dataType) => {
	return API.post('folders/addItems', {
		leads_ids: values,
		folder_id: folder_id,
		dataType: dataType,
	});
};

export const deleteListRequest = (id) => {
	return API.delete(`folders/delete?folder_id=${id}`);
};

export const editListRequest = (values) => {
	return API.post('folders/edit', values);
};
//quick search
export const quickSearch = (searchValue, page, limit) => {
	return API.get(
		`leads/quickSearch?searchparam=${searchValue}&page=${page}&limit=${limit}`
	);
};

//credits

export const getCreditsRequest = () => {
	return API.get('/miscellaneous/myCredits');
};

//Team APIs

export const teamSignUp = (values) => {
	return API.post('member/auth/register', values);
};

export const getInvitedMemberRequest = (invite_id) => {
	return API.get(`company/members/getOneInvite?invite_id=${invite_id}`);
};

export const getProfileRequest = () => {
	return API.get('profile/myProfile');
};

export const editProfileRequest = (values) => {
	return API.post('profile/edit', values);
};

export const onboardingComplete = () => {
	return API.get('profile/onboardFinish');
};

export const sendRequestCredits = (values) => {
	return API.get(`member/requestcredits/requestCredits?credits=${values}`);
};

export const requestLogRequest = () => {
	return API.get('member/requestcredits/myRequests');
};

export const requestLogRequestCom = () => {
	return API.get('company/creditrequests/myRequests');
};

export const logout = () => {
	return API.delete('auth/logout');
};

//leads api
export const uploadLeadCsv = (values) => {
	return API.post('admin/leads/uploadCustomerCSV', values);
};

export const getLeadByCategory = (id, page, limit) => {
	return API.get(
		`admin/leads/leadsFromCategory?categoryId=${id}&page=${page}&limits=${limit}`
	);
};

//company section for admin

export const getCompaniesByType = (type) => {
	return API.get(`admin/company/basedOnType?type=${type}`);
};
export const getCompanyInfo = (id) => {
	return API.get(`admin/company/companyInfo?company_id=${id}`);
};
export const getCompanyActivityLog = (id, page, limit) => {
	return API.get(
		`admin/company/companyActivityLog?company_id=${id}&page=${page}&limits=${limit}`
	);
};
export const getCompanyMembers = (id) => {
	return API.get(`admin/company/companyMembers?company_id=${id}`);
};
export const getCompanyMembersCreditRequestAdmin = (id) => {
	return API.get(`admin/company/companyCreditRequests`, {
		params: {
			company_id: id,
		},
	});
};

//Transaction section for company admin
export const getTransactions = (type) => {
	return API.get(`company/plans/transactions`);
};

//Transaction section for super admin
export const getCompanyTransactions = (id) => {
	return API.get(`admin/company/transactions?company_id=${id}`);
};

//edit company info by super admin
export const editCompanyInfo = (id, values) => {
	return API.post(`admin/company/editdetails?company_id=${id}`, values);
};

//Team member section for super admin
export const getCompanyCredits = (id) => {
	return API.get(`admin/company/credits?company_id=${id}`);
};
export const inviteCompanyMember = (id, values) => {
	return API.post(`admin/company/invite?company_id=${id}`, values);
};

export const profileVisitDec = () => {
	return API.post(`profile/changeProfileVisit`);
};

export const getUnlockedLeads = (dataType) => {
	return API.get(`leads/getMyLeads`, {
		params: {
			type: dataType,
		},
	});
};
export const getDownloadLeads = (dataType) => {
	return API.get(`downloads`, {
		params: {
			type: dataType,
		},
	});
};
export const getDownloadQueue = () => {
	return API.get(`downloads/downloadQueue`);
};
export const getOneDownloadList = (id) => {
	return API.get(`downloads/oneDownloadList`, {
		params: {
			downloadlist_id: id,
		},
	});
};
export const renameDownloadList = (values) => {
	return API.post(`downloads/edit`, values);
};

export const createAndSaveItemToList = (values) => {
	return API.post(`folders/createAndAddItems`, values);
};

export const downloadLeads = (values) => {
	return API.post(`leads/download`, values);
};

export const saveSearchFilter = (values) => {
	return API.post(`search/add`, values);
};

export const getSaveSearch = (dataType) => {
	return API.get(`search/`, {
		params: {
			type: dataType,
		},
	});
};

export const editSavedSearch = (values) => {
	return API.post(`search/edit`, values);
};

export const deleteSavedSearch = (id) => {
	return API.delete(`search/`, {
		params: {
			search_id: id,
		},
	});
};

export const uploadExclusionLeadsCSV = (values) => {
	return API.post(`exclusion/add`, values);
};

export const getAllExclusionList = () => {
	return API.get(`exclusion/`);
};
export const getExclusionLeads = (id) => {
	return API.get(`exclusion/oneExclusionList/`, {
		params: {
			exclusion_id: id,
		},
	});
};

export const deleteExclusion = (id) => {
	return API.delete(`exclusion/`, {
		params: {
			exclusion_id: id,
		},
	});
};

export const createCompanyByAdmin = (values) => {
	return API.post(`admin/company/create`, values);
};

export const cancelSubscription = (values) => {
	return API.post(`company/plans/cancelSubscription`, values);
};

export const getInvoices = () => {
	return API.get(`company/plans/invoices/`);
};
export const getCompanyInvoicesAdmin = (id) => {
	return API.get(`admin/company/invoices`, {
		params: {
			company_id: id,
		},
	});
};

export const acceptMemberCreditRequest = (id) => {
	return API.get(`company/creditrequests/accept`, {
		params: {
			request_id: id,
		},
	});
};
export const denyMemberCreditRequest = (id) => {
	return API.delete(`company/creditrequests/decline`, {
		params: {
			request_id: id,
		},
	});
};

export const deleteMember = (id) => {
	return API.delete(`company/members/deleteMember`, {
		params: {
			member_id: id,
		},
	});
};
export const deleteInvite = (id) => {
	return API.delete(`company/members/deleteInvite`, {
		params: {
			invite_id: id,
		},
	});
};
export const deleteInviteAdmin = (companyId, inviteId) => {
	return API.delete(`admin/company/deleteInvite`, {
		params: {
			company_id: companyId,
			invite_id: inviteId,
		},
	});
};

export const assignCreditToMember = (id, value) => {
	return API.get('company/members/assignCredits', {
		params: {
			member_id: id,
			credits: value,
		},
	});
};
export const revokeCreditFromMember = (id, value) => {
	return API.get('company/members/revokeCredits', {
		params: {
			member_id: id,
			credits: value,
		},
	});
};
export const getCurrentPlan = () => {
	return API.get('company/plans/currentPlan');
};

export const blockAndUnblock = (id) => {
	return API.get('company/members/blockunblock', {
		params: {
			member_id: id,
		},
	});
};

export const assignCreditsToCompany = (values) => {
	return API.patch('/admin/company/add_credits', values);
};
export const assignUsersToCompany = (values) => {
	return API.patch('admin/company/add_users', values);
};

export const buyMoreCredits = (values) => {
	return API.post('/company/credit/buyCredits_request', values);
};
export const postPotentialCustomer = (email) => {
	return API.put(`/admin/potential_customers?email=${email}`);
};
export const getPotentialCustomer = () => {
	return API.get(`admin/potential_customers`);
};
export const getDashboardData = (year) => {
	return API.get(`admin/dashboard`, {
		params: {
			date: year,
		},
	});
};
export const payInvoice = (values) => {
	return API.post(`company/plans/payInvoice`, values);
};
export const markAsPaidInvoice = (values) => {
	return API.post(`admin/company/markAsPaid`, values);
};
export const getAllCompanies = () => {
	return API.get(`admin/company`);
};

export const getCompanySubscriptionHistory = (id) => {
	return API.get(`admin/subscription/history`, {
		params: {
			company_id: id,
		},
	});
};
export const blockAndUnblockCompany = (id) => {
	return API.get(`admin/company/blockunblock`, {
		params: {
			company_id: id,
		},
	});
};
export const suspendAndUnsuspendCompany = (id) => {
	return API.get(`admin/company/suspendUnsuspend`, {
		params: {
			company_id: id,
		},
	});
};
export const verifyCompanyEmail = (id) => {
	return API.patch(`admin/company/verify_email?company_id=${id}`);
};
export const changeCompanySubscription = (id, sub_id, isAnnual) => {
	return API.patch(`admin/company/change_subscription?company_id=${id}`, {
		subscription_id: sub_id,
		isAnnual: isAnnual,
	});
};

export const createSubAdmin = (values) => {
	return API.post(`admin/subadmin/add`, values);
};

export const getAllSubAdmin = () => {
	return API.get(`admin/subadmin`);
};

export const getSubAdmin = (id) => {
	return API.get(`admin/subadmin/one`, {
		params: {
			id: id,
		},
	});
};

export const deleteSubAdmin = (id) => {
	return API.delete(`admin/subadmin`, {
		params: {
			id: id,
		},
	});
};

export const getAdminTabs = () => {
	return API.get('admin/tabs');
};

export const getUnPaidInvoiceNotification = () => {
	return API.get('miscellaneous/unpaidNotifications');
};
export const extendFreeTrial = (id, date) => {
	return API.get('admin/company/extendTrial', {
		params: {
			company_id: id,
			date: date,
		},
	});
};

export const editSubAdmin = (id, values) => {
	return API.patch(`admin/subadmin/edit?id=${id}`, values);
};

//internal
export const getInternalDays = () => {
	return API.get('/internal/getDays');
};

export const updateInternalDays = (data) => {
	return API.post('/internal/updateDays', data);
};

export const getInternalFileVerification = (
	from = '',
	to = '',
	page,
	limit
) => {
	return API.get(
		`/internal/fileVerifications?from=${from}&to=${to}&page=${page}&limit=${limit}`
	);
};
export const getInternalBounceRepository = (page) => {
	return API.get(`/internal/bounce?page=${page}&limit=10`);
};

export const addInternalBounceFile = (data) => {
	return API.post('/internal/uploadBounce', data);
};

export const addInternalFileVerificationFile = (data) => {
	return API.post('/internal/uploadFileVerification', data);
};

export const internalDownloadFile = (data) => {
	return API.post('/internal/download', data);
};

export const finderDownloadFile = (data) => {
	return API.post('/internal/finderdownload', data);
};

export const addInternalCompanyProfileFile = (data) => {
	return API.post('/internal/uploadCompanyFile', data);
};
export const addEnhancerFile = (data) => {
	return API.post('/internal/uploadEnhancerFile', data);
};
export const getInternalCompanyProfileFile = (page) => {
	return API.get(`/internal/companyFile?limit=10&page=${page}`);
};
export const addInternalProjectFile = (data) => {
	return API.post('/internal/uploadProjectFile', data);
};
export const getInternalProjectFile = (page) => {
	return API.get(`/internal/projectFile?page=${page}&limit=10`);
};

export const checkInternalStatus = (data) => {
	return API.post('/internal/check-status', data);
};

export const verificationCompletedAPi = (data) => {
	return API.post('/internal/complete', data);
};

export const getCompanyInternalFileVerificationApi = (
	from = '',
	to = '',
	id,
	page
) => {
	return API.get(
		`/internal/admin/fileVerifications?company_id=${id}&from=${from}&to=${to}&limit=10&page=${page}`
	);
};

export const getMyEmailFinderAPI = (page, limit) => {
	return API.get(
		`/internal/enhancerFile?page=${page}&limit=${limit ? limit : 10}`
	);
};

export const getCompanyCompanyFileApi = (id, page) => {
	return API.get(
		`/internal/admin/companyFile?company_id=${id}&limit=10&page=${page}`
	);
};
export const getCompanyProjectFileApi = (id, page) => {
	return API.get(
		`/internal/admin/projectFile?company_id=${id}&limit=10&page=${page}`
	);
};
export const getCompanyBounceApi = (id, page) => {
	return API.get(
		`/internal/admin/bounce?company_id=${id}&limit=10&page=${page}`
	);
};

export const getCommonInternalFileVerificationApi = (
	from = '',
	to = '',
	page,
	limit
) => {
	return API.get(
		`/internal/admin/common/fileVerifications?page=${page}&limit=${limit}&from=${from}&to=${to}`
	);
};
export const getCommonCompanyFileApi = (page) => {
	return API.get(`/internal/admin/common/companyFile?page=${page}&limit=10`);
};
export const getCommonProjectFileApi = (page) => {
	return API.get(`/internal/admin/common/projectFile?page=${page}&limit=10`);
};
export const getCommonBounceApi = (page) => {
	return API.get(`/internal/admin/common/bounce?page=${page}&limit=10`);
};
export const getCommonEnhancerFileApi = (page, limit) => {
	return API.get(
		`/internal/admin/common/enhancerFile?page=${page}&limit=${limit}`
	);
};
