/** @format */

import React, { useEffect, useState } from 'react';

const OverlayModal = ({ isOpen, onClose, children, addPlan }) => {
	const [translateY, setTranslateY] = useState(false);
	const handleOverlayClick = (e) => {
		if (e.target === e.currentTarget) {
			setTranslateY(false);
			onClose();
		}
	};

	const handleClose = () => {
		setTranslateY(false);
		onClose();
	};

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				setTranslateY(true);
			}, 100);
		} else {
			setTranslateY(false);
		}
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 z-50 flex justify-center items-start'>
			<div
				className='absolute inset-0 bg-slate-900 opacity-60'
				onClick={handleOverlayClick}
			></div>
			<div
				className={`w-auto p-2.5 transform border-none transition-transform duration-200 bg-white rounded-lg shadow-lg ${
					translateY
						? addPlan
							? 'translate-y-6'
							: 'translate-y-24'
						: '-translate-y-full'
				}`}
			>
				{children}
			</div>
		</div>
	);
};

export default OverlayModal;
