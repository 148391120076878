import { NotificationManager } from "react-notifications";
import { getUnPaidInvoiceNotification } from "../APIs";

export const getUnPaidInvoiceNotificationReducer = async (updateState) => {
  try {
    const res = await getUnPaidInvoiceNotification();
    updateState(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
