/** @format */

import React, { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../Context/AuthContext';
import { MARK_AS_PAID_INVOICE } from '../../../Context/Types';
import OverlayModal from '../../../Elements/Misc/Modal';

const MarkAsPaidPopUp = (props) => {
	const { dispatch } = useContext(AuthContext);
	const [value, setValue] = React.useState('free');
	const [info, setInfo] = useState({
		amount: '',
		date: '',
		txnId: '',
	});

	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		let formatedOptions = {
			amount: 0,
			txnId: '',
			date: '',
			offlinePayment: false,
		};
		if (value === 'offline') {
			if (!info.amount || !info.txnId || !info.date) {
				//("Every field is required!");
				return;
			}
			if (+info.amount <= 0) {
				//("Enter valid Amount!");
				return;
			}
			formatedOptions = {
				amount: +info.amount,
				txnId: info.txnId,
				date: info.date,
				offlinePayment: true,
			};
		}
		dispatch({
			type: MARK_AS_PAID_INVOICE,
			values: {
				invoice_id: props.selectedInvoice._id,
				company_id: props.selectedCompanyByAdmin,
				...formatedOptions,
			},
			setIsLoading: setIsLoading,
			updateState: props.setRows,
			setallData: props.setallData,
			closeModal: props.close,
		});
	};

	const reasonOptions = ['free', 'offline'];

	return (
		<OverlayModal isOpen={props.open} onClose={props.close}>
			<div className='w-96'>
				<div className='flex justify-between items-center gap-4'>
					<h3 className='text-sm font-semibold text-gray-800'>Mark as Paid</h3>
					<div
						onClick={props.close}
						className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</div>
				</div>
				<h2 className='text-sm font-medium text-left mt-4'>
					Select an Option:
				</h2>
				<div className='w-full my-2 rounded-lg'>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center'>
						{reasonOptions?.length > 0 &&
							reasonOptions?.map((item, i) => (
								<div
									key={i}
									className={`rounded-full ${
										value === item
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={() => setValue(item)}
										className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
									>
										<input
											name={`userReason${i}`}
											type='radio'
											checked={value === item}
											class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
										/>
										{item?.toUpperCase()}
									</h3>
								</div>
							))}
					</div>
				</div>
				{value === 'offline' && (
					<div>
						<div className='mt-3'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Amount:
							</h2>
							<input
								type='text'
								value={info?.amount}
								onChange={(e) => setInfo({ ...info, amount: e.target.value })}
								placeholder='Enter Amount'
								required
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
						<div className='mt-2'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Date:
							</h2>
							<input
								type='date'
								value={info?.date}
								onChange={(e) => setInfo({ ...info, date: e.target.value })}
								placeholder='Enter Date'
								required
								className='uppercase border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
						<div className='mt-2'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Transaction ID:
							</h2>
							<input
								type='text'
								value={info?.txnId}
								onChange={(e) => setInfo({ ...info, txnId: e.target.value })}
								placeholder='Enter Transaction ID'
								required
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
					</div>
				)}

				<button
					disabled={false}
					onClick={handleClick}
					className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
				>
					{isLoading ? (
						<CircularProgress sx={{ color: 'white' }} size={14} />
					) : (
						'Submit'
					)}
				</button>
			</div>
		</OverlayModal>
	);
};

export default MarkAsPaidPopUp;
