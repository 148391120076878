/** @format */
import Pagination from './APagination';
import { useRef, useState, useEffect } from 'react';

const PaginationRow = ({
	totalResults,
	page,
	setPage,
	rowsPerPage,
	setRowsPerPage,
}) => {
	const dropdownRef = useRef(null);
	const [dropdown, setDropdown] = useState(false);
	const handleClickOutside = (e) => {
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			setDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className='z-0 px-4 mt-2 pb-2 flex justify-between items-center gap-2'>
			<p className='text-gray-800 text-xs font-semibold'>
				{totalResults} results
			</p>
			<Pagination
				currentPage={page}
				totalPages={Math.ceil(totalResults / rowsPerPage)}
				pageSize={rowsPerPage}
				onPageChange={(page) => setPage(page)}
			/>
			<div
				ref={dropdownRef}
				className='relative flex items-center gap-1 text-xs'
			>
				<p>Records per page:</p>
				<div
					onClick={() => setDropdown(!dropdown)}
					className='cursor-pointer border border-gray-300 p-2 font-medium rounded-lg text-2xs hover:bg-gray-200'
				>
					{rowsPerPage}
				</div>
				{dropdown && (
					<div
						class='absolute bottom-4 right-0 w-20 z-10 transition-[margin,opacity] duration-300 mb-1 bg-white shadow-md rounded-lg p-1 border'
						aria-labelledby='hs-small-pagination-dropdown'
					>
						{[10, 20, 50].map((op, i) => (
							<button
								key={i}
								type='button'
								onClick={() => setRowsPerPage(op)}
								class='w-full flex items-center gap-x-3.5 py-1.5 px-2 rounded-lg text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none'
							>
								{op}
								{op === rowsPerPage && (
									<svg
										class='w-3 h-3 ms-auto text-blue-600'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									>
										<polyline points='20 6 9 17 4 12'></polyline>
									</svg>
								)}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default PaginationRow;
