/** @format */

import React from 'react';
import './TableSkeltonLoader.css';
const TableSkeltonLoader = ({ countRow }) => {
	const arr = [...Array(countRow)];
	return (
		<table className='tg mt-0'>
			<thead>
				<tr>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
					<th className='tg-cly1'>
						<div className='line'></div>
					</th>
				</tr>
			</thead>
			<tbody>
				{arr?.map((item, index) => {
					return (
						<tr key={index}>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
							<td className='tg-cly1'>
								<div className='line'></div>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default TableSkeltonLoader;
