/** @format */

import React, { createContext, useState, useEffect } from 'react';

const NavbarContext = createContext();

export const NProvider = ({ children }) => {
	//   const [darkMode, setDarkMode] = useState(
	//     JSON.parse(localStorage.getItem("darkMode")) || false
	//   );
	const [tourAgain, setTourAgain] = useState(false);
	const [isFullScreen, setIsFullScreen] = useState(
		JSON.parse(localStorage.getItem('isFullScreen')) || false
	);

	const toggleFullScreen = () => {
		if (!isFullScreen) {
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen();
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
		setIsFullScreen(!isFullScreen);
	};

	//   const toggleDarkMode = () => {
	//     setDarkMode((prevDarkMode) => !prevDarkMode);
	//   };

	//   useEffect(() => {
	//     localStorage.setItem("darkMode", JSON.stringify(darkMode));
	//   }, [darkMode]);

	useEffect(() => {
		localStorage.setItem('isFullScreen', JSON.stringify(isFullScreen));
	}, [isFullScreen]);

	useEffect(() => {
		// const savedDarkMode = JSON.parse(localStorage.getItem("darkMode"));
		// if (savedDarkMode !== null) {
		//   setDarkMode(savedDarkMode);
		// }

		const savedIsFullScreen = JSON.parse(localStorage.getItem('isFullScreen'));
		if (savedIsFullScreen !== null) {
			setIsFullScreen(savedIsFullScreen);
		}
	}, []);

	return (
		<NavbarContext.Provider
			value={{ isFullScreen, toggleFullScreen, tourAgain, setTourAgain }}
		>
			{children}
		</NavbarContext.Provider>
	);
};

export default NavbarContext;
