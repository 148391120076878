/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../Context/AuthContext';
import {
	CREATE_COMPANY_ADMIN,
	GET_ALL_SUBSCRIPTION_PLAN,
} from '../../../Context/Types';

const AddCompany = (props) => {
	const [info, setInfo] = useState({
		name: '',
		email: '',
		mobile: '',
		company_name: '',
		password: '',
	});
	const [subscriptions, setSubscriptions] = useState([]);
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [subscriptionOptions, setSubscriptionOptions] = useState([]);
	const [selectedType, setSelectedType] = useState('FREE');
	const [selectedSubscription, setSelectedSubscription] = useState(null);

	useEffect(() => {
		dispatch({
			type: GET_ALL_SUBSCRIPTION_PLAN,
			id: '',
			flag: 'COMMON',
			updateState: setSubscriptions,
			setIsLoading: setIsLoading,
		});
	}, []);

	useEffect(() => {
		setSubscriptionOptions(
			subscriptions.map((item) => {
				return {
					value: item._id,
					label: item.title,
				};
			})
		);
	}, [subscriptions]);

	const handleSubmit = () => {
		if (!selectedType) {
			return;
		}
		for (const [key, value] of Object.entries(info)) {
			if (key === 'mobile' || key == 'company_name') continue;
			if (!value) {
				return;
			}
		}
		let values = { ...info, type: selectedType };
		if (selectedType === 'PREMIUM') {
			if (!selectedSubscription) {
				//("Choose Subscription!");
				return;
			}
			values['subscription_id'] = selectedSubscription?.value;
		}
		dispatch({
			type: CREATE_COMPANY_ADMIN,
			values: values,
			closeModal: props.close,
			setIsLoading: setIsLoading,
		});
	};

	return (
		<>
			<div className='mt-4'>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Name:
					</h2>
					<input
						type='text'
						value={info?.name}
						onChange={(e) => setInfo({ ...info, name: e.target.value })}
						placeholder='Enter name'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Company Name:
					</h2>
					<input
						type='text'
						value={info?.company_name}
						onChange={(e) => setInfo({ ...info, company_name: e.target.value })}
						placeholder='Enter company name'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Email:
					</h2>
					<input
						type='text'
						value={info?.email}
						onChange={(e) => setInfo({ ...info, email: e.target.value })}
						placeholder='Enter email'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Mobile:
					</h2>
					<input
						type='text'
						value={info?.mobile}
						onChange={(e) => setInfo({ ...info, mobile: e.target.value })}
						placeholder='Enter mobile number'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Password:
					</h2>
					<input
						type='text'
						value={info?.password}
						onChange={(e) => setInfo({ ...info, password: e.target.value })}
						placeholder='Enter password'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						User Type:
					</h2>
					<div className='w-full my-2 rounded-lg'>
						<div className='flex flex-wrap gap-x-2 gap-y-2 items-center'>
							<div
								className={`rounded-full ${
									selectedType === 'FREE'
										? 'border-check-active'
										: 'border-check-light'
								}`}
							>
								<h3
									onClick={() => setSelectedType('FREE')}
									className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
								>
									<input
										name={'userTypefree'}
										type='radio'
										checked={selectedType === 'FREE'}
										defaultChecked
										class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
									/>
									Free
								</h3>
							</div>
							<div
								className={`rounded-full ${
									selectedType === 'PREMIUM'
										? 'border-check-active'
										: 'border-check-light'
								}`}
							>
								<h3
									onClick={() => setSelectedType('PREMIUM')}
									className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
								>
									<input
										name={'usertypepremium'}
										type='radio'
										checked={selectedType === 'PREMIUM'}
										class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
									/>
									Premium
								</h3>
							</div>
						</div>
					</div>
				</div>
				{selectedType === 'PREMIUM' && (
					<div className='mt-2'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Plan:
						</h2>
						<div className='w-full my-2 rounded-lg'>
							<div className='flex flex-wrap gap-x-2 gap-y-2 items-center'>
								{subscriptionOptions?.length > 0 &&
									subscriptionOptions?.map((item, i) => (
										<div
											key={i}
											className={`rounded-full ${
												selectedSubscription?.value === item?.value
													? 'border-check-active'
													: 'border-check-light'
											}`}
										>
											<h3
												onClick={() => setSelectedSubscription(item)}
												className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
											>
												<input
													name={`userPlan${i}`}
													type='radio'
													checked={selectedSubscription?.value === item?.value}
													class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
												/>
												{item?.label}
											</h3>
										</div>
									))}
							</div>
						</div>
					</div>
				)}
			</div>

			<button
				disabled={false}
				onClick={handleSubmit}
				className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
			>
				{isLoading ? (
					<CircularProgress sx={{ color: 'white' }} size={14} />
				) : (
					'Submit'
				)}
			</button>
		</>
	);
};

export default AddCompany;
