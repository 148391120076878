/** @format */

import axios from 'axios';
import Cookies from 'js-cookie';

// for live server
const API = axios.create({
	baseURL: 'https://api.healthdbi.com/',
});

API.interceptors.request.use((req) => {
	if (Cookies.get('token')) {
		req.headers['token'] = `${Cookies.get('token')}`;
	}
	return req;
});

API.interceptors.response.use(
	(res) => res,
	async (error) => {
		if (
			// error.response.status === 400 &&
			error.response.data === 'Account not found!' ||
			error.response.data === 'Invalid Token' ||
			error.response.data === 'Token Expired!' ||
			error.response.data === 'Please login again!'
		) {
			Cookies.remove('token');
			window.location.reload();
		}
		return Promise.reject(error);
	}
);

export const getStates = async (searchValue) => {
	try {
		const response = await API.post('location/getLocationState', {
			State: [`${searchValue}`],
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};

export const getCities = async (selectedState, searchValue) => {
	let arr = selectedState;
	if (arr?.length == 0) arr = [''];
	try {
		const response = await API.post('location/getLocation', {
			State: arr,
			City: [`${searchValue}`],
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getZipCode = async (searchValue) => {
	try {
		const response = await API.get('location/getZipCode', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getSpecialityGroup1 = async (searchValue) => {
	try {
		const response = await API.post('speciality/getSptyGroup', {
			SpecialtyGroup1: [`${searchValue}`],
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getSpeciality = async (selectedGroup1, searchValue) => {
	let arr = selectedGroup1;
	if (arr?.length == 0) arr = [''];
	try {
		const response = await API.post('speciality/getSpty', {
			SpecialtyGroup1: arr,
			Specialty: [`${searchValue}`],
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
};
export const getSpecialityMasterAPI = async (searchValue) => {
	try {
		const response = await API.post('speciality/getSpty', {
			common: searchValue,
		});
		return response.data;
	} catch (error) {
		console.log(error);
	}
};
export const getSpecialityType = async (searchValue) => {
	try {
		const response = await API.get('speciality/getSpType', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getCountry = async (searchValue = '') => {
	try {
		const response = await API.get('location/getCountry', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getTitle = async (searchValue) => {
	try {
		const response = await API.get('others/getTitle', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getHospitalType = async (searchValue) => {
	try {
		const response = await API.get('others/getHosType', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getFirmType = async (searchValue) => {
	try {
		const response = await API.get('others/getFirmType', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getOwnership = async (searchValue) => {
	try {
		const response = await API.get('others/getOwnership', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
export const getLicenseState = async (searchValue) => {
	try {
		const response = await API.get('others/getLicenseStates', {
			params: {
				name: searchValue,
			},
		});
		return response.data.data;
	} catch (error) {
		console.log(error);
	}
};
