/** @format */

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const useAuth = () => {
	const token = Cookies.get('token');
	let auth = null;
	if (token) {
		if (token === 'undefined') return { auth };
		let { id, role } = jwtDecode(token);
		if (String(role) === 'MEMBER') role = 'user';
		if (String(role) === 'SUB_ADMIN') role = 'subadmin';
		role = role?.toLowerCase();
		auth = { id: id, role };
	}
	return { auth };
};

export default useAuth;
