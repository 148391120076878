/** @format */

import React, { useContext, useState, useEffect } from 'react';
import { MainContext } from '../../Context/FilterContext/FilterContext';
import { AuthContext } from '../../Context/AuthContext';
import Animation from '../../assests/image/loading.json';
import { CircularProgress } from '@mui/material';
import lottie from 'lottie-web';
import { FILTER_CONTACTS, GET_SAVE_SEARCH } from '../../Context/Types';

import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../Context/filterCompo/filterTypes';
import JobTitleFilter from '../../Elements/Filters/JobTitle';
import LocationFilter from '../../Elements/Filters/Location';
import NetworkFilter from '../../Elements/Filters/Network';
import OwnershipFilter from '../../Elements/Filters/Ownership';
import OfficeFilter from '../../Elements/Filters/Office';
import SpecialityFilter from '../../Elements/Filters/Speciality';
import HCPFilter from '../../Elements/Filters/HCP';
import ExperienceFilter from '../../Elements/Filters/Experience';
import LicenseFilter from '../../Elements/Filters/License';

const executiveFilter = [
	{
		title: 'Title',
		displayName: 'Job Title',
		sorting: 'title',
	},
	{
		title: 'Level ID',
		sorting: 'levelID',
		displayName: 'Management Level',
	},
	{
		title: 'Firm Type',
		sorting: 'firmType',
		displayName: 'Network',
	},
	{
		title: 'Location',
		sorting: 'location',
		displayName: 'Location',
	},
	{
		title: 'Hospital Type',
		sorting: 'hospitalType',
		displayName: 'Facility Type',
	},

	{
		title: 'Ownership',
		sorting: 'ownership',
		displayName: 'Ownership',
	},

	{
		title: 'Geographic Classification',
		sorting: 'geographic',
		displayName: 'Geographic Classification',
	},
];

const physicianFilter = [
	{
		title: 'Speciality',
		displayName: 'Speciality',
		sorting: 'specialityGroup',
	},

	{
		title: 'Speciality Type',
		displayName: 'Speciality Type',
		sorting: 'specialityType',
		displayName: 'HCP Type',
	},

	{
		title: 'Location',
		displayName: 'Location',
		sorting: 'location',
	},

	{
		title: 'Licensed State',
		sorting: 'licenseState',
		displayName: 'Licensed State',
	},
	{
		title: 'Experience',
		sorting: 'experience',
		displayName: 'Experience',
	},

	{
		title: 'Gender',
		sorting: 'gender',
		displayName: 'Gender',
	},

	{
		title: 'NPI Number',
		sorting: 'npi',
		displayName: 'NPI Match',
	},
	{
		title: 'Office Based',
		displayName: 'Office Based',
	},
	{
		title: 'Email Type',
		displayName: 'Email Type',
	},
];

const Filter = (props) => {
	const [filterData, setFilterList] = useState(physicianFilter);
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [searchDisable, setSearchDisable] = useState(true);
	const [titleCount, setTitleCount] = useState(0);
	const [locationCount, setLocationCount] = useState(0);
	const [specialityCount, setSpecialityCount] = useState(0);
	const [hcpCount, setHcpCount] = useState(0);
	const [experienceCount, setExperienceCount] = useState(0);
	const [officeCount, setOfficeCount] = useState(0);
	const [licenseCount, setLicenseCount] = useState(0);
	const [networkCount, setNetworkCount] = useState(0);
	const [ownershipCount, setOwnershipCount] = useState(0);
	const [filterModal, setFilterModal] = useState('Job Title');
	const [filterCross, setFilterCross] = useState(0);

	const {
		anyFilterFlag,
		typesOfFilter,
		setTypesOfFilter,
		profile,
		dispatch,
		filter,
		setSortField,
		setFilter,
		setAllContacts,
		setFilterListData,
		setSelectedLeads,
		setFirstTimeApiCall,
		setFirstTimeFilter,
		setPage,
		setTitleInput,
		setCompanyInput,
		setCountryInput,
		setRegionInput,
		setCityInput,
		setStateInput,
		setPinInput,
		setIndustryInput,
		setSicInput,
		setKeywordInput,
		setSelectedDepartment,
		setSelectedLevel,
		setSelectedRevenue,
		setSelectedEmployee,
		setSelectedCompanyType,
		setNumOfRecordsFound,
		setAvailableStats,
		selectedFilter,
		setSelectedFilter,
		setSelectedRegion,

		setValue,
		setSpecialityInputValue,
		setSpecialityInputValue1,
		setSpecialityInputValue2,
		setTypeInputValue,
		setZipInput,
		setLicenseInput,

		setLevelInput,
		setFacilityInput,
		setNetworkInput,
		setOwnerInput,
		setGeoInput,
		setSelectedVal,
	} = useContext(AuthContext);
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		filterKeyToGlobalFilterKey,
	} = useContext(MainContext);

	const handleFilterSubmit = () => {
		setSelectedFilter('');
		setPage(1);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		dispatch({
			type: FILTER_CONTACTS,
		});
		anyFilterFlag.current = true;
		setIsFilterOpen(false);
	};

	const handleClearSearch = () => {
		setSpecialityInputValue(''),
			setSpecialityInputValue1(''),
			setSpecialityInputValue2(''),
			setTypeInputValue(''),
			setCountryInput(''),
			setStateInput(''),
			setCityInput(''),
			setZipInput(''),
			setLicenseInput(''),
			setTitleInput(''),
			setLevelInput(''),
			setFacilityInput(''),
			setNetworkInput(''),
			setOwnerInput(''),
			setGeoInput(''),
			setSelectedVal('');
		setFirstTimeApiCall(false);
		setPage(1);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setValue([0, 99]);
		setSortField({});
		setSortField({
			contact_name_sort: null,
			address_sort: null,
			city_sort: null,
			state_sort: null,
			country_sort: null,
			zipcode_sort: null,
			speciality_sort: null,
			specialityType_sort: null,
			specialityGroup_sort: null,
			gender_sort: null,
			title_sort: null,
			hospitalType_sort: null,
			firmType_sort: null,
			levelID_sort: null,
			ownership_sort: null,
			geographic_sort: null,
			experience_sort: null,
			licenseState_sort: null,
			npi_sort: null,
			degree_sort: null,
			hospitalName_sort: null,
			department_sort: null,
			webaddress_sort: null,
			bedrange_sort: null,
		});
		setFilter({
			contact_name: [],
			tabletitle: [],
			tablehospitalName: [],
			tablehospitalType: [],
			tabledepartment: [],
			tablecity: [],
			tablestate: [],
			tablespeciality: [],
			tablespecialityGroup: [],
			tablespecialityType: [],
			tablewebaddress: [],
			tablefirmType: [],
			tableownership: [],
			tablebedrange: [],
			tabledegree: [],
			tablenpi: [],
			address: [],
			city: [],
			state: [],
			country: [],
			zipcode: [],
			speciality: [],
			specialityType: [],
			specialityGroup: [],
			gender: [],
			title: [],
			hospitalType: [],
			firmType: [],
			levelID: [],
			ownership: [],
			geographic: [],
			experience: [],
			licenseState: [],
			npi: [],
			//new
			degree: [],
			hospitalName: [],
			department: [],
			webaddress: [],
			bedrange: [],

			officetype: [],
			emailtype: [],
		});
		let emptyGlobalSelectFilter = {};
		for (const [key, value] of Object.entries(globalSelectFilter)) {
			emptyGlobalSelectFilter[key] = [];
		}
		setGlobalSelectFilter(emptyGlobalSelectFilter);
		setAllContacts([]);
		setSelectedLeads([]);
		anyFilterFlag.current = false;
		// setTypesOfFilter(PHYSICIAN_FILTER);
		// dispatch({
		//   type: FILTER_CONTACTS,
		//   setAllContacts: setAllContacts,
		//   page: page,
		//   limit: rowsPerPage,
		// });
	};

	useEffect(() => {
		let dataType = 0;
		if (typesOfFilter === PHYSICIAN_FILTER) {
			dataType = 0;
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			dataType = 1;
		}
		dispatch({
			type: GET_SAVE_SEARCH,
			updateState: setRows,
			setIsLoading: setIsLoading,
			dataType: dataType,
		});
	}, [typesOfFilter]);

	const handleResetTitleFilter = () => {
		setFilter((prev) => {
			return { ...prev, title: [], levelID: [] };
		});
		setGlobalSelectFilter((prev) => {
			return { ...prev, title: [], levelID: [] };
		});
	};

	const handleResetLocationFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				country: [],
				state: [],
				city: [],
				zipcode: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				country: [],
				state: [],
				city: [],
				zipcode: [],
			};
		});
	};

	const handleResetSpecialityFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				speciality: [],
				specialityGroup: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				speciality: [],
				specialityGroup: [],
			};
		});
	};

	const handleResetHCPFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				specialityType: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				specialityType: [],
			};
		});
	};

	const handleResetExperienceFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				experience: [],
				gender: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				experience: [],
				gender: [],
			};
		});
	};

	const handleResetLicenseFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				licenseState: [],
				npi: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				licenseState: [],
				npi: [],
			};
		});
		setFilterCross(0);
	};

	const handleResetOfficeFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				officetype: [],
				emailtype: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				officetype: [],
				emailtype: [],
			};
		});
		setFilterCross(0);
	};

	const handleResetNetworkFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				hospitalType: [],
				firmType: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				hospitalType: [],
				firmType: [],
			};
		});
		setFilterCross(0);
	};

	const handleResetOwnershipFilter = () => {
		setFilter((prev) => {
			return {
				...prev,
				ownership: [],
				geographic: [],
			};
		});
		setGlobalSelectFilter((prev) => {
			return {
				...prev,
				ownership: [],
				geographic: [],
			};
		});
	};

	const [seeMore, setSeeMore] = useState(false);
	const moreSavedSearches = rows.length > 0 ? rows?.slice(0, 15) : [];
	const savedSearches = rows.length > 0 ? rows?.slice(0, 3) : [];

	const [isFilterOpen, setIsFilterOpen] = useState(false);

	const toggleFilterDropdown = (filterData) => {
		if (filterData === filterModal) {
			setIsFilterOpen(!isFilterOpen);
		} else {
			setIsFilterOpen(true);
		}
		setFilterModal(filterData);
	};

	function areAllArraysEmpty(obj) {
		for (let key in obj) {
			if (Array.isArray(obj[key]) && obj[key].length > 0) {
				return false;
			}
		}
		return true;
	}

	useEffect(() => {
		if (titleCount === 0) {
			setFilterCross(0);
		}
		if (locationCount === 0) {
			setFilterCross(0);
		}
		if (specialityCount === 0) {
			setFilterCross(0);
		}
		if (hcpCount === 0) {
			setFilterCross(0);
		}
		if (experienceCount === 0) {
			setFilterCross(0);
		}
		if (licenseCount === 0) {
			setFilterCross(0);
		}
		if (officeCount === 0) {
			setFilterCross(0);
		}
		if (networkCount === 0) {
			setFilterCross(0);
		}
		if (ownershipCount === 0) {
			setFilterCross(0);
		}
	}, [
		titleCount,
		locationCount,
		specialityCount,
		hcpCount,
		experienceCount,
		licenseCount,
		officeCount,
		networkCount,
		ownershipCount,
	]);

	useEffect(() => {
		setSearchDisable(areAllArraysEmpty(filter));
		var titleCounter = filter?.title?.length ? filter.title.length : 0;
		titleCounter += filter?.levelID?.length ? filter.levelID.length : 0;
		setTitleCount(titleCounter);

		var locationCounter = filter?.country?.length ? filter.country.length : 0;
		locationCounter += filter?.state?.length ? filter.state.length : 0;
		locationCounter += filter?.city?.length ? filter.city.length : 0;
		locationCounter += filter?.zipcode?.length ? filter.zipcode.length : 0;
		setLocationCount(locationCounter);

		var specialityCounter = filter?.speciality?.length
			? filter.speciality.length
			: 0;
		specialityCounter += filter?.specialityGroup?.length
			? filter.specialityGroup.length
			: 0;
		setSpecialityCount(specialityCounter);

		var experienceCounter = filter?.experience?.length > 0 ? 1 : 0;
		experienceCounter += filter?.gender?.length ? filter.gender.length : 0;
		setExperienceCount(experienceCounter);

		var hcpCounter = filter?.specialityType?.length
			? filter.specialityType.length
			: 0;
		setHcpCount(hcpCounter);

		var licenseCounter = filter?.licenseState?.length
			? filter.licenseState.length
			: 0;
		licenseCounter += filter?.npi?.length > 0 ? 1 : 0;
		setLicenseCount(licenseCounter);

		var officeCounter = filter?.officetype?.length
			? filter.officetype.length
			: 0;
		officeCounter += filter?.emailtype?.length ? filter.emailtype.length : 0;
		setOfficeCount(officeCounter);

		var networkCounter = filter?.firmType?.length ? filter.firmType.length : 0;
		networkCounter += filter?.hospitalType?.length
			? filter.hospitalType.length
			: 0;
		setNetworkCount(networkCounter);

		var ownershipCounter = filter?.ownership?.length
			? filter.ownership.length
			: 0;
		ownershipCounter += filter?.geographic?.length
			? filter.geographic.length
			: 0;
		setOwnershipCount(ownershipCounter);
	}, [filter]);

	useEffect(() => {
		const animation = lottie.loadAnimation({
			container: document.getElementById('fullscreen-animation'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: Animation,
		});
		animation.setSpeed(3);

		// Clean up animation when component unmounts
		return () => {
			animation.destroy();
		};
	}, []);

	return (
		<>
			{isLoading && profile === undefined && (
				<div className='absolute bg-gray-900 z-max opacity-90 h-full w-full text-white flex justify-center items-center'>
					<div
						id='fullscreen-animation'
						style={{ width: '130px', height: '130px', opacity: 1 }}
					></div>
				</div>
			)}
			<div className='h-screen m-0 pt-10 w-52 bg-white fixed top-0 z-4'>
				<div className='h-screen bg-white relative z-4'>
					<div class='pt-3 px-2 z-4'>
						<div id='step3' class='py-1 first:pt-0 last:pb-0'>
							<span class='py-1 text-2xs font-medium text-gray-600 flex items-center justify-between'>
								<p className='uppercase'>Saved Searches</p>
								{isLoading ? (
									<CircularProgress size={10} style={{ color: '#2F5EFF' }} />
								) : (
									rows.length > 0 && (
										<p
											//onClick={() => props.navigate('/mylist')}
											onClick={() => setSeeMore(!seeMore)}
											className='text-blue-600 hover:underline cursor-pointer font-semibold'
										>
											{seeMore ? 'See Less' : 'See More'}
										</p>
									)
								)}
							</span>
							{seeMore
								? moreSavedSearches.length > 0 &&
								  moreSavedSearches.map((search, i) => (
										<div
											key={i}
											onClick={() => {
												// setTypesOfFilter(selectedFilter);
												anyFilterFlag.current = true;
												setFilter(search.search_params);
												setFilter((prev) => {
													return { ...prev, type: [search.type] };
												});
												setGlobalSelectFilter(search.search_params);
												setGlobalSelectFilter((prev) => {
													return { ...prev, type: [search.type] };
												});
												handleFilterSubmit();
												setIsFilterOpen(false);
											}}
											class={`flex items-center justify-start py-1.5 hover:bg-gray-100 rounded-lg px-2 cursor-pointer ${
												i == 0 ? 'mt-1.5' : ''
											}`}
										>
											<span class='w-1 h-1 inline-block bg-blue-600 rounded-full me-2'></span>
											<span class='text-gray-800 text-xs font-medium'>
												{search?.search_name.length > 22
													? search?.search_name.substring(0, 22) + '...'
													: search?.search_name}
											</span>
										</div>
								  ))
								: savedSearches.length > 0 &&
								  savedSearches.map((search, i) => (
										<div
											key={i}
											onClick={() => {
												anyFilterFlag.current = true;
												setFilter(search.search_params);
												setFilter((prev) => {
													return { ...prev, type: [search.type] };
												});
												setGlobalSelectFilter(search.search_params);
												setGlobalSelectFilter((prev) => {
													return { ...prev, type: [search.type] };
												});
												handleFilterSubmit();
												setIsFilterOpen(false);
											}}
											class={`flex items-center justify-start py-1.5 hover:bg-gray-100 rounded-lg px-2 cursor-pointer ${
												i == 0 ? 'mt-1.5' : ''
											}`}
										>
											<span class='w-1 h-1 inline-block bg-blue-600 rounded-full me-2'></span>
											<span class='text-gray-800 text-xs font-medium'>
												{search?.search_name.length > 22
													? search?.search_name.substring(0, 22) + '...'
													: search?.search_name}
											</span>
										</div>
								  ))}

							{seeMore ? (
								<button
									onClick={() => props.navigate('/myList?active=search')}
									className={`mt-2 bg-blue-100 !text-blue-700 hover:!text-blue-800 rounded-lg focus:outline-none border-none w-full py-2 text-xs font-semibold hover:font-semibold flex justify-center items-center gap-2`}
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-3.5 h-3.5'
									>
										<path d='M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z' />
										<path d='M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
										<path d='M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2' />
										<path d='M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2' />
									</svg>
									View All
								</button>
							) : (
								<button
									onClick={() => props.setSaveSearch(true)}
									disabled={!props.isResult}
									style={{ backgroundColor: '#CCFBF1', color: '#115E59' }}
									className={`mt-2 rounded-lg focus:outline-none border-none w-full py-2 text-xs font-semibold hover:font-semibold flex justify-center items-center gap-2 ${
										props.isResult
											? 'cursor-pointer hover:!text-teal-900'
											: 'opacity-75 cursor-default'
									}`}
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-3.5 h-3.5'
									>
										<circle cx='12' cy='12' r='10' />
										<path d='M8 12h8' />
										<path d='M12 8v8' />
									</svg>
									Save Search
								</button>
							)}
						</div>
						{!seeMore && (
							<>
								<hr className='bg-slate-500 my-2' />
								<div id='step4' class='py-2 first:pt-0 last:pb-0'>
									<span class='py-1.5 mb-1 text-2xs font-medium text-gray-600 flex items-center justify-between'>
										<p className='uppercase'>Search Filters</p>
										{(!areAllArraysEmpty(filter) || props.isResult) && (
											<p
												onClick={handleClearSearch}
												className='text-red-600 cursor-pointer font-semibold hover:underline hover:text-red-500'
											>
												Clear All
											</p>
										)}
									</span>
									<div class='flex w-full pt-1'>
										<div class='mx-auto flex bg-gray-200 hover:bg-slate-200 rounded-lg transition p-1'>
											<nav class='flex gap-2' aria-label='Tabs' role='tablist'>
												<button
													type='button'
													class={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg ${
														typesOfFilter === EXECUTIVE_FILTER
															? 'bg-white text-gray-800'
															: 'bg-transparent text-gray-600 hover:text-gray-700'
													}`}
													id='segment-item-1'
													data-hs-tab='#segment-1'
													aria-controls='segment-1'
													role='tab'
													onClick={() => {
														if (typesOfFilter !== EXECUTIVE_FILTER) {
															setTypesOfFilter(EXECUTIVE_FILTER);
															anyFilterFlag.current = false;
															setFilterList(executiveFilter);
															setFirstTimeApiCall(false);
														}
													}}
												>
													Executives
												</button>
												<button
													type='button'
													class={`py-1 px-2 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg ${
														typesOfFilter === PHYSICIAN_FILTER
															? 'bg-white text-gray-800'
															: 'bg-transparent text-gray-600 hover:text-gray-700'
													}`}
													id='segment-item-1'
													data-hs-tab='#segment-1'
													aria-controls='segment-1'
													role='tab'
													onClick={() => {
														if (typesOfFilter !== PHYSICIAN_FILTER) {
															setTypesOfFilter(PHYSICIAN_FILTER);
															anyFilterFlag.current = false;
															setFilterList(physicianFilter);
															setFirstTimeApiCall(false);
														}
													}}
												>
													Physicians
												</button>
											</nav>
										</div>
									</div>
									{typesOfFilter === PHYSICIAN_FILTER ? (
										<>
											<div
												id='specClick'
												onClick={() => toggleFilterDropdown('Speciality')}
												class='flex justify-between cursor-pointer items-center mt-2 px-1 py-2 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none'
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z' />
														<path d='m9 12 2 2 4-4' />
													</svg>
													Speciality
												</p>
												{specialityCount > 0 && (
													<div
														onMouseEnter={() => setFilterCross(1)}
														onMouseLeave={() => setFilterCross(0)}
														onClick={() => {
															setFilterCross(0);
															handleResetSpecialityFilter();
														}}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 1 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															specialityCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('HCP')}
												class='flex justify-between cursor-pointer items-center px-1 py-2 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none'
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<rect width='7' height='7' x='14' y='3' rx='1' />
														<path d='M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3' />
													</svg>
													HCP Type
												</p>
												{hcpCount > 0 && (
													<div
														onMouseEnter={() => setFilterCross(1)}
														onMouseLeave={() => setFilterCross(0)}
														onClick={() => {
															setFilterCross(0);
															handleResetHCPFilter();
														}}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 1 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															hcpCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('Location')}
												class={`flex justify-between cursor-pointer items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M18 8c0 4.5-6 9-6 9s-6-4.5-6-9a6 6 0 0 1 12 0' />
														<circle cx='12' cy='8' r='2' />
														<path d='M8.835 14H5a1 1 0 0 0-.9.7l-2 6c-.1.1-.1.2-.1.3 0 .6.4 1 1 1h18c.6 0 1-.4 1-1 0-.1 0-.2-.1-.3l-2-6a1 1 0 0 0-.9-.7h-3.835' />
													</svg>
													Location
												</p>
												{locationCount > 0 && (
													<div
														onClick={() => handleResetLocationFilter()}
														onMouseEnter={() => setFilterCross(2)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 2 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															locationCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('Experience')}
												class={`flex justify-between cursor-pointer items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<circle cx='10' cy='7' r='4' />
														<path d='M10.3 15H7a4 4 0 0 0-4 4v2' />
														<circle cx='17' cy='17' r='3' />
														<path d='m21 21-1.9-1.9' />
													</svg>
													Experience & Gender
												</p>
												{experienceCount > 0 && (
													<div
														onClick={() => handleResetExperienceFilter()}
														onMouseEnter={() => setFilterCross(3)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 3 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															experienceCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('License')}
												class={`flex justify-between cursor-pointer items-center px-1 py-2 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<rect width='20' height='14' x='2' y='5' rx='2' />
														<line x1='2' x2='22' y1='10' y2='10' />
													</svg>
													Licensed State & NPI
												</p>
												{licenseCount > 0 && (
													<div
														onClick={() => handleResetLicenseFilter()}
														onMouseEnter={() => setFilterCross(4)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 4 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															licenseCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('Office')}
												class={`flex justify-between cursor-pointer items-center px-1 py-2 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M7 21h10' />
														<rect width='20' height='14' x='2' y='3' rx='2' />
													</svg>
													Office & Email Type
												</p>
												{officeCount > 0 && (
													<div
														onClick={() => handleResetOfficeFilter()}
														onMouseEnter={() => setFilterCross(5)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 5 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															officeCount
														)}
													</div>
												)}
											</div>
											<span class='pt-2 mb-1 text-2xs font-medium text-gray-600 flex items-center justify-between'>
												<p className='uppercase'>Coming Soon</p>
											</span>
											<div
												//onClick={() => toggleFilterDropdown('Ownership')}
												class={`flex justify-between items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-700 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M12 6v4' />
														<path d='M14 14h-4' />
														<path d='M14 18h-4' />
														<path d='M14 8h-4' />
														<path d='M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2' />
														<path d='M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18' />
													</svg>
													ICD 10 Codes
												</p>
											</div>
											<div
												//onClick={() => toggleFilterDropdown('Ownership')}
												class={`flex justify-between items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-700 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M11 2a2 2 0 0 0-2 2v5H4a2 2 0 0 0-2 2v2c0 1.1.9 2 2 2h5v5c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2v-5h5a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-5V4a2 2 0 0 0-2-2h-2z' />
													</svg>
													HCPCS/CPT Codes
												</p>
											</div>
											<div
												//onClick={() => toggleFilterDropdown('Ownership')}
												class={`flex justify-between items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-700 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='m10.5 20.5 10-10a4.95 4.95 0 1 0-7-7l-10 10a4.95 4.95 0 1 0 7 7Z' />
														<path d='m8.5 8.5 7 7' />
													</svg>
													Rx Drug Name
												</p>
											</div>
										</>
									) : (
										<>
											<div
												onClick={() => toggleFilterDropdown('Job Title')}
												class='flex justify-between cursor-pointer items-center mt-2 px-1 py-2 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none'
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M12 12h.01' />
														<path d='M16 6V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2' />
														<path d='M22 13a18.15 18.15 0 0 1-20 0' />
														<rect width='20' height='14' x='2' y='6' rx='2' />
													</svg>
													Job Title
												</p>
												{titleCount > 0 && (
													<div
														onMouseEnter={() => setFilterCross(1)}
														onMouseLeave={() => setFilterCross(0)}
														onClick={() => {
															setFilterCross(0);
															handleResetTitleFilter();
														}}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 1 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															titleCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('Location')}
												class={`flex justify-between cursor-pointer items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M18 8c0 4.5-6 9-6 9s-6-4.5-6-9a6 6 0 0 1 12 0' />
														<circle cx='12' cy='8' r='2' />
														<path d='M8.835 14H5a1 1 0 0 0-.9.7l-2 6c-.1.1-.1.2-.1.3 0 .6.4 1 1 1h18c.6 0 1-.4 1-1 0-.1 0-.2-.1-.3l-2-6a1 1 0 0 0-.9-.7h-3.835' />
													</svg>
													Location
												</p>
												{locationCount > 0 && (
													<div
														onClick={() => handleResetLocationFilter()}
														onMouseEnter={() => setFilterCross(1)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 1 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															locationCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('Network')}
												class={`flex justify-between cursor-pointer items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<rect x='16' y='16' width='6' height='6' rx='1' />
														<rect x='2' y='16' width='6' height='6' rx='1' />
														<rect x='9' y='2' width='6' height='6' rx='1' />
														<path d='M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3' />
														<path d='M12 12V8' />
													</svg>
													Network & Facility
												</p>
												{networkCount > 0 && (
													<div
														onClick={() => handleResetNetworkFilter()}
														onMouseEnter={() => setFilterCross(2)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 2 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															networkCount
														)}
													</div>
												)}
											</div>
											<div
												onClick={() => toggleFilterDropdown('Ownership')}
												class={`flex justify-between cursor-pointer items-center py-2 px-1 rounded-lg text-xs font-medium text-gray-800 hover:bg-gray-100 focus:outline-none`}
											>
												<p className='flex items-center gap-2'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='w-3.5 h-3.5'
													>
														<path d='M21.54 15H17a2 2 0 0 0-2 2v4.54' />
														<path d='M7 3.34V5a3 3 0 0 0 3 3v0a2 2 0 0 1 2 2v0c0 1.1.9 2 2 2v0a2 2 0 0 0 2-2v0c0-1.1.9-2 2-2h3.17' />
														<path d='M11 21.95V18a2 2 0 0 0-2-2v0a2 2 0 0 1-2-2v-1a2 2 0 0 0-2-2H2.05' />
														<circle cx='12' cy='12' r='10' />
													</svg>
													Ownership & Geo.
												</p>
												{ownershipCount > 0 && (
													<div
														onClick={() => handleResetOwnershipFilter()}
														onMouseEnter={() => setFilterCross(3)}
														onMouseLeave={() => setFilterCross(0)}
														className='bg-blue-600 w-4 h-4 text-white hover:bg-red-600 rounded-full flex justify-center items-center text-2xs font-medium'
													>
														{filterCross === 3 ? (
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																stroke='currentColor'
																stroke-width='3'
																stroke-linecap='round'
																stroke-linejoin='round'
																class='w-2 h-2'
															>
																<path d='M18 6 6 18' />
																<path d='m6 6 12 12' />
															</svg>
														) : (
															ownershipCount
														)}
													</div>
												)}
											</div>
										</>
									)}
								</div>
							</>
						)}
					</div>
				</div>
				<div
					className={`z-3 transition-all duration-300 transform border-l border-slate-300 absolute top-0 -right-52 w-80 h-screen bg-white ${
						isFilterOpen ? 'translate-x-0' : '-translate-x-full'
					}`}
				>
					<div
						style={{ height: '98vh', scrollbarWidth: 'none' }}
						className='text-gray-900 pt-28 pb-12 overflow-y-scroll'
					>
						{filterModal === 'Job Title' ? (
							<JobTitleFilter />
						) : filterModal === 'Location' ? (
							<LocationFilter />
						) : filterModal === 'Network' ? (
							<NetworkFilter />
						) : filterModal === 'Ownership' ? (
							<OwnershipFilter />
						) : filterModal === 'Office' ? (
							<OfficeFilter />
						) : filterModal === 'Speciality' ? (
							<SpecialityFilter />
						) : filterModal === 'HCP' ? (
							<HCPFilter />
						) : filterModal === 'Experience' ? (
							<ExperienceFilter />
						) : (
							<LicenseFilter />
						)}
					</div>
					<div className='fixed w-full bg-white mt-11 py-2 top-0 border-b border-slate-300 flex justify-center gap-2 items-center px-2'>
						<button
							onClick={() => setIsFilterOpen(false)}
							className='w-full hover:bg-gray-100 text-xs font-medium bg-gray-200 text-gray-700 p-2 rounded-lg focus:outline-none border-none flex justify-center items-center gap-2'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2.5'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='m15 18-6-6 6-6' />
							</svg>
							Close
						</button>
						<button
							id='step6'
							onClick={handleFilterSubmit}
							disabled={searchDisable}
							className={`w-full text-xs font-medium bg-blue-600 text-white p-2 rounded-lg focus:outline-none border-none flex justify-center items-center gap-2 ${
								areAllArraysEmpty(filter)
									? 'opacity-75 cursor-default'
									: 'hover:bg-blue-700 cursor-pointer'
							}`}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='3'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<circle cx='11' cy='11' r='8' />
								<path d='m21 21-4.3-4.3' />
							</svg>
							Search
						</button>
					</div>
				</div>
			</div>
			{isFilterOpen && (
				<div
					className='fixed inset-0 bg-black opacity-50 z-2'
					onClick={() => toggleFilterDropdown(filterModal)}
				></div>
			)}
		</>
	);
};
export default Filter;
