import { getUnlockedLeads } from "../APIs";
import NotificationManager from "react-notifications/lib/NotificationManager";

export const getUnlockedLeadsReducer = async (
  updateState,
  setIsLoading,
  dataType
) => {
  setIsLoading(true);
  try {
    const res = await getUnlockedLeads(dataType);
    console.log(res?.data)
    debugger
    if (res.data) {
      const formatedData = res.data
        .slice(0)
        .reverse()
        .map((item) => {
          return {
            ...item,
            Name: item.FirstName + " " + item.LastName,
          };
        });
      updateState(formatedData);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
