/** @format */

export const EXECUTIVE_FILTER = 'executive filter';
export const PHYSICIAN_FILTER = 'physician filter';

export const LOCATION_STATE = 'location state';
export const LOCATION_CITY = 'location city';
export const LOCATION_ZIP_CODE = 'location zip code';
export const LOCATION_COUNTRY = 'location country';

export const SPECIALITY_SPECIALITY_TYPE = 'speciality speciality type';
export const SPECIALITY_SPECIALITY_GROUP1 = 'speciality speciality group';
export const SPECIALITY_SPECIALITY_GROUP2 = 'speciality speciality group1';
export const SPECIALITY_SPECIALITY = 'speciality speciality';

export const CONTACT_NAME = 'contact name';
export const TITLE = 'title';
export const GENDER = 'gender';
export const ADDRESS1 = 'address';
export const LEVEL_ID = 'level id';
export const GEOGRAPHIC_LOCATION = 'geographic location';
export const EXPERIENCE = 'experience';
export const LICENSE_STATE = 'license state';

export const CLINIC_NAME = 'clinic name';
export const HOSPITAL_TYPE = 'hospital type';
export const FIRM_TYPE = 'firm type';
export const OWNERSHIP = 'ownership';

export const EMAIL_TYPE = 'email type';
export const NPI_NUMBER = 'npi number';
export const DEGREE = 'degree';
export const HOSPITAL_NAME = 'hospital name';
export const DEPARTMENT = 'department';
export const WEB_ADDRESS = 'web address';
export const BED_RANGE = 'bedrange';

export const TABLE_TITLE = 'tabletitle';
export const TABLE_HOSPITAL_NAME = 'tablehospitalName';
export const TABLE_HOSPITAL_TYPE = 'tablehospitalType';
export const TABLE_DEPARTMENT = 'tabledepartment';
export const TABLE_CITY = 'tablecity';
export const TABLE_STATE = 'tablestate';
export const TABLE_SPECIALITY = 'tablespeciality';
export const TABLE_SPECIALITY_GROUP = 'tablespecialityGroup';
export const TABLE_SPECIALITY_TYPE = 'tablespecialityType';
export const TABLE_WEBADDRESS = 'tablewebaddress';
export const TABLE_FIRMTYPE = 'tablefirmType';
export const TABLE_OWNERSHIP = 'tableownership';
export const TABLE_BEDRANGE = 'tablebedrange';
export const TABLE_DEGREE = 'tabledegree';
export const TABLE_NPI = 'tablenpi';
export const OFFICE_TYPE = 'officetype';
