/** @format */
import { useContext, useEffect, useState } from 'react';
import Navbar from '../../Elements/Navbar/Navbar';
import { useLocation } from 'react-router-dom';
import EmailFinder from './Tabs/EmailFinder';
import EmailVerifier from './Tabs/EmailVerifier';
import { AuthContext } from '../../Context/AuthContext';
import useAuth from '../../hooks/useAuth';

const MyTools = () => {
	const { auth } = useAuth();
	const { profile } = useContext(AuthContext);
	const [activeTab, setActiveTab] = useState('emailfinder');
	const query = new URLSearchParams(useLocation().search);
	const active = query.get('active');

	useEffect(() => {
		if (active) {
			setActiveTab(active);
		}
	}, [active]);
	console.log(profile, auth);

	return (
		<div className='relative min-h-screen pb-10'>
			<div className='fixed top-0 z-10 w-full'>
				<Navbar />
			</div>

			<div className='flex justify-center items-center mt-16'>
				<nav
					class='relative z-0 flex border rounded-xl overflow-hidden'
					aria-label='Tabs'
					role='tablist'
				>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
							activeTab == 'emailfinder'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						onClick={() => setActiveTab('emailfinder')}
						id='bar-with-underline-item-1'
						data-hs-tab='#bar-with-underline-1'
						aria-controls='bar-with-underline-1'
						role='tab'
						disabled={
							auth?.role === 'company' && !profile?.is_file_enhancer_user
						}
					>
						Email Finder
					</button>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-60 disabled:pointer-events-none ${
							activeTab == 'emailverifier'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						id='bar-with-underline-item-2'
						onClick={() => setActiveTab('emailverifier')}
						data-hs-tab='#bar-with-underline-2'
						aria-controls='bar-with-underline-2'
						role='tab'
						disabled={auth?.role === 'company' && !profile?.is_internal_user}
					>
						Email Verification
					</button>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-60 disabled:pointer-events-none ${
							activeTab == 'dataupload'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						id='bar-with-underline-item-3'
						data-hs-tab='#bar-with-underline-3'
						aria-controls='bar-with-underline-3'
						role='tab'
						disabled={true}
						onClick={() => setActiveTab('dataupload')}
					>
						Data Upload
					</button>
				</nav>
			</div>

			<div className='mt-6 mx-20 bg-white rounded-lg'>
				{activeTab === 'emailfinder' && <EmailFinder />}
				{activeTab === 'emailverifier' && <EmailVerifier />}
			</div>
		</div>
	);
};

export default MyTools;
