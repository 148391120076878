/** @format */

import React, { useState, useRef, useEffect } from 'react';

function toTitleCase(str) {
	return str.toLowerCase().replace(/\b\w/g, function (char) {
		return char.toUpperCase();
	});
}

function SimpleDropdown({
	options,
	onSelect,
	currentValue,
	defaultLabel,
	disabled,
	title,
	openAbove,
	noIcon,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
	const [search, setSearch] = useState('');

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleSearch = (e) => {
		setIsOpen(true);
		setSearch(e.target.value);
	};

	const handleSelect = (option) => {
		onSelect(option);
		setSearch('');
		setIsOpen(false);
	};

	const handleClickOutside = (e) => {
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className='w-full relative' ref={dropdownRef}>
			<button
				className='w-full py-2 px-2 bg-white flex justify-between items-center border border-gray-300 hover:border-gray-300 rounded-lg text-gray-900 innersearch focus:outline-none outline-none disabled:pointer-events-none disabled:opacity-50'
				onClick={toggleDropdown}
				disabled={disabled}
			>
				<div className='flex gap-1 items-center text-gray-600'>
					<input
						class='ps-1 pe-2 block w-full text-gray-800 font-medium !border-gray-300 rounded-lg text-xs focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
						type='text'
						name='abncs'
						value={search !== '' ? search : currentValue.label}
						placeholder={defaultLabel}
						onChange={(e) => handleSearch(e)}
						autocomplete='off'
					/>
				</div>
				{!noIcon && (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
						className={`flex-shrink-0 w-3 h-3 ${isOpen ? 'rotate-180' : ''}`}
					>
						<path d='m6 9 6 6 6-6' />
					</svg>
				)}
			</button>
			{isOpen && (
				<div
					className={`absolute z-10  rounded-lg bg-white shadow-lg border border-gray-200 px-2 py-1.5 w-full ${
						openAbove ? 'bottom-5' : 'mt-2'
					}`}
				>
					<h3 className='text-gray-600 font-semibold uppercase text-2xs py-1 text-start px-2'>
						{title}
					</h3>

					<div className={`h-40 overflow-y-auto scrollbarY`}>
						{options
							.filter((item) => {
								return item.label.toLowerCase().includes(search.toLowerCase());
							})
							.map((option, index) => (
								<p
									key={index}
									className={`flex justify-between items-center text-start px-2 py-1 hover:bg-gray-100 text-xs font-medium rounded-lg text-slate-800 cursor-pointer`}
									onClick={() => handleSelect(option)}
								>
									{option.label}
									{currentValue.value === option.value && (
										<svg
											class='w-3 h-3 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12'></polyline>
										</svg>
									)}
								</p>
							))}
					</div>
				</div>
			)}
		</div>
	);
}

export default SimpleDropdown;
