/** @format */
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';

const Info = () => {
	const [screen, setScreen] = useState('');

	useEffect(() => {
		const handleResize = () => {
			setScreen(window.innerWidth);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const settings = {
		dots: true,
		arrows: false,
		speed: 500,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: true,
		fade: true,
	};

	const testimonials = [
		{
			quote:
				'This is by far the tool I was looking for to get providers contact info. Makes my job easier now.',
			name: 'Jill',
			title: 'Healthcare Recruiter, USA',
		},
		{
			quote:
				'Finding healthcare decision makers emails has been a tough task. HealthDBi makes it easy.',
			name: 'Saleh',
			title: 'VP Sales - Medical Prodcuts, USA',
		},
		{
			quote:
				'We saw good response rates from the emails and phones that we got from Healthdbi.',
			name: 'Christopher',
			title: 'Research specialist, USA',
		},
		{
			quote:
				'Our team has been happy with the simple platform and easy to use features.',
			name: 'Phil',
			title: 'healthcare Staffing, London, UK',
		},
	];

	return (
		<div
			className={`bg-blue-600 p-4 col-span-1 ${screen < 1050 ? 'hidden' : ''}`}
		>
			<div className='flex flex-col h-full mx-4 my-2'>
				<div className='w-full'>
					<h1 className='text-3xl text-white font-semibold text-left'>
						3.5 Million+ Healthcare Provider Intelligence
					</h1>
					<p className='text-lg font-semibold text-left text-cyan-200'>
						With Direct Email & Phone
					</p>
				</div>
				<div className='my-20'>
					<Slider {...settings}>
						{testimonials.map((testimonial, index) => (
							<div
								key={index}
								className='bg-white p-3 rounded-md border-none flex items-center gap-2'
							>
								<blockquote>
									<div className='shrink-0 flex justify-start gap-x-1 mb-2'>
										{[...Array(5)].map((_, i) => (
											<svg
												key={i}
												className='size-4 text-amber-400'
												width='51'
												height='51'
												viewBox='0 0 51 51'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M27.0352 1.6307L33.9181 16.3633C34.2173 16.6768 34.5166 16.9903 34.8158 16.9903L50.0779 19.1845C50.9757 19.1845 51.275 20.4383 50.6764 21.0652L39.604 32.3498C39.3047 32.6632 39.3047 32.9767 39.3047 33.2901L41.998 49.2766C42.2973 50.217 41.1002 50.8439 40.5017 50.5304L26.4367 43.3208C26.1375 43.3208 25.8382 43.3208 25.539 43.3208L11.7732 50.8439C10.8754 51.1573 9.97763 50.5304 10.2769 49.59L12.9702 33.6036C12.9702 33.2901 12.9702 32.9767 12.671 32.6632L1.29923 21.0652C0.700724 20.4383 0.999979 19.4979 1.89775 19.4979L17.1598 17.3037C17.459 17.3037 17.7583 16.9903 18.0575 16.6768L24.9404 1.6307C25.539 0.69032 26.736 0.69032 27.0352 1.6307Z'
													fill='currentColor'
												/>
											</svg>
										))}
									</div>

									<p className='text-base font-medium text-gray-800 text-left'>
										{testimonial.quote}
									</p>

									<footer className='mt-6 text-left'>
										<p className='font-medium text-gray-800'>
											{testimonial.name}
										</p>
										<p className='text-sm text-gray-600'>{testimonial.title}</p>
									</footer>
								</blockquote>
							</div>
						))}
					</Slider>
				</div>
				<div className='my-5 text-white text-sm text-left'>
					Trusted by more than 100+ Healthcare companies globally.
				</div>
			</div>
		</div>
	);
};

export default Info;
