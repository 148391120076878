/** @format */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const SingleRangeSlider = ({ min, max, onChange, currentValue }) => {
	const [value, setValue] = useState(min);
	const range = useRef(null);

	const handleMouseEnter = () => {
		setShowTooltip(true);
	};

	const handleMouseLeave = () => {
		setShowTooltip(false);
	};

	const handleChange = (event) => {
		const newValue = Number(event.target.value);
		setValue(newValue);
		onChange(newValue);
	};

	useEffect(() => {
		if (currentValue) {
			setValue(currentValue);
		}
	}, []);

	return (
		<div className='flex justify-center items-center relative'>
			<input
				type='range'
				min={min}
				max={max}
				step='100'
				value={value}
				onChange={handleChange}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				className='thumb thumb--left w-full'
				style={{ zIndex: '5' }}
			/>
			<div className='slider w-full'>
				<div className='slider__track' />
				<div ref={range} className='slider__range' />
				<div className='slider__left-value !text-gray-800'>100</div>
				<div className='slider__right-value !text-gray-800'>10000</div>
			</div>
		</div>
	);
};

SingleRangeSlider.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SingleRangeSlider;
