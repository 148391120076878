/** @format */

import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';

export const QueuePopUpModel = ({ queueData, setRefresh, isLoading }) => {
	const [seconds, setSeconds] = useState(30);
	const [isRefresh, setIsRefresh] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setSeconds((prevSeconds) => prevSeconds - 1);
		}, 1000);

		// Reset timer and setRefresh to true after 30 seconds
		if (seconds === 0) {
			clearTimeout(timer);
			setIsRefresh(true);
		}
		if (seconds > 27) {
			setRefresh(false);
		}

		return () => clearTimeout(timer);
	}, [seconds]);

	return (
		<>
			{queueData?.length > 0 ? (
				<div
					className='mx-auto mt-3 overflow-y-auto'
					style={{ height: '85vh' }}
				>
					<div className='flex justify-end items-center'>
						{isLoading ? (
							<div className='flex items-center gap-2 text-blue-600 text-xs font-medium'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='w-3 h-3'
								>
									<path d='M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8' />
									<path d='M3 3v5h5' />
									<path d='M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16' />
									<path d='M16 16h5v5' />
								</svg>
								Refreshing...
							</div>
						) : isRefresh ? (
							<div
								onClick={() => {
									setRefresh(true);
									setSeconds(30);
									setIsRefresh(false);
								}}
								className='flex items-center cursor-pointer gap-2 text-blue-600 text-xs font-medium'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='w-3 h-3'
								>
									<path d='M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8' />
									<path d='M3 3v5h5' />
									<path d='M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16' />
									<path d='M16 16h5v5' />
								</svg>
								Refresh Queue
							</div>
						) : (
							<div className='flex items-center gap-2 text-blue-600 text-xs font-medium'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='w-3 h-3'
								>
									<path d='M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8' />
									<path d='M3 3v5h5' />
									<path d='M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16' />
									<path d='M16 16h5v5' />
								</svg>
								Refresh again in {seconds}s
							</div>
						)}
					</div>
					{queueData.map((queue, i) => (
						<div className='bg-gray-100 rounded-lg mt-3'>
							<div className='flex justify-between items-center gap-4 p-2'>
								<div>
									<h1 className='text-gray-900 text-start font-semibold text-sm'>
										{queue.file_name}
									</h1>
									<p className='text-gray-600 text-start font-medium text-xs'>
										{queue.unique_emails} Records
									</p>
								</div>
								<div>
									<h1 className='text-gray-900 text-end font-semibold text-sm'>
										{(
											((queue?.unique_emails - queue?.verified) * 3) /
											60
										).toFixed(2)}{' '}
										Mins
									</h1>
									<p className='text-gray-600 text-end font-medium text-xs'>
										Estimated Time
									</p>
								</div>
							</div>
							<div className='flex justify-between items-center gap-4 p-2'>
								<div class='relative w-24 h-24'>
									<svg
										class='w-full h-full'
										width='36'
										height='36'
										viewBox='0 0 36 36'
										xmlns='http://www.w3.org/2000/svg'
									>
										<circle
											cx='18'
											cy='18'
											r='16'
											fill='none'
											class='stroke-current text-gray-200'
											stroke-width='2'
										></circle>

										<g
											style={{
												transform: 'rotate(-90deg)',
												transformOrigin: 'center',
											}}
										>
											<circle
												cx='18'
												cy='18'
												r='16'
												fill='none'
												class='stroke-current text-green-600'
												stroke-width='2'
												stroke-dasharray='100'
												stroke-dashoffset={100 - queue.percent}
											></circle>
										</g>
									</svg>

									<div class='absolute absolute-center'>
										<span class='text-center text-xl font-bold text-gray-800'>
											{queue.percent}%
										</span>
									</div>
								</div>
								<div>
									<h1 className='text-gray-900 text-end font-medium text-xs'>
										Verified: <strong>{queue.verified}</strong>
									</h1>
									<h1 className='text-gray-900 text-end font-medium text-xs'>
										Pending:{' '}
										<strong>{queue.unique_emails - queue.verified}</strong>
									</h1>
									<div className='mt-4 flex gap-2 items-center bg-blue-200 text-blue-600 font-semibold rounded-lg py-1 px-2 text-2xs'>
										<CircularProgress size={10} style={{ color: '#2f5eff' }} />
										In Progress
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className='mx-auto px-2 my-10'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						stroke-width='2'
						stroke-linecap='round'
						stroke-linejoin='round'
						className='w-8 h-8 mx-auto text-gray-600'
					>
						<path d='M21 8L18.74 5.74A9.75 9.75 0 0 0 12 3C11 3 10.03 3.16 9.13 3.47' />
						<path d='M8 16H3v5' />
						<path d='M3 12C3 9.51 4 7.26 5.64 5.64' />
						<path d='m3 16 2.26 2.26A9.75 9.75 0 0 0 12 21c2.49 0 4.74-1 6.36-2.64' />
						<path d='M21 12c0 1-.16 1.97-.47 2.87' />
						<path d='M21 3v5h-5' />
						<path d='M22 22 2 2' />
					</svg>
					<h1 className='text-sm font-semibold text-gray-800 mt-3'>
						No Ongoing Downloads
					</h1>
					<p className='text-gray-700 text-xs font-medium px-3 mt-1'>
						Currently, there are no items in the download queue, and no ongoing
						downloads are in process.
					</p>
				</div>
			)}
		</>
	);
};
