/** @format */

import React, { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../Context/AuthContext';
import { CHANGE_PASSWORD } from '../../../Context/Types';
import { NotificationManager } from 'react-notifications';

const ChangePassword = () => {
	const { dispatch } = useContext(AuthContext);

	const [isLoading, setIsLoading] = useState(false);

	const [initialValues, setInitialValues] = useState({
		password: '',
		confirm_password: '',
		old_password: '',
	});

	const handleSubmit = (resetForm) => {
		if (initialValues?.password?.length < 6) {
			NotificationManager.error(
				'Password should be atleast 6 characters long.'
			);
			return;
		}
		if (initialValues?.password !== initialValues.confirm_password) {
			NotificationManager.error(
				'New password & Confirm password not matching.'
			);
			return;
		}

		dispatch({
			type: CHANGE_PASSWORD,
			payload: initialValues,
			resetForm: resetForm,
			setIsLoading: setIsLoading,
		});
	};

	return (
		<>
			<div className='py-6 px-10'>
				<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
					<div>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Current Password:
						</h2>
						<input
							type='text'
							value={initialValues.old_password}
							onChange={(e) =>
								setInitialValues({
									...initialValues,
									old_password: e.target.value,
								})
							}
							placeholder='Enter current password'
							required
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>
					<div>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							New Password:
						</h2>
						<input
							type='text'
							value={initialValues.password}
							onChange={(e) =>
								setInitialValues({
									...initialValues,
									password: e.target.value,
								})
							}
							placeholder='Enter new password'
							required
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>
					<div>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Confirm New Password:
						</h2>
						<input
							type='text'
							value={initialValues.confirm_password}
							onChange={(e) =>
								setInitialValues({
									...initialValues,
									confirm_password: e.target.value,
								})
							}
							placeholder='Enter new password again'
							required
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>
				</div>
				<button
					className='mt-4 rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
					onClick={handleSubmit}
				>
					{isLoading ? (
						<CircularProgress sx={{ color: 'white' }} size={14} />
					) : (
						'Submit'
					)}
				</button>
			</div>
		</>
	);
};

export default ChangePassword;
