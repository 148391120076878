/** @format */

const LogIcons = ({ heading }) => {
	return (
		<>
			{heading === 'Contact Search' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M10.7 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v4.1' />
					<path d='m21 21-1.9-1.9' />
					<circle cx='17' cy='17' r='3' />
				</svg>
			) : heading === 'Login Successfully' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4' />
					<polyline points='10 17 15 12 10 7' />
					<line x1='15' x2='3' y1='12' y2='12' />
				</svg>
			) : heading === 'Credits Transfer' ||
			  heading === 'Credits Request' ||
			  heading === 'Credits Revoked' ||
			  heading === 'Credits Assigned' ||
			  heading.includes('Credits') ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M6 3h12l4 6-10 13L2 9Z' />
					<path d='M11 3 8 9l4 13 4-13-3-6' />
					<path d='M2 9h20' />
				</svg>
			) : heading === 'Subscription' || heading.includes('Subscription') ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8' />
					<path d='M3 3v5h5' />
					<path d='M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16' />
					<path d='M16 16h5v5' />
				</svg>
			) : heading === 'Add Member' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M2 21a8 8 0 0 1 13.292-6' />
					<circle cx='10' cy='8' r='5' />
					<path d='M19 16v6' />
					<path d='M22 19h-6' />
				</svg>
			) : heading === 'Invitation Accepted' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M2 21a8 8 0 0 1 13.292-6' />
					<circle cx='10' cy='8' r='5' />
					<path d='m16 19 2 2 4-4' />
				</svg>
			) : heading === 'Unpaid Invoice' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z' />
					<path d='M12 9v4' />
					<path d='M12 17h.01' />
				</svg>
			) : heading === 'Leads Unlocked' || heading === 'Leads Downloaded' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
					<polyline points='7 10 12 15 17 10' />
					<line x1='12' x2='12' y1='15' y2='3' />
				</svg>
			) : heading === 'Transaction Failed' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<circle cx='12' cy='12' r='10' />
					<path d='m4.9 4.9 14.2 14.2' />
				</svg>
			) : heading === 'Transaction Successful' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<circle cx='12' cy='12' r='10' />
					<path d='m9 12 2 2 4-4' />
				</svg>
			) : heading === 'Account Created' ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<circle cx='12' cy='12' r='10' />
					<circle cx='12' cy='10' r='3' />
					<path d='M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662' />
				</svg>
			) : (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
					class='size-6 text-gray-800'
				>
					<path d='M8 2v4' />
					<path d='M16 2v4' />
					<path d='M21 14V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8' />
					<path d='M3 10h18' />
					<path d='m16 20 2 2 4-4' />
				</svg>
			)}
		</>
	);
};

export default LogIcons;
