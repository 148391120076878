/** @format */
import React, { useContext, useEffect, useState } from 'react';

import { addDays, format } from 'date-fns';
import { AuthContext } from '../../../Context/AuthContext';
import {
	GET_ACTIVITY_LOGS,
	GET_COMPANY_ACTIVITY_LOGS_ADMIN,
} from '../../../Context/Types';

import LogIcons from './LogIcons';
import OverlayModal from '../../../Elements/Misc/Modal';

function convertTo12HourTime(isoString) {
	const date = new Date(isoString);

	// Get hours and minutes
	let hours = date.getHours();
	const minutes = date.getMinutes();

	// Determine AM/PM
	const ampm = hours >= 12 ? 'PM' : 'AM';

	// Convert 24-hour to 12-hour format
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'

	// Format minutes to always be two digits
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	// Return formatted time
	return `${hours}:${formattedMinutes} ${ampm}`;
}

const ActivityLog = (props) => {
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const [date, setDate] = useState('');
	const [row1, setRow1] = useState({ date: '', data: [] });
	const [row2, setRow2] = useState({ date: '', data: [] });
	const [row3, setRow3] = useState({ date: '', data: [] });
	const [selectedSearch, setSelectedSearch] = useState({});

	useEffect(() => {
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_ACTIVITY_LOGS_ADMIN,
				searchParams: {
					id: props.selectedCompanyByAdmin,
					date: date,
				},
				updateState: { setRows },
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_ACTIVITY_LOGS,
				updateState: { setRows },
				setIsLoading: setIsLoading,
				date: date,
			});
		}
	}, [date]);

	useEffect(() => {
		if (rows.length === 0) {
			if (date !== '') {
				const secondDate = addDays(new Date(date), 1);
				const thirdDate = addDays(new Date(date), 2);
				setRow1({ date: format(new Date(date), 'yyyy-MM-dd'), data: [] });
				setRow2({ date: format(secondDate, 'yyyy-MM-dd'), data: [] });
				setRow3({ date: format(thirdDate, 'yyyy-MM-dd'), data: [] });
			} else {
				const secondDate = addDays(new Date(), 1);
				const thirdDate = addDays(new Date(), 2);
				setRow1({ date: format(new Date(), 'yyyy-MM-dd'), data: [] });
				setRow2({ date: format(secondDate, 'yyyy-MM-dd'), data: [] });
				setRow3({ date: format(thirdDate, 'yyyy-MM-dd'), data: [] });
			}
		} else {
			const groupedData = {};

			rows.forEach((item) => {
				const date = new Date(item.createdAt).toISOString().split('T')[0];

				if (!groupedData[date]) {
					groupedData[date] = [];
				}
				groupedData[date].push(item);
			});

			var isContinue = true;
			const dates = Object.keys(groupedData).sort();
			if (date !== '' && dates.length > 0) {
				const firstDate = format(new Date(dates[0]), 'yyyy-MM-dd');
				const selectedDate = format(new Date(date), 'yyyy-MM-dd');
				if (firstDate === selectedDate) {
					isContinue = true;
				} else {
					isContinue = false;
					setRow1({
						date: selectedDate,
						data: [],
					});
					const secondDate = addDays(new Date(date), 1);
					const thirdDate = addDays(new Date(date), 2);
					const secondDateString = format(secondDate, 'yyyy-MM-dd');
					if (dates.includes(secondDateString)) {
						setRow2({
							date: secondDateString,
							data: groupedData[secondDateString],
						});
						setRow3({
							date: format(addDays(secondDate, 1), 'yyyy-MM-dd'),
							data:
								groupedData[format(addDays(secondDate, 1), 'yyyy-MM-dd')] || [],
						});
					} else {
						// If no match for second date, set it with empty data
						setRow2({ date: secondDateString, data: [] });
						const thirdDateString = format(thirdDate, 'yyyy-MM-dd');
						if (dates.includes(thirdDateString)) {
							setRow3({
								date: thirdDateString,
								data: groupedData[thirdDateString],
							});
						} else {
							setRow3({ date: thirdDateString, data: [] });
						}
					}
				}
			}

			if (isContinue) {
				if (dates.length > 0) {
					// Use the first date as row1
					const firstDate = new Date(dates[0]);
					setRow1({
						date: format(firstDate, 'yyyy-MM-dd'),
						data: groupedData[dates[0]],
					});

					// Calculate the next two consecutive dates
					const secondDate = addDays(firstDate, 1);
					const thirdDate = addDays(firstDate, 2);

					if (dates.length > 1) {
						// Check if the second date matches the consecutive secondDate
						const secondDateString = format(secondDate, 'yyyy-MM-dd');
						if (dates.includes(secondDateString)) {
							setRow2({
								date: secondDateString,
								data: groupedData[secondDateString],
							});
							setRow3({
								date: format(addDays(secondDate, 1), 'yyyy-MM-dd'),
								data:
									groupedData[format(addDays(secondDate, 1), 'yyyy-MM-dd')] ||
									[],
							});
						} else {
							// If no match for second date, set it with empty data
							setRow2({ date: secondDateString, data: [] });

							// The third date will be the next valid date or empty if no data
							const thirdDateString = format(thirdDate, 'yyyy-MM-dd');
							if (dates.includes(thirdDateString)) {
								setRow3({
									date: thirdDateString,
									data: groupedData[thirdDateString],
								});
							} else {
								setRow3({ date: thirdDateString, data: [] });
							}
						}
					} else {
						// If only one date, fill the next two days with empty data
						setRow2({ date: format(secondDate, 'yyyy-MM-dd'), data: [] });
						setRow3({ date: format(thirdDate, 'yyyy-MM-dd'), data: [] });
					}
				}
			}
		}
	}, [rows]);

	const [isOpen, setIsOpen] = useState(false);

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const formatType = (value) => {
		return value === '1' ? 'Executive' : 'Physician';
	};

	return (
		<>
			<div className='py-4 px-10'>
				<div className='mb-4 w-60'>
					<input
						type='date'
						value={date === '' ? null : date}
						onChange={(e) => setDate(e.target.value)}
						placeholder='Select Date'
						className='uppercase border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg py-2 px-2 text-xs'
					/>
				</div>
				<div className='grid grid-cols-1 md:grid-cols-3 gap-4 text-left'>
					<div>
						<h1 className='mb-3 text-sm font-medium text-gray-800'>
							{row1?.date &&
								new Date(row1?.date)?.toLocaleDateString('en-US', {
									day: 'numeric',
									month: 'short',
									year: 'numeric',
								})}
						</h1>
						{row1?.data?.length > 0 ? (
							row1.data.map((item, i) => (
								<div key={i} class='group relative flex gap-x-5'>
									<div class='relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-slate-300'>
										<div class='relative z-1 size-5 flex justify-center items-center'>
											<LogIcons heading={item?.heading} />
										</div>
									</div>

									<div class='grow pb-8 group-last:pb-0'>
										<h3 class='mb-1 text-xs text-gray-600'>
											{convertTo12HourTime(item?.createdAt)}
										</h3>

										<p class='font-semibold text-sm text-gray-800'>
											{item?.heading}
										</p>

										<p class='mt-1 text-sm text-gray-600'>{item?.message}</p>
										{item?.query && item?.query !== null && (
											<p
												onClick={() => {
													toggleModal();
													setSelectedSearch(JSON.parse(item?.query));
												}}
												className='mt-1 text-xs text-blue-600 whitespace-nowrap cursor-pointer hover:text-blue-700'
											>
												View Search
											</p>
										)}
									</div>
								</div>
							))
						) : (
							<div class='group relative flex gap-x-5'>
								<div class='relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-slate-300'>
									<div class='relative z-1 size-5 flex justify-center items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='1.5'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='size-6 text-gray-800'
										>
											<path d='m2 2 20 20' />
											<path d='M8.35 2.69A10 10 0 0 1 21.3 15.65' />
											<path d='M19.08 19.08A10 10 0 1 1 4.92 4.92' />
										</svg>
									</div>
								</div>

								<div class='grow pb-8 group-last:pb-0'>
									<p class='font-semibold text-sm text-gray-800'>No Activity</p>
								</div>
							</div>
						)}
					</div>
					<div>
						<h1 className='mb-3 text-sm font-medium text-gray-800'>
							{row2?.date &&
								new Date(row2?.date)?.toLocaleDateString('en-US', {
									day: 'numeric',
									month: 'short',
									year: 'numeric',
								})}
						</h1>
						{row2?.data?.length > 0 ? (
							row2.data.map((item, i) => (
								<div key={i} class='group relative flex gap-x-5'>
									<div class='relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-slate-300'>
										<div class='relative z-1 size-5 flex justify-center items-center'>
											<LogIcons heading={item?.heading} />
										</div>
									</div>

									<div class='grow pb-8 group-last:pb-0'>
										<h3 class='mb-1 text-xs text-gray-600'>
											{convertTo12HourTime(item?.createdAt)}
										</h3>

										<p class='font-semibold text-sm text-gray-800'>
											{item?.heading}
										</p>

										<p class='mt-1 text-sm text-gray-600'>{item?.message}</p>

										{item?.query && item?.query !== null && (
											<p
												onClick={() => {
													toggleModal();
													setSelectedSearch(JSON.parse(item?.query));
												}}
												className='mt-1 text-xs text-blue-600 whitespace-nowrap cursor-pointer hover:text-blue-700'
											>
												View Search
											</p>
										)}
									</div>
								</div>
							))
						) : (
							<div class='group relative flex gap-x-5'>
								<div class='relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-slate-300'>
									<div class='relative z-1 size-5 flex justify-center items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='1.5'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='size-6 text-gray-800'
										>
											<path d='m2 2 20 20' />
											<path d='M8.35 2.69A10 10 0 0 1 21.3 15.65' />
											<path d='M19.08 19.08A10 10 0 1 1 4.92 4.92' />
										</svg>
									</div>
								</div>

								<div class='grow pb-8 group-last:pb-0'>
									<p class='font-semibold text-sm text-gray-800'>No Activity</p>
								</div>
							</div>
						)}
					</div>
					<div>
						<h1 className='mb-3 text-sm font-medium text-gray-800'>
							{row3?.date &&
								new Date(row3?.date)?.toLocaleDateString('en-US', {
									day: 'numeric',
									month: 'short',
									year: 'numeric',
								})}
						</h1>
						{row3?.data?.length > 0 ? (
							row3.data.map((item, i) => (
								<div key={i} class='group relative flex gap-x-5'>
									<div class='relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-slate-300'>
										<div class='relative z-1 size-5 flex justify-center items-center'>
											<LogIcons heading={item?.heading} />
										</div>
									</div>

									<div class='grow pb-8 group-last:pb-0'>
										<h3 class='mb-1 text-xs text-gray-600'>
											{convertTo12HourTime(item?.createdAt)}
										</h3>

										<p class='font-semibold text-sm text-gray-800'>
											{item?.heading}
										</p>

										<p class='mt-1 text-sm text-gray-600'>{item?.message}</p>
										{item?.query && item?.query !== null && (
											<p
												onClick={() => {
													toggleModal();
													setSelectedSearch(JSON.parse(item?.query));
												}}
												className='mt-1 text-xs text-blue-600 whitespace-nowrap cursor-pointer hover:text-blue-700'
											>
												View Search
											</p>
										)}
									</div>
								</div>
							))
						) : (
							<div class='group relative flex gap-x-5'>
								<div class='relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-slate-300'>
									<div class='relative z-1 size-5 flex justify-center items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='1.5'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='size-6 text-gray-800'
										>
											<path d='m2 2 20 20' />
											<path d='M8.35 2.69A10 10 0 0 1 21.3 15.65' />
											<path d='M19.08 19.08A10 10 0 1 1 4.92 4.92' />
										</svg>
									</div>
								</div>

								<div class='grow pb-8 group-last:pb-0'>
									<p class='font-semibold text-sm text-gray-800'>No Activity</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<OverlayModal isOpen={isOpen} onClose={handleClose}>
				<div className='w-96 max-h-96 overflow-auto'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Search Criteria
						</h3>
						<div
							onClick={handleClose}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<div className='mt-4 text-left'>
						<p className='text-sm font-medium'>
							<strong>Page:</strong> {selectedSearch?.page}
						</p>
						<p className='mt-1 text-sm font-medium'>
							<strong>Limit:</strong> {selectedSearch?.limit}
						</p>
						<ul>
							{Object.entries(selectedSearch).map(([key, value]) => {
								if (Array.isArray(value) && value.length > 0) {
									return (
										<li key={key} className='mt-1 text-sm font-medium'>
											<strong>{capitalizeFirstLetter(key)}:</strong>{' '}
											{key === 'type' ? formatType(value[0]) : value.join(', ')}
										</li>
									);
								}
								return null;
							})}
						</ul>
					</div>
				</div>
			</OverlayModal>
		</>
	);
};

export default ActivityLog;
