/** @format */

import React, { useContext, useState } from 'react';
import {
	BarChart,
	ResponsiveContainer,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from 'recharts';

const MainChart = ({ usersTime }) => {
	return (
		<div style={{ width: '100%', height: 300 }}>
			<div className='w-100 text-right'>
				{/* <RangePicker picker="week" className="p-2" /> */}
			</div>

			<ResponsiveContainer>
				{/* <ComposedChart
            width={700}
            height={600}
            data={data}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
             <Line type="monotone" dataKey="paid" stroke="red" /> 
            <Line type="monotone" dataKey="free"  stroke="#8884d8" /> 
            <Bar dataKey="total" barSize={20} fill="#413ea0" >
             <LabelList dataKey="total" position="top" /> 
            </Bar>
          </ComposedChart> */}

				<BarChart
					width={500}
					height={300}
					data={usersTime}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='month_name' />
					<YAxis />
					<Tooltip />
					<Legend />
					<Bar dataKey='FreeUsers' fill='#428ea0' />
					<Bar dataKey='PremiumUsers' fill='#913ea0' />
					<Bar dataKey='TotalUsers' barSize={40} fill='#413ea0' />
				</BarChart>
				{/* <PieChart 
            width={730} 
            height={250}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <Pie data={data} dataKey="total" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" />
            <Pie data={data} dataKey="paid" nameKey="name" cx="50%" cy="50%" innerRadius={85} outerRadius={110} fill="#82ca9d" label />
          </PieChart> */}
			</ResponsiveContainer>
		</div>
	);
};

export default MainChart;
