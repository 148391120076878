/** @format */

export const physicianHeadersDownload = [
	{
		key: 'FullName',
		label: 'FullName',
	},
	{
		key: 'FirstName',
		label: 'FirstName',
	},
	{
		key: 'MiddleName',
		label: 'MiddleName',
	},
	{
		key: 'LastName',
		label: 'LastName',
	},
	{
		key: 'Specialty',
		label: 'Specialty',
	},
	{
		key: 'SpecialtyGroup1',
		label: 'SpecialtyGroup1',
	},
	{
		key: 'SpecialtyGroup2',
		label: 'SpecialtyGroup2',
	},
	{
		key: 'SpecialtyType',
		label: 'SpecialtyType',
	},
	{
		key: 'Hospital_Doctor',
		label: 'Hospital_Doctor',
	},
	{
		key: 'EmailAddress',
		label: 'EmailAddress',
	},
	{
		key: 'PhoneNumber',
		label: 'PhoneNumber',
	},
	{
		key: 'Address1',
		label: 'Address1',
	},
	{
		key: 'Address2',
		label: 'Address2',
	},

	{
		key: 'City',
		label: 'City',
	},
	{
		key: 'State',
		label: 'State',
	},
	{
		key: 'Country',
		label: 'Country',
	},

	{
		key: 'ZIPCode',
		label: 'ZIPCode',
	},
	{
		key: 'WebAddress',
		label: 'WebAddress',
	},

	{
		key: 'FaxNumber',
		label: 'FaxNumber',
	},
	{
		key: 'MasterID',
		label: 'MasterID',
	},
	//  {
	// key: "ISP",
	// label: "ISP",
	//  },

	{
		key: 'NPINumber',
		label: 'NPINumber',
	},
	// {
	//   key: "MasterID2",
	//   label: "MasterID2",
	// },
	{
		key: 'EnumerationDate',
		label: 'EnumerationDate',
	},
	//  {
	// key: "DeactivationReasonCode",
	// label: "DeactivationReasonCode",
	//  },
	{
		key: 'Gender',
		label: 'Gender',
	},
	{
		key: 'degree',
		label: 'degree',
	},
	//  {
	// key: "TaxonomyCode",
	// label: "TaxonomyCode",
	//  },
	{
		key: 'LicenseNumber',
		label: 'LicenseNumber',
	},
	{
		key: 'LicenseIssueState',
		label: 'LicenseIssueState',
	},
	//  {
	// key: "DeactivationDate",
	// label: "DeactivationDate",
	//  },
	//  {
	// key: "AMA_Description",
	// label: "AMA_Description",
	//  },
	//  {
	// key: "NUCCDescription",
	// label: "NUCCDescription",
	//  },
	// {
	//   label: "Action",
	//   label: "Action",
	// },
	{
		key: 'emailstatus',
		label: 'Status',
	},
	// { key: 'emailValid', label: 'emailValid' },
];

export const executiveHeadersDownload = [
	{
		key: 'FullName',
		label: 'FullName',
	},
	{
		key: 'FirstName',
		label: 'FirstName',
	},
	{
		key: 'MiddleName',
		label: 'MiddleName',
	},
	{
		key: 'LastName',
		label: 'LastName',
	},
	{
		key: 'EmailAddress',
		label: 'EmailAddress',
	},
	{
		key: 'PhoneNumber',
		label: 'PhoneNumber',
	},
	{
		key: 'Address1',
		label: 'Address1',
	},
	{
		key: 'Address2',
		label: 'Address2',
	},

	{
		key: 'City',
		label: 'City',
	},
	{
		key: 'State',
		label: 'State',
	},
	{
		key: 'Country',
		label: 'Country',
	},

	{
		key: 'ZIPCode',
		label: 'ZIPCode',
	},
	{
		key: 'WebAddress',
		label: 'WebAddress',
	},

	//  {
	// key: "FaxNumber",
	// label: "FaxNumber",
	//  },
	// {
	//   key: "MasterID",
	//   label: "MasterID",
	// },
	//  {
	// key: "ISP",
	// label: "ISP",
	//  },

	// {
	//   label: "NPINumber",
	//   label: "NPINumber",
	// },
	// {
	//   key: "MasterID2",
	//   label: "MasterID2",
	// },
	//  {
	// key: "EnumerationDate",
	// label: "EnumerationDate",
	//  },
	//  {
	// key: "DeactivationReasonCode",
	// label: "DeactivationReasonCode",
	//  },
	//  {
	// key: "Gender",
	// label: "Gender",
	//  },
	// {
	//   label: "degree",
	//   label: "degree",
	// },
	//  {
	// key: "TaxonomyCode",
	// label: "TaxonomyCode",
	//  },
	//  {
	// key: "LicenseNumber",
	// label: "LicenseNumber",
	//  },
	//  {
	// key: "LicenseIssueState",
	// label: "LicenseIssueState",
	//  },
	//  {
	// key: "DeactivationDate",
	// label: "DeactivationDate",
	//  },
	//  {
	// key: "AMA_Description",
	// label: "AMA_Description",
	//  },
	//  {
	// key: "NUCCDescription",
	// label: "NUCCDescription",
	//  },
	// {
	//   label: "Action",
	//   label: "Action",
	// },
	{
		key: 'Title',
		label: 'Title',
	},
	{
		key: 'LevelID',
		label: 'LevelID',
	},
	{
		key: 'Department',
		label: 'Department',
	},
	{
		key: 'FirmType',
		label: 'FirmType',
	},
	{
		key: 'HospitalType',
		label: 'HospitalType',
	},
	{
		key: 'BedsRange',
		label: 'BedsRange',
	},
	{
		key: 'Ownership',
		label: 'Ownership',
	},
	{
		key: 'Market_Concentration_Index',
		label: 'Market_Concentration_Index',
	},
	{
		key: 'HospitalType',
		label: 'HospitalType',
	},
	{
		key: 'CompanyStatus',
		label: 'CompanyStatus',
	},
	{
		key: 'emailstatus',
		label: 'Status',
	},
	// { key: "emailValid", label: "emailValid" },
];
export const physicianColumns = [
	{
		title: 'FullName',
		dataIndex: 'FullName',
	},
	//   {
	//     title: "FirstName",
	//     dataIndex: "FirstName",
	//   },
	//   {
	//     title: "MiddleName",
	//     dataIndex: "MiddleName",
	//   },
	//   {
	//     title: "LastName",
	//     dataIndex: "LastName",
	//   },
	{
		title: 'Specialty',
		dataIndex: 'Specialty',
	},
	{
		title: 'SpecialtyGroup1',
		dataIndex: 'SpecialtyGroup1',
	},
	//   {
	//     title: "SpecialtyGroup2",
	//     dataIndex: "SpecialtyGroup2",
	//   },
	{
		title: 'SpecialtyType',
		dataIndex: 'SpecialtyType',
	},
	{
		title: 'Hospital_Doctor',
		dataIndex: 'Hospital_Doctor',
	},
	{
		title: 'EmailAddress',
		dataIndex: 'EmailAddress',
	},
	{
		title: 'PhoneNumber',
		dataIndex: 'PhoneNumber',
	},
	//   {
	//     title: "Address1",
	//     dataIndex: "Address1",
	//   },
	//   {
	//     title: "Address2",
	//     dataIndex: "Address2",
	//   },

	{
		title: 'City',
		dataIndex: 'City',
	},
	{
		title: 'State',
		dataIndex: 'State',
	},
	//   {
	//     title: "Country",
	//     dataIndex: "Country",
	//   },

	//   {
	//     title: "ZIPCode",
	//     dataIndex: "ZIPCode",
	//   },
	//   {
	//     title: "WebAddress",
	//     dataIndex: "WebAddress",
	//   },

	//   {
	//     title: "FaxNumber",
	//     dataIndex: "FaxNumber",
	//   },
	//   {
	//     title: "MasterID",
	//     dataIndex: "MasterID",
	//   },
	//  {
	// title: "ISP",
	// dataIndex: "ISP",
	//  },

	{
		title: 'NPINumber',
		dataIndex: 'NPINumber',
	},
	// {
	//   title: "MasterID2",
	//   dataIndex: "MasterID2",
	// },
	//   {
	//     title: "EnumerationDate",
	//     dataIndex: "EnumerationDate",
	//   },
	//  {
	// title: "DeactivationReasonCode",
	// dataIndex: "DeactivationReasonCode",
	//  },
	//   {
	//     title: "Gender",
	//     dataIndex: "Gender",
	//   },
	{
		title: 'degree',
		dataIndex: 'degree',
	},
	//  {
	// title: "TaxonomyCode",
	// dataIndex: "TaxonomyCode",
	//  },
	//   {
	//     title: "LicenseNumber",
	//     dataIndex: "LicenseNumber",
	//   },
	//   {
	//     title: "LicenseIssueState",
	//     dataIndex: "LicenseIssueState",
	//   },
	//  {
	// title: "DeactivationDate",
	// dataIndex: "DeactivationDate",
	//  },
	//  {
	// title: "AMA_Description",
	// dataIndex: "AMA_Description",
	//  },
	//  {
	// title: "NUCCDescription",
	// dataIndex: "NUCCDescription",
	//  },
	// {
	//   dataIndex: "Action",
	//   dataIndex: "Action",
	// },
	//   { title: "Valid", dataIndex: "emailValid" },
];

export const executiveColumns = [
	{
		title: 'FullName',
		dataIndex: 'FullName',
	},
	//   {
	//     title: "FirstName",
	//     dataIndex: "FirstName",
	//   },
	//   {
	//     title: "MiddleName",
	//     dataIndex: "MiddleName",
	//   },
	//   {
	//     title: "LastName",
	//     dataIndex: "LastName",
	//   },
	{
		title: 'EmailAddress',
		dataIndex: 'EmailAddress',
	},
	{
		title: 'PhoneNumber',
		dataIndex: 'PhoneNumber',
	},
	//   {
	//     title: "Address1",
	//     dataIndex: "Address1",
	//   },
	//   {
	//     title: "Address2",
	//     dataIndex: "Address2",
	//   },

	{
		title: 'City',
		dataIndex: 'City',
	},
	{
		title: 'State',
		dataIndex: 'State',
	},
	//   {
	//     title: "Country",
	//     dataIndex: "Country",
	//   },

	{
		title: 'ZIPCode',
		dataIndex: 'ZIPCode',
	},
	{
		title: 'WebAddress',
		dataIndex: 'WebAddress',
	},

	//  {
	// title: "FaxNumber",
	// dataIndex: "FaxNumber",
	//  },
	//   {
	//     title: "MasterID",
	//     dataIndex: "MasterID",
	//   },
	//  {
	// title: "ISP",
	// dataIndex: "ISP",
	//  },

	// {
	//   dataIndex: "NPINumber",
	//   dataIndex: "NPINumber",
	// },
	// {
	//   title: "MasterID2",
	//   dataIndex: "MasterID2",
	// },
	//  {
	// title: "EnumerationDate",
	// dataIndex: "EnumerationDate",
	//  },
	//  {
	// title: "DeactivationReasonCode",
	// dataIndex: "DeactivationReasonCode",
	//  },
	//  {
	// title: "Gender",
	// dataIndex: "Gender",
	//  },
	// {
	//   dataIndex: "degree",
	//   dataIndex: "degree",
	// },
	//  {
	// title: "TaxonomyCode",
	// dataIndex: "TaxonomyCode",
	//  },
	//  {
	// title: "LicenseNumber",
	// dataIndex: "LicenseNumber",
	//  },
	//  {
	// title: "LicenseIssueState",
	// dataIndex: "LicenseIssueState",
	//  },
	//  {
	// title: "DeactivationDate",
	// dataIndex: "DeactivationDate",
	//  },
	//  {
	// title: "AMA_Description",
	// dataIndex: "AMA_Description",
	//  },
	//  {
	// title: "NUCCDescription",
	// dataIndex: "NUCCDescription",
	//  },
	// {
	//   dataIndex: "Action",
	//   dataIndex: "Action",
	// },
	{
		title: 'Title',
		dataIndex: 'Title',
	},
	//   {
	//     title: "LevelID",
	//     dataIndex: "LevelID",
	//   },
	{
		title: 'Department',
		dataIndex: 'Department',
	},
	{
		title: 'FirmType',
		dataIndex: 'FirmType',
	},
	{
		title: 'HospitalType',
		dataIndex: 'HospitalType',
	},
	{
		title: 'BedsRange',
		dataIndex: 'BedsRange',
	},
	{
		title: 'Ownership',
		dataIndex: 'Ownership',
	},
	//   {
	//     title: "Market_Concentration_Index",
	//     dataIndex: "Market_Concentration_Index",
	//   },
	//   {
	//     title: "HospitalType",
	//     dataIndex: "HospitalType",
	//   },
	//   {
	//     title: "CompanyStatus",
	//     dataIndex: "CompanyStatus",
	//   },

	//   { title: "Valid", dataIndex: "emailValid" },
];
