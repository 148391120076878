export const TITLEABB = {
  AM: "Account Manager",
  AP: "Accounts Payable",
  AR: "Accounts Receivable",
  CAO: "Chief Administrative Officer",
  CEO: "Chief Executive Officer",
  CFO: "Chief Financial Officer",
  CIO: "Chief Investment Officer",
  CISO: "Chief Information Security Officer",
  CLO: "Chief Legal Officer",
  CMO: "Chief Marketing Officer",
  COO: "Chief Operating Officer",
  CPA: "Certified Public Accountant",
  CRM: "Customer Relationship Management",
  CSO: "Chief Security Officer",
  HR: "Human Resources",
  PM: "Project Manager",
  QA: "Quality Assurance",
  CTO: "Chief Technology Officer",
  CDO: "Chief Data Officer",
  CAO: "Chief Analytics Officer",
  ChAIR: "Chairperson",
  CHRO: "Chief Human Resources Officer",
  CLO: "Chief Legal Officer",
  CPO: "Chief People Officer",
  CSO: "Chief Security Officer",
  DIR: "Director",
  ED: "Executive Director",
  EVP: "Executive Vice President",
  VP: "Vice President",
  MD: "Managing Director",
  MD: "Managing Partner",
  PRES: "President",
};
// // AR
// CAO
// CEO
// CFO
// CIO
// CISO
// CLO
// CMO
// COO
// CPA
// CRM
// CSO
// HR
// PM
// QA
// CTO
// CDO
// CAO
// Chair
// CHRO
// CLO
// CPO
// CSO
// Dir
// ED
// EVP
// VP
// MD
// MP
// Pres
