/** @format */

import Navbar from '../../Elements/Navbar/Navbar';
import ContactSearchManagement from './ContactSearchManagement';

const ContactSearch = () => {
	return (
		<div className='relative h-screen'>
			<div className='fixed top-0 z-10 w-full'>
				<Navbar />
			</div>
			<ContactSearchManagement />
		</div>
	);
};

export default ContactSearch;
