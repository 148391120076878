/** @format */

import React, { useContext, useEffect, useState, useRef } from 'react';

import { AuthContext } from '../../../Context/AuthContext';
import { CircularProgress } from '@mui/material';
import { downloadCSV } from 'download-csv';

import UploadExclusionLeads from './UploadExclusionLeads';
import TableSkeltonLoader from '../../../Elements/TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../../Elements/Misc/DTable';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import OverlayModal from '../../../Elements/Misc/Modal';
import {
	DELETE_EXCLUSION,
	GET_EXCLUSION_LEADS,
	GET_EXCLUSION_LIST,
} from '../../../Context/Types';

// do not delete it important for future
const headers = [
	// { label: "FirstName", key: "FirstName" },
	// { label: "LastName", key: "LastName" },
	// { label: "Specialty_General", key: "Specialty_General" },
	// { label: "Telephone", key: "Telephone" },
	{ label: 'EmailAddress', key: 'EmailAddress' },
	// { label: "Address1", key: "Address1" },
	// { label: "Clinic", key: "Clinic" },
	// { label: "City", key: "City" },
	// { label: "State", key: "State" },
	// { label: "Country", key: "Country" },
];

// do not delete it important for future
const leadColumns = [
	// {
	//   title: "Name",
	//   dataIndex: "Name",
	// },
	// {
	//   title: "Speciality General",
	//   dataIndex: "Specialty_General",
	// },
	// {
	//   title: "TelePhone",
	//   dataIndex: "Telephone",
	// },
	{
		title: 'Email',
		dataIndex: 'EmailAddress',
	},
	// {
	//   title: "Address",
	//   dataIndex: "Address1",
	// },
];

const CSVs = () => {
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [selectedCSV, setSelectedCSV] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [isModal, setIsModal] = useState(false);
	const instanceRef = useRef();
	const handleDelete = (id) => {
		dispatch({
			type: DELETE_EXCLUSION,
			id: id,
			updateState: setRows,
			setIsLoading: setDeleteLoading,
		});
	};
	useEffect(() => {
		if (selectedCSV) {
			dispatch({
				type: GET_EXCLUSION_LEADS,
				id: selectedCSV,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_EXCLUSION_LIST,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		}
	}, [selectedCSV]);
	const columns = [
		{
			title: 'Uploaded List',
			dataIndex: 'list_name',
		},
		{
			title: 'Date',
			dataIndex: 'createdAt',
		},
		{
			title: 'Actions',
		},
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Uploaded List') {
			return row?.list_name;
		} else if (col.title === 'Date') {
			return row.createdAt;
		} else if (col.title === 'Actions') {
			return (
				<div className='flex items-center !gap-4'>
					<div
						onClick={() => {
							setSelectedCSV(row._id);
						}}
						className='cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z' />
							<path d='M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
							<path d='M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2' />
							<path d='M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2' />
						</svg>
						View
					</div>
					<div
						onClick={() => {
							setSelectedRow(row);
							handleDelete(row._id);
						}}
						className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
					>
						{deleteLoading && row._id === selectedRow._id ? (
							<CircularProgress sx={{ color: 'red' }} size={12} />
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M3 6h18' />
								<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
								<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
								<line x1='10' x2='10' y1='11' y2='17' />
								<line x1='14' x2='14' y1='11' y2='17' />
							</svg>
						)}
						Delete
					</div>
				</div>
			);
		} else if (col.title === 'Email') {
			return row.EmailAddress;
		}
		return row[col.title];
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [uploadLoading, setUploadLoading] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
		}, 200);
	};

	return (
		<div>
			{selectedCSV ? (
				<div className='flex justify-between items-center px-4 pt-3'>
					<div
						className='cursor-pointer text-sm text-gray-800 font-medium flex items-center gap-2 hover:text-blue-600'
						onClick={() => setSelectedCSV(null)}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4'
						>
							<path d='M6 8L2 12L6 16' />
							<path d='M2 12H22' />
						</svg>
						Back
					</div>
				</div>
			) : (
				<div className='flex justify-end items-center gap-2 px-4 pt-3'>
					<button
						className='focus:outline-none border-none bg-green-600 hover:bg-green-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
						onClick={() => {
							downloadCSV([], headers, 'ExclusionSample');
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
							<polyline points='7 10 12 15 17 10' />
							<line x1='12' x2='12' y1='15' y2='3' />
						</svg>
						Download Sample
					</button>

					<button
						className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
						onClick={openModal}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
							<polyline points='17 8 12 3 7 8' />
							<line x1='12' x2='12' y1='3' y2='15' />
						</svg>
						Upload EmailAddress
					</button>
				</div>
			)}
			{selectedCSV ? (
				isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={8} />
					</div>
				) : (
					<DynamicTable
						columns={leadColumns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)
			) : isLoading ? (
				<div
					className='relative'
					style={{ overflow: 'hidden', marginTop: '1rem' }}
				>
					<TableSkeltonLoader countRow={8} />
				</div>
			) : (
				<DynamicTable
					columns={columns}
					data={paginatedRows}
					renderCell={renderCell}
				/>
			)}
			{!isLoading && (
				<PaginationRow
					totalResults={rows?.length}
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
			<OverlayModal isOpen={modalOpen} onClose={closeModal}>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>Upload CSV</h3>
						<div
							onClick={closeModal}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					<UploadExclusionLeads setRows={setRows} close={closeModal} />
				</div>
			</OverlayModal>
			{/* <PopUpModel
				open={isModal}
				title={'Upload Leads'}
				close={() => {
					setIsModal(false);
				}}
			>
				<UploadExclusionLeads
					close={() => {
						setIsModal(false);
					}}
					setRows={setRows}
				/>
			</PopUpModel> */}
		</div>
	);
};

export default CSVs;
