/** @format */

import React, { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { REQUEST_CREDITS } from '../../../Context/Types';
import { NotificationManager } from 'react-notifications';
import { AuthContext } from '../../../Context/AuthContext';
import OverlayModal from '../../../Elements/Misc/Modal';

const RequestCredits = (props) => {
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [credits, setCredits] = useState(0);

	const handleSubmit = (resetForm) => {
		if (Number(credits) && Number(credits) > 0) {
			dispatch({
				type: REQUEST_CREDITS,
				payload: credits,
				resetForm: resetForm,
				setIsLoading: setIsLoading,
				cb: props.getRequestLogs,
			});
		} else {
			NotificationManager.error('Please enter the valid credits.');
			return;
		}
	};

	return (
		<OverlayModal isOpen={props.open} onClose={props.close}>
			<div className='w-96'>
				<div className='flex justify-between items-center gap-4'>
					<h3 className='text-sm font-semibold text-gray-800'>Send Request</h3>
					<div
						onClick={props.close}
						className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</div>
				</div>
				<div className='mt-4'>
					<div>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Credits:
						</h2>
						<input
							type='text'
							value={credits}
							onChange={(e) => setCredits(e.target.value)}
							placeholder='Enter credits to assign'
							required
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>
				</div>

				<button
					className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
					onClick={handleSubmit}
					disabled={!credits || credits <= 0}
				>
					{isLoading ? (
						<CircularProgress sx={{ color: 'white' }} size={14} />
					) : (
						'Submit'
					)}
				</button>
			</div>
		</OverlayModal>
	);
};

export default RequestCredits;
