import { useEffect, useState } from "react";

const useArea = () => {
  const [area, setArea] = useState("");

  async function getApi(bdcApi) {
    var bdcApi = "https://api.bigdatacloud.net/data/reverse-geocode-client";
    const res = await fetch(bdcApi);
    const data = await res.json();
    setArea(data);
  }

  // async function getLocation() {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       bdcApi =
  //         bdcApi +
  //         "?latitude=" +
  //         position.coords.latitude +
  //         "&longitude=" +
  //         position.coords.longitude +
  //         "&localityLanguage=en";
  //       getApi(bdcApi);
  //     },
  //     (err) => {
  //       getApi(bdcApi);
  //     },
  //     {
  //       enableHighAccuracy: true,
  //       timeout: 5000,
  //       maximumAge: 0,
  //     }
  //   );
  // }

  useEffect(() => {
    getApi();
  }, []);

  return { area };
};

export default useArea;
