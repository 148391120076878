/** @format */

import React, { useContext, useEffect, useState, useRef } from 'react';
import { CircularProgress } from '@mui/material';

import TableSkeltonLoader from '../../../Elements/TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../../Elements/Misc/DTable';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import OverlayModal from '../../../Elements/Misc/Modal';
import { AuthContext } from '../../../Context/AuthContext';
import {
	CREATE_LIST,
	DELETE_LIST,
	DOWNLOAD_LEADS,
	EDIT_LIST_NAME,
	GET_ALL_LIST,
	GET_ALL_LIST_CONTACTS,
	UNLOCK_MY_LIST_FIELD,
} from '../../../Context/Types';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../../Context/filterCompo/filterTypes';
import down from '../../../assests/image/down.svg';

export const MyListTable = () => {
	const { dispatch } = useContext(AuthContext);
	const [createListModal, setCreateListModal] = useState(false);
	const [editListModal, setEditListModal] = useState(false);
	const [state, setstate] = useState(true);
	const [listData, setListData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoading2, setIsLoading2] = useState(false);
	const [isEditLoading, setIsEditLoading] = useState(false);
	const [listName, setListName] = useState('');
	const [download, setDownload] = useState();
	const [editedList, setEditedList] = useState({});
	const [folderName, setFolderName] = useState('');
	const [dloader, setDLoader] = useState(false);
	const [downloadingName, setDownloadName] = useState('');
	const [isbtnLoading, setIsBtnLoading] = useState({
		create: false,
		add: false,
	});
	const [isDelete, setIsDelete] = useState(false);
	const [rows, setRows] = useState({
		leads: [],
		unlockIndex: [],
	});
	const [unlockedLeads, setUnlockedLeads] = useState({
		data: [],
		unlockedLeads: [],
		isUnlocked: false,
	});

	const [typesOfFilter, setTypesOfFilter] = useState(PHYSICIAN_FILTER);
	const instanceRef = useRef();
	const [page, setPage] = useState(1);
	const [selectedRow, setSelectedRow] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [downloading, setDownloading] = useState(false);
	const [downloadId, setDownloadId] = useState('');
	// console.log("listData", unlockedLeads);

	const UnlockFilled = (_id, clickedOn) => {
		dispatch({
			type: UNLOCK_MY_LIST_FIELD,
			payload: _id,
			clickedOn: clickedOn,
			setLock: setUnlockedLeads,
		});
	};

	const leadsColumns = [
		{
			title: 'Name',
			dataIndex: 'Name',
		},
		{
			title: 'Speciality General',
			dataIndex: 'Specialty_General',
		},

		{
			title: 'Address',
			dataIndex: 'Address1',
		},
		{
			title: <img src={down} alt='' />,
			render: (_, record) =>
				downloading && record?._id === downloadId ? (
					<CircularProgress size={15} style={{ color: '#1891eb' }} />
				) : (
					<img
						src={down}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setDownloadId(record._id);
							dispatch({
								type: DOWNLOAD_LEADS,
								payload: {
									leads_ids: [record._id],
									verifyAll: false,
									downloadName: `My List - ${new Date().toISOString()}`,
									isVerify: true,
								},
								close: () => {},
								setIsLoading: setDownloading,
								setDownload,
							});
						}}
					/>
				),
		},
	];

	const physicianColumns = [
		{
			title: 'FullName',
			dataIndex: 'FullName',
		},
		//   {
		//     title: "FirstName",
		//     dataIndex: "FirstName",
		//   },
		//   {
		//     title: "MiddleName",
		//     dataIndex: "MiddleName",
		//   },
		//   {
		//     title: "LastName",
		//     dataIndex: "LastName",
		//   },
		{
			title: 'Specialty',
			dataIndex: 'Specialty',
		},
		{
			title: 'SpecialtyGroup1',
			dataIndex: 'SpecialtyGroup1',
		},
		//   {
		//     title: "SpecialtyGroup2",
		//     dataIndex: "SpecialtyGroup2",
		//   },
		{
			title: 'SpecialtyType',
			dataIndex: 'SpecialtyType',
		},
		{
			title: 'Hospital_Doctor',
			dataIndex: 'Hospital_Doctor',
		},
		// {
		// 	title: 'PhoneNumber',
		// 	dataIndex: 'PhoneNumber',
		// 	render: (_, record, index) => (
		// 		<p>
		// 			{(unlockedLeads?.data.filter((d) => d._id === record._id).length ===
		// 				0 ||
		// 				unlockedLeads?.data.filter((d) => d._id === record._id)[0]) &&
		// 			unlockedLeads.isUnlocked ? (
		// 				unlockedLeads?.unlockedLeads.includes(record._id) ? (
		// 					record.PhoneNumber
		// 				) : record.isUnlocked === true ? (
		// 					record.PhoneNumber
		// 				) : (
		// 					<p
		// 						style={{
		// 							color: '#1891eb',
		// 							textDecoration: 'none',
		// 							cursor: 'pointer',
		// 						}}
		// 						onClick={() => {
		// 							UnlockFilled(record._id, 'phone');
		// 						}}
		// 					>
		// 						Verify Phone
		// 					</p>
		// 				)
		// 			) : record.isUnlocked === true ? (
		// 				record.PhoneNumber
		// 			) : (
		// 				<p
		// 					style={{
		// 						color: '#1891eb',
		// 						textDecoration: 'none',
		// 						cursor: 'pointer',
		// 					}}
		// 					onClick={() => {
		// 						UnlockFilled(record._id, 'phone');
		// 					}}
		// 				>
		// 					Verify Phone
		// 				</p>
		// 			)}
		// 		</p>
		// 	),
		// },
		// {
		// 	title: 'Email',
		// 	dataIndex: 'EmailAddress',
		// 	render: (_, record) => (
		// 		<p>
		// 			{(unlockedLeads?.data.filter((d) => d._id === record._id).length ===
		// 				0 ||
		// 				unlockedLeads?.data.filter((d) => d._id === record._id)[0]) &&
		// 			unlockedLeads.isUnlocked ? (
		// 				unlockedLeads?.unlockedLeads.includes(record._id) ? (
		// 					record.EmailAddress
		// 				) : !record.debounceCode ||
		// 				  record?.debounceCode === '1' ||
		// 				  record?.debounceCode === '2' ? (
		// 					record.EmailAddress
		// 				) : (
		// 					<p
		// 						style={{
		// 							color: '#1891eb',
		// 							textDecoration: 'none',
		// 							cursor: 'pointer',
		// 						}}
		// 						// onClick={() => {
		// 						//   UnlockFilled(record._id, "email");
		// 						// }}
		// 					>
		// 						Invalid Email
		// 					</p>
		// 				)
		// 			) : record.isUnlocked === true ? (
		// 				record.EmailAddress
		// 			) : (
		// 				<p
		// 					style={{
		// 						color: '#1891eb',
		// 						textDecoration: 'none',
		// 						cursor: 'pointer',
		// 					}}
		// 					onClick={() => {
		// 						UnlockFilled(record._id, 'email');
		// 					}}
		// 				>
		// 					Verify Email
		// 				</p>
		// 			)}
		// 		</p>
		// 	),
		// },
		//   {
		//     title: "Address1",
		//     dataIndex: "Address1",
		//   },
		//   {
		//     title: "Address2",
		//     dataIndex: "Address2",
		//   },

		{
			title: 'City',
			dataIndex: 'City',
		},
		{
			title: 'State',
			dataIndex: 'State',
		},
		//   {
		//     title: "Country",
		//     dataIndex: "Country",
		//   },

		//   {
		//     title: "ZIPCode",
		//     dataIndex: "ZIPCode",
		//   },
		//   {
		//     title: "WebAddress",
		//     dataIndex: "WebAddress",
		//   },

		//   {
		//     title: "FaxNumber",
		//     dataIndex: "FaxNumber",
		//   },
		//   {
		//     title: "MasterID",
		//     dataIndex: "MasterID",
		//   },
		//  {
		// title: "ISP",
		// dataIndex: "ISP",
		//  },

		{
			title: 'NPINumber',
			dataIndex: 'NPINumber',
		},
		// {
		//   title: "MasterID2",
		//   dataIndex: "MasterID2",
		// },
		//   {
		//     title: "EnumerationDate",
		//     dataIndex: "EnumerationDate",
		//   },
		//  {
		// title: "DeactivationReasonCode",
		// dataIndex: "DeactivationReasonCode",
		//  },
		//   {
		//     title: "Gender",
		//     dataIndex: "Gender",
		//   },
		{
			title: 'degree',
			dataIndex: 'degree',
		},
		//  {
		// title: "TaxonomyCode",
		// dataIndex: "TaxonomyCode",
		//  },
		//   {
		//     title: "LicenseNumber",
		//     dataIndex: "LicenseNumber",
		//   },
		//   {
		//     title: "LicenseIssueState",
		//     dataIndex: "LicenseIssueState",
		//   },
		//  {
		// title: "DeactivationDate",
		// dataIndex: "DeactivationDate",
		//  },
		//  {
		// title: "AMA_Description",
		// dataIndex: "AMA_Description",
		//  },
		//  {
		// title: "NUCCDescription",
		// dataIndex: "NUCCDescription",
		//  },
		// {
		//   dataIndex: "Action",
		//   dataIndex: "Action",
		// },
		//   { title: "Valid", dataIndex: "emailValid" },
		// {
		// 	title: <img src={down} alt='' />,
		// 	render: (_, record) =>
		// 		downloading && record?._id === downloadId ? (
		// 			<CircularProgress size={15} style={{ color: '#1891eb' }} />
		// 		) : (
		// 			<img
		// 				src={down}
		// 				style={{ cursor: 'pointer' }}
		// 				onClick={() => {
		// 					setDownloadId(record._id);
		// 					dispatch({
		// 						type: DOWNLOAD_LEADS,
		// 						payload: {
		// 							leads_ids: [record._id],
		// 							verifyAll: false,
		// 							downloadName: `My List - ${new Date().toISOString()}`,
		// 							isVerify: true,
		// 						},
		// 						close: () => {},
		// 						setIsLoading: setDownloading,
		// 						setDownload,
		// 					});
		// 				}}
		// 			/>
		// 		),
		// },
	];

	const executiveColumns = [
		{
			title: 'FullName',
			dataIndex: 'FullName',
		},
		//   {
		//     title: "FirstName",
		//     dataIndex: "FirstName",
		//   },
		//   {
		//     title: "MiddleName",
		//     dataIndex: "MiddleName",
		//   },
		//   {
		//     title: "LastName",
		//     dataIndex: "LastName",
		//   },
		// {
		// 	title: 'PhoneNumber',
		// 	dataIndex: 'PhoneNumber',
		// 	render: (_, record) => (
		// 		<p>
		// 			{(unlockedLeads?.data.filter((d) => d._id === record._id).length ===
		// 				0 ||
		// 				unlockedLeads?.data.filter((d) => d._id === record._id)[0]) &&
		// 			unlockedLeads.isUnlocked ? (
		// 				unlockedLeads?.unlockedLeads.includes(record._id) ? (
		// 					record.PhoneNumber
		// 				) : record.isUnlocked === true ? (
		// 					record.PhoneNumber
		// 				) : (
		// 					<p
		// 						style={{
		// 							color: '#1891eb',
		// 							textDecoration: 'underline',
		// 							cursor: 'pointer',
		// 						}}
		// 						onClick={() => {
		// 							UnlockFilled(record._id, 'phone');
		// 						}}
		// 					>
		// 						Verify Phone
		// 					</p>
		// 				)
		// 			) : record.isUnlocked === true ? (
		// 				record.PhoneNumber
		// 			) : (
		// 				<p
		// 					style={{
		// 						color: '#1891eb',
		// 						textDecoration: 'underline',
		// 						cursor: 'pointer',
		// 					}}
		// 					onClick={() => {
		// 						UnlockFilled(record._id, 'phone');
		// 					}}
		// 				>
		// 					Verify Phone
		// 				</p>
		// 			)}
		// 		</p>
		// 	),
		// },
		// {
		// 	title: 'Email',
		// 	dataIndex: 'EmailAddress',
		// 	render: (_, record) => (
		// 		<p>
		// 			{(unlockedLeads?.data.filter((d) => d._id === record._id).length ===
		// 				0 ||
		// 				unlockedLeads?.data.filter((d) => d._id === record._id)[0]) &&
		// 			unlockedLeads.isUnlocked ? (
		// 				unlockedLeads?.unlockedLeads.includes(record._id) ? (
		// 					record.EmailAddress
		// 				) : !record?.debounceCode ||
		// 				  record?.debounceCode === '1' ||
		// 				  record?.debounceCode === '2' ? (
		// 					record.EmailAddress
		// 				) : (
		// 					<p
		// 						style={{
		// 							color: '#1891eb',
		// 							textDecoration: 'none',
		// 							cursor: 'pointer',
		// 						}}
		// 						// onClick={() => {
		// 						//   UnlockFilled(record._id, "email");
		// 						// }}
		// 					>
		// 						Verify Email
		// 					</p>
		// 				)
		// 			) : record.isUnlocked === true ? (
		// 				record.EmailAddress
		// 			) : (
		// 				<p
		// 					style={{
		// 						color: '#1891eb',
		// 						textDecoration: 'none',
		// 						cursor: 'pointer',
		// 					}}
		// 					onClick={() => {
		// 						UnlockFilled(record._id, 'email');
		// 					}}
		// 				>
		// 					Verify Email
		// 				</p>
		// 			)}
		// 		</p>
		// 	),
		// },
		//   {
		//     title: "Address1",
		//     dataIndex: "Address1",
		//   },
		//   {
		//     title: "Address2",
		//     dataIndex: "Address2",
		//   },

		{
			title: 'City',
			dataIndex: 'City',
		},
		{
			title: 'State',
			dataIndex: 'State',
		},
		//   {
		//     title: "Country",
		//     dataIndex: "Country",
		//   },

		{
			title: 'ZIPCode',
			dataIndex: 'ZIPCode',
		},
		{
			title: 'WebAddress',
			dataIndex: 'WebAddress',
		},

		//  {
		// title: "FaxNumber",
		// dataIndex: "FaxNumber",
		//  },
		//   {
		//     title: "MasterID",
		//     dataIndex: "MasterID",
		//   },
		//  {
		// title: "ISP",
		// dataIndex: "ISP",
		//  },

		// {
		//   dataIndex: "NPINumber",
		//   dataIndex: "NPINumber",
		// },
		// {
		//   title: "MasterID2",
		//   dataIndex: "MasterID2",
		// },
		//  {
		// title: "EnumerationDate",
		// dataIndex: "EnumerationDate",
		//  },
		//  {
		// title: "DeactivationReasonCode",
		// dataIndex: "DeactivationReasonCode",
		//  },
		//  {
		// title: "Gender",
		// dataIndex: "Gender",
		//  },
		// {
		//   dataIndex: "degree",
		//   dataIndex: "degree",
		// },
		//  {
		// title: "TaxonomyCode",
		// dataIndex: "TaxonomyCode",
		//  },
		//  {
		// title: "LicenseNumber",
		// dataIndex: "LicenseNumber",
		//  },
		//  {
		// title: "LicenseIssueState",
		// dataIndex: "LicenseIssueState",
		//  },
		//  {
		// title: "DeactivationDate",
		// dataIndex: "DeactivationDate",
		//  },
		//  {
		// title: "AMA_Description",
		// dataIndex: "AMA_Description",
		//  },
		//  {
		// title: "NUCCDescription",
		// dataIndex: "NUCCDescription",
		//  },
		// {
		//   dataIndex: "Action",
		//   dataIndex: "Action",
		// },
		{
			title: 'Title',
			dataIndex: 'Title',
		},
		//   {
		//     title: "LevelID",
		//     dataIndex: "LevelID",
		//   },
		{
			title: 'Department',
			dataIndex: 'Department',
		},
		{
			title: 'FirmType',
			dataIndex: 'FirmType',
		},
		{
			title: 'HospitalType',
			dataIndex: 'HospitalType',
		},
		{
			title: 'BedsRange',
			dataIndex: 'BedsRange',
		},
		{
			title: 'Ownership',
			dataIndex: 'Ownership',
		},
		//   {
		//     title: "Market_Concentration_Index",
		//     dataIndex: "Market_Concentration_Index",
		//   },
		//   {
		//     title: "HospitalType",
		//     dataIndex: "HospitalType",
		//   },
		//   {
		//     title: "CompanyStatus",
		//     dataIndex: "CompanyStatus",
		//   },

		//   { title: "Valid", dataIndex: "emailValid" },
		// {
		// 	title: 'Action',
		// 	render: (_, record) => (
		// 		<img
		// 			src={down}
		// 			style={{ cursor: 'pointer' }}
		// 			onClick={() => {
		// 				dispatch({
		// 					type: DOWNLOAD_LEADS,
		// 					payload: {
		// 						leads_ids: [record._id],
		// 						verifyAll: false,
		// 						downloadName: `My List - ${new Date().toISOString()}`,
		// 						isVerify: true,
		// 					},
		// 					close: () => {},
		// 					setIsLoading: () => {},
		// 					setDownload,
		// 				});
		// 			}}
		// 		/>
		// 	),
		// },
	];

	const listColumns = [
		{
			title: 'List Name',
		},
		{
			title: 'Date',
		},
		{
			title: 'Total Contacts',
		},
		{
			title: 'Actions',
			key: 'Action',
		},
	];

	const changetable = (folder_id, name) => {
		setFolderName(name);
		setstate(false);
		dispatch({
			type: GET_ALL_LIST_CONTACTS,
			updateState: setRows,
			folder_id: folder_id,
			setIsLoading: setIsLoading,
		});
	};

	const [downloadsLeads, setDownloadsLeads] = useState([]);

	useEffect(() => {
		let leadsIds = [];
		rows?.leads?.forEach((item) => {
			leadsIds.push(String(item?._id));
		});
		setDownloadsLeads(leadsIds);
	}, [rows]);

	const getList = (dataType) => {
		dispatch({
			type: GET_ALL_LIST,
			updateState: setListData,
			setIsLoading: setIsLoading,
			dataType: dataType,
		});
	};

	useEffect(() => {
		if (!typesOfFilter) return;
		let dataType;
		if (typesOfFilter === PHYSICIAN_FILTER) {
			dataType = 0;
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			dataType = 1;
		}
		getList(dataType);
	}, [typesOfFilter]);

	const handleCreateList = () => {
		if (listName) {
			dispatch({
				type: CREATE_LIST,
				payload: {
					folder_name: listName,
					dataType: typesOfFilter === PHYSICIAN_FILTER ? '0' : '1',
				},
				setIsLoading: setIsBtnLoading,
				cb: getList.bind(this, typesOfFilter === PHYSICIAN_FILTER ? '0' : '1'),
			});
		}
	};

	const handleDeleteList = (id) => {
		dispatch({
			type: DELETE_LIST,
			payload: id,
			setIsLoading: setIsDelete,
			cb: getList.bind(this, typesOfFilter === PHYSICIAN_FILTER ? '0' : '1'),
		});
	};

	const handleEditList = () => {
		dispatch({
			type: EDIT_LIST_NAME,
			payload: editedList,
			setIsLoading: setIsEditLoading,
			cb: getList.bind(this, typesOfFilter === PHYSICIAN_FILTER ? '0' : '1'),
		});
	};

	const handleDownloadsData = () => {
		dispatch({
			type: DOWNLOAD_LEADS,
			payload: {
				leads_ids: downloadsLeads,
				verifyAll: false,
				downloadName: `My List - ${new Date().toISOString()}`,
				isVerify: true,
			},
			setIsLoading: setIsLoading2,
			setDownload,
		});
	};

	let newrows = [...listData];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	let newlist = [...rows.leads];
	const paginatedLists = newlist.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'List Name') {
			return row?.folder_name;
		} else if (col.title === 'Total Contacts') {
			return row?.leads?.length;
		} else if (col.title === 'Date') {
			return new Date(row.createdAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				// hour: '2-digit',
				// minute: '2-digit',
			});
		} else if (col.title === 'PhoneNumber') {
			<p>
				{(unlockedLeads?.data.filter((d) => d._id === row._id).length === 0 ||
					unlockedLeads?.data.filter((d) => d._id === row._id)[0]) &&
				unlockedLeads.isUnlocked ? (
					unlockedLeads?.unlockedLeads.includes(row._id) && row.PhoneNumber
				) : row.isUnlocked === true ? (
					row.PhoneNumber
				) : (
					<p
						className='cursor-pointer text-nowrap text-blue-600'
						onClick={() => {
							UnlockFilled(row._id, 'phone');
						}}
					>
						Verify Phone
					</p>
				)}
			</p>;
		} else if (col.title === 'Email') {
			return (
				<p>
					{(unlockedLeads?.data.filter((d) => d._id === row._id).length === 0 ||
						unlockedLeads?.data.filter((d) => d._id === row._id)[0]) &&
					unlockedLeads.isUnlocked ? (
						unlockedLeads?.unlockedLeads.includes(row._id) ? (
							row.EmailAddress
						) : !row.debounceCode ||
						  row?.debounceCode === '1' ||
						  row?.debounceCode === '2' ? (
							row.EmailAddress
						) : (
							<p>Invalid Email</p>
						)
					) : row.isUnlocked === true ? (
						row.EmailAddress
					) : (
						<p
							className='cursor-pointer text-nowrap text-blue-600'
							onClick={() => {
								UnlockFilled(row._id, 'email');
							}}
						>
							Verify Email
						</p>
					)}
				</p>
			);
		} else if (col.title === 'Actions') {
			return (
				<div className='flex items-center !gap-4'>
					<div
						onClick={() => {
							changetable(row._id, row.folder_name);
							// setSelectedName(row?.download_name);
						}}
						className='cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z' />
							<path d='M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
							<path d='M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2' />
							<path d='M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2' />
						</svg>
						View
					</div>
					<div
						onClick={() => openModal(row)}
						className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
							<path d='m15 5 4 4' />
						</svg>
						Rename
					</div>
					<div
						onClick={() => {
							setDownloadName(row?._id);
							const leadsId = row?.leads;
							// leadsId.shift();
							dispatch({
								type: DOWNLOAD_LEADS,
								payload: {
									leads_ids: leadsId || [],
									verifyAll: false,
									downloadName: `My List - ${new Date().toISOString()}`,
									isVerify: true,
								},
								setIsLoading: setDLoader,
								setDownload,
							});
						}}
						className='cursor-pointer flex items-center gap-1 text-blue-600 hover:underline'
					>
						{dloader && downloadingName === row?._id ? (
							<CircularProgress sx={{ color: 'blue' }} size={12} />
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
								<polyline points='7 10 12 15 17 10' />
								<line x1='12' x2='12' y1='15' y2='3' />
							</svg>
						)}
						Download
					</div>
					<div
						onClick={() => {
							setSelectedRow(row);
							handleDeleteList(row._id);
						}}
						className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
					>
						{isDelete && row._id === selectedRow._id ? (
							<CircularProgress sx={{ color: 'red' }} size={12} />
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M3 6h18' />
								<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
								<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
								<line x1='10' x2='10' y1='11' y2='17' />
								<line x1='14' x2='14' y1='11' y2='17' />
							</svg>
						)}
						Delete
					</div>
				</div>
			);
		}
		return row[col.title];
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [renameLoading, setRenameLoading] = useState(false);
	const [newName, setnewName] = useState('');

	const openModal = (row) => {
		setModalOpen(true);
		setSelectedRow(row);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
			setSelectedRow(null);
		}, 200);
	};

	useEffect(() => {
		setnewName(selectedRow?.folder_name);
	}, [selectedRow]);

	const handleRenameList = async () => {
		setRenameLoading(true);
		dispatch({
			type: EDIT_LIST_NAME,
			payload: {
				folder_id: selectedRow?._id,
				folder_name: newName,
			},
			setIsLoading: setRenameLoading,
			cb: getList.bind(this, typesOfFilter === PHYSICIAN_FILTER ? '0' : '1'),
		});
		setRenameLoading(false);
		closeModal();
	};

	return (
		<div>
			{state ? (
				<>
					<div className='flex items-center gap-2 mx-4 pt-3'>
						<p className='text-xs font-semibold text-gray-800'>Choose Type:</p>
						<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
							<div
								className={`rounded-full ${
									typesOfFilter === PHYSICIAN_FILTER
										? 'border-check-active'
										: 'border-check-light'
								}`}
							>
								<h3
									onClick={() => setTypesOfFilter(PHYSICIAN_FILTER)}
									className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
								>
									<input
										name={'companyradionull'}
										type='radio'
										checked={typesOfFilter === PHYSICIAN_FILTER}
										defaultChecked
										class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
									/>
									Physicians
								</h3>
							</div>
							<div
								className={`rounded-full ${
									typesOfFilter === EXECUTIVE_FILTER
										? 'border-check-active'
										: 'border-check-light'
								}`}
							>
								<h3
									onClick={() => setTypesOfFilter(EXECUTIVE_FILTER)}
									className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
								>
									<input
										name={'companyradio1'}
										type='radio'
										checked={typesOfFilter === EXECUTIVE_FILTER}
										class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 '
									/>
									Executives
								</h3>
							</div>
						</div>
					</div>
					{isLoading ? (
						<div
							className='relative'
							style={{ overflow: 'hidden', marginTop: '1rem' }}
						>
							<TableSkeltonLoader countRow={8} />
						</div>
					) : (
						<DynamicTable
							columns={listColumns}
							data={paginatedRows}
							renderCell={renderCell}
						/>
					)}
				</>
			) : (
				<div>
					<div className='flex justify-between items-center px-4 pt-3'>
						<div
							className='cursor-pointer text-sm text-gray-800 font-medium flex items-center gap-2 hover:text-blue-600'
							onClick={() => setstate(true)}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4'
							>
								<path d='M6 8L2 12L6 16' />
								<path d='M2 12H22' />
							</svg>
							Back
						</div>
						<div>
							<button
								className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
								onClick={handleDownloadsData}
							>
								{isLoading2 ? (
									<CircularProgress size={12} style={{ color: 'white' }} />
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-4 h-4'
									>
										<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
										<polyline points='7 10 12 15 17 10' />
										<line x1='12' x2='12' y1='15' y2='3' />
									</svg>
								)}
								Download
							</button>
						</div>
					</div>
					{isLoading ? (
						<div
							className='relative'
							style={{ overflow: 'hidden', height: '50vh', marginTop: '1rem' }}
						>
							<TableSkeltonLoader countRow={8} />
						</div>
					) : (
						<DynamicTable
							columns={
								typesOfFilter === EXECUTIVE_FILTER
									? executiveColumns
									: typesOfFilter === PHYSICIAN_FILTER
									? physicianColumns
									: []
							}
							data={paginatedLists}
							renderCell={renderCell}
						/>
					)}
				</div>
			)}
			{!isLoading && (
				<PaginationRow
					totalResults={state ? listData?.length : rows.leads.length}
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
			<OverlayModal isOpen={modalOpen} onClose={closeModal}>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Rename Search
						</h3>
						<div
							onClick={closeModal}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					<div className='mt-4'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Give a new name:
						</h2>
						<input
							type='text'
							value={newName}
							onChange={(e) => setnewName(e.target.value)}
							placeholder='Rename...'
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>

					<div className='flex items-center justify-end gap-2 mt-3'>
						<button
							onClick={closeModal}
							className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
						>
							Cancel
						</button>
						<button
							onClick={() => {
								handleRenameList();
							}}
							disabled={!newName}
							className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
						>
							{renameLoading ? (
								<>
									<CircularProgress sx={{ color: 'white' }} size={10} />{' '}
									Renaming
								</>
							) : (
								'Rename'
							)}
						</button>
					</div>
				</div>
			</OverlayModal>
		</div>
	);
};
