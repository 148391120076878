export const getLevelID = (searchValue) => {
  return [
    {
      name: "C-Level",
    },
    {
      name: "Manager-Level",
    },
    {
      name: "Director-Level",
    },
    {
      name: "Staff",
    },
    {
      name: "VP-Level",
    },
  ];
};
export const getGeographicClassfication = (searchValue) => {
  return [
    {
      name: "Rural",
    },
    {
      name: "Urban",
    },
  ];
};
export const getGender = (searchValue) => {
  return [
    {
      name: "M",
    },
    {
      name: "F",
    },
  ];
};
