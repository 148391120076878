/** @format */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assests/image/companylogo.png';

const Failed = () => {
	const navigate = useNavigate();
	const [countdown, setCountdown] = useState(10);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		const timer = setTimeout(() => {
			navigate('/contactsearch');
		}, 10000);

		return () => {
			clearInterval(interval);
			clearTimeout(timer);
		};
	}, [navigate]);

	return (
		<div class='max-w-[50rem] min-h-screen flex flex-col mx-auto size-full'>
			<header class='mb-auto flex justify-center z-50 w-full py-4'>
				<nav class='px-4 sm:px-6 lg:px-8'>
					<img
						src={logo}
						alt='HealthDBi'
						className='cursor-pointer h-12 w-auto'
					/>
				</nav>
			</header>
			<main id='content'>
				<div class='text-center py-10 px-4 sm:px-6 lg:px-8'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						stroke-width='1.5'
						stroke-linecap='round'
						stroke-linejoin='round'
						class='size-14 mb-3 mx-auto text-red-600'
					>
						<circle cx='12' cy='12' r='10' />
						<path d='m15 9-6 6' />
						<path d='m9 9 6 6' />
					</svg>
					<h3 className='text-xl font-semibold text-gray-800'>
						Transaction Failed
					</h3>
					<h3 className='px-10 mt-2 text-gray-700 font-medium text-sm'>
						Sorry, Your transaction was not successful. You can view the details
						in your transaction summary.
					</h3>
					<p className='mt-4 text-sm font-medium text-gray-800'>
						You’ll be redirected to the homepage in {countdown} seconds. Or,{' '}
						<span
							className='text-gray-950 border-b-2 !border-gray-600 cursor-pointer'
							onClick={() => navigate('/billing')}
						>
							click here
						</span>{' '}
						to go now.
					</p>
				</div>
			</main>
			<footer class='mt-auto text-center py-5'>
				<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
					<p class='text-sm text-gray-600'>
						© All Rights Reserved. 2022-{new Date().getFullYear()}.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default Failed;
