/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { DELETE_SUB_ADMIN, GET_ALL_SUB_ADMIN } from '../../../Context/Types';
import { AuthContext } from '../../../Context/AuthContext';
import AddSubadmin from './AddSubadmin';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import DynamicTable from '../../../Elements/Misc/DTable';
import TableSkeltonLoader from '../../../Elements/TableSkeltonLoader/TableSkeltonLoader';
import OverlayModal from '../../../Elements/Misc/Modal';

const Subadmin = () => {
	const { dispatch } = useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [isModal, setIsModal] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		dispatch({
			type: GET_ALL_SUB_ADMIN,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	}, []);

	const handleDelete = (id) => {
		dispatch({
			type: DELETE_SUB_ADMIN,
			id: id,
			updateState: setRows,
		});
	};

	const columns = [
		{
			title: 'Name',
		},
		{
			title: 'Email',
		},
		{
			title: 'Creation Date',
		},
		{
			title: 'Actions',
		},
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		console.log(row.createdAt);
		if (col.title === 'Name') {
			return row?.name;
		} else if (col.title === 'Email') {
			return row?.email;
		} else if (col.title === 'Creation Date') {
			return new Date(row.createdAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		} else if (col.title === 'Actions') {
			return (
				<div className='flex gap-4 items-center'>
					<div
						onClick={() => {
							setSelectedRow(row);
							setIsModal(true);
						}}
						className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-4'
						>
							<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
							<path d='m15 5 4 4' />
						</svg>
						Edit
					</div>
					<div
						onClick={() => {
							handleDelete(row._id);
						}}
						className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-4'
						>
							<path d='M3 6h18' />
							<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
							<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
							<line x1='10' x2='10' y1='11' y2='17' />
							<line x1='14' x2='14' y1='11' y2='17' />
						</svg>
						Delete
					</div>
				</div>
			);
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				<div className={`flex items-center px-4 pt-3 justify-end`}>
					<button
						className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2 disabled:opacity-60 disabled:pointer-events-none'
						onClick={() => {
							setIsModal(true);
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-4'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						Add Subadmin
					</button>
				</div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={3} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>

			<OverlayModal
				isOpen={isModal}
				onClose={() => {
					setIsModal(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							{selectedRow ? 'Edit Subadmin' : 'Add Subadmin'}
						</h3>
						<div
							onClick={() => {
								setIsModal(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<AddSubadmin
						setIsModal={setIsModal}
						setSelectedRow={setSelectedRow}
						selectedRow={selectedRow}
						updateState={setRows}
					/>
				</div>
			</OverlayModal>
		</>
	);
};

export default Subadmin;
