/** @format */

import React, { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../Context/AuthContext';
import { CANCEL_SUBSCRIPTION } from '../../../Context/Types';
import { NotificationManager } from 'react-notifications';
import OverlayModal from '../../../Elements/Misc/Modal';

const reasonOptions = [
	'Pricing',
	'Data Quality',
	'Platform Utility',
	'Found a better vendor',
	'Other',
];

const CancelSubscription = (props) => {
	const [value, setValue] = React.useState();
	const [otherReason, setOtherReason] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useContext(AuthContext);

	const handleSubmit = () => {
		if (!value || !otherReason) {
			NotificationManager.error('Please select a reason');
			return;
		}
		dispatch({
			type: CANCEL_SUBSCRIPTION,
			values: {
				reason: value === 'Other' ? otherReason : value,
			},
			callback: props.close,
			setIsLoading: setIsLoading,
		});
	};

	return (
		<OverlayModal isOpen={props.open} onClose={props.close}>
			<div className='w-96'>
				<div className='flex justify-between items-center gap-4'>
					<h3 className='text-sm font-semibold text-gray-800'>
						Cancel Subscription
					</h3>
					<div
						onClick={props.close}
						className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</div>
				</div>
				<h2 className='text-sm font-medium text-left mt-4'>
					Reason for Cancellation
				</h2>
				<div className='w-full my-2 rounded-lg'>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center'>
						{reasonOptions?.length > 0 &&
							reasonOptions?.map((item, i) => (
								<div
									key={i}
									className={`rounded-full ${
										value === item
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={() => setValue(item)}
										className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
									>
										<input
											name={`userReason${i}`}
											type='radio'
											checked={value === item}
											class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
										/>
										{item}
									</h3>
								</div>
							))}
					</div>
				</div>
				{value === 'Other' && (
					<div className=''>
						<textarea
							rows={'3'}
							placeholder='Write your reason...'
							value={otherReason}
							onChange={(e) => {
								setOtherReason(e.target.value);
							}}
							className='w-full px-3 py-2 focus:outline-none border !border-gray-200 text-xs font-medium text-gray-800 rounded-md'
						></textarea>
					</div>
				)}

				<button
					disabled={false}
					onClick={handleSubmit}
					className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
				>
					{isLoading ? (
						<CircularProgress sx={{ color: 'white' }} size={14} />
					) : (
						'Submit'
					)}
				</button>
			</div>
		</OverlayModal>
	);
};

export default CancelSubscription;
