/** @format */

import {
	allContacts,
	getAllListContactsRequest,
	unlock,
	downloadLeads,
	filterContacts,
} from '../APIs';
import CryptoJS from 'crypto-js';
import { NotificationManager } from 'react-notifications';
import { downloadCSV } from 'download-csv';
import { EXECUTIVE_FILTER, PHYSICIAN_FILTER } from '../filterCompo/filterTypes';

export const getAllContacts = async (
	page,
	limit,
	setAllContacts,
	setIsLoadingDataTable,
	setNumOfRecordsFound
) => {
	setIsLoadingDataTable(true);
	try {
		const res = await allContacts(page, limit);
		if (res.data) {
			setAllContacts((prev) => {
				if (prev) {
					return [...prev.concat(res.data.leads)];
				} else return res.data.leads;
			});
			setNumOfRecordsFound(res.data.count);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.info(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoadingDataTable(false);
	}
};

export const unlockField = async (
	_id,
	clickedOn,
	setLock,
	getCredits,
	setCredits,
	typesOfFilter,
	setUnlockLoading,
	getAvailableLeads
) => {
	let dataType;
	if (typesOfFilter === PHYSICIAN_FILTER) {
		dataType = '0';
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		dataType = '1';
	}

	try {
		setUnlockLoading(true);
		const res = await unlock(_id, clickedOn, dataType);
		setLock((prev) => {
			return [...prev, ...res?.data?.leads];
		});
		if (
			res.data?.leads[0]?.debounceCode !== '1' &&
			res.data?.leads[0]?.debounceCode !== '2'
		) {
			NotificationManager.info('Verification failed, Your credits are safe!');
		} else {
			NotificationManager.success(res?.data?.message);
		}
		var role = localStorage.getItem('role');
		if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
			getCredits(setCredits);
		}
		getAvailableLeads();
		setUnlockLoading(false);
	} catch (e) {
		setUnlockLoading(false);
		console.log(e);
	}
};
export const unlockMyList = async (
	_id,
	clickedOn,
	setLock,
	getCredits,
	setCredits,
	typesOfFilter
) => {
	let dataType;
	if (typesOfFilter === PHYSICIAN_FILTER) {
		dataType = '0';
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		dataType = '1';
	}

	try {
		const res = await unlock(_id, clickedOn, dataType);
		if (
			res?.data?.message.includes(
				'Email verification failed, your credits are safe !'
			)
		) {
			NotificationManager.success('Lead unlocked Successfully');
			setLock((prev) => {
				return {
					unlockedLeads: [_id, ...prev.unlockedLeads],
					data: res?.data?.leads,
					isUnlocked: true,
				};
			});
		}
		if (res?.data?.message === 'Leads Unlocked') {
			setLock((prev) => {
				return {
					unlockedLeads: [_id, ...prev.unlockedLeads],
					data: res?.data?.leads,
					isUnlocked: true,
				};
			});

			if (res?.data?.leads[0]?.emailValid === false) {
				setLock((prev) => {
					return {
						unlockedLeads: [_id, ...prev.unlockedLeads],
						data: res?.data?.leads,
						isUnlocked: true,
					};
				});
				NotificationManager.info(
					'Lead unlocked Successfully' + ' But email not valid!'
				);
			} else NotificationManager.success('Lead unlocked Successfully');
			var role = localStorage.getItem('role');
			if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
				getCredits(setCredits);
			}
		}
	} catch (e) {
		setLock({
			data: [],
			isUnlocked: false,
		});
		console.log(e);
	}
};

export const downloadLeadsReducer = async (
	values,
	setIsLoading,
	setDownload,
	getCredits,
	setCredits,
	typesOfFilter,
	getAvailableLeads,
	credits
) => {
	setCredits(Number(credits) - Number(values?.leads_ids));
	const physicianHeaders = [
		{
			key: 'FullName',
			label: 'FullName',
		},
		{
			key: 'FirstName',
			label: 'FirstName',
		},
		{
			key: 'MiddleName',
			label: 'MiddleName',
		},
		{
			key: 'LastName',
			label: 'LastName',
		},
		{
			key: 'Specialty',
			label: 'Specialty',
		},
		{
			key: 'SpecialtyGroup1',
			label: 'Specialty Group',
		},

		{
			key: 'SpecialtyType',
			label: 'HCP Type',
		},
		{
			key: 'Hospital_Doctor',
			label: 'Hospital/Clinic',
		},
		{
			key: 'EmailAddress',
			label: 'EmailAddress',
		},
		{
			key: 'PhoneNumber',
			label: 'PhoneNumber',
		},
		{
			key: 'Address1',
			label: 'Address1',
		},
		{
			key: 'Address2',
			label: 'Address2',
		},

		{
			key: 'City',
			label: 'City',
		},
		{
			key: 'State',
			label: 'State',
		},
		{
			key: 'Country',
			label: 'Country',
		},

		{
			key: 'ZIPCode',
			label: 'ZIPCode',
		},
		{
			key: 'WebAddress',
			label: 'WebAddress',
		},

		{
			key: 'FaxNumber',
			label: 'FaxNumber',
		},

		//  {
		// key: "ISP",
		// label: "ISP",
		//  },

		{
			key: 'NPINumber',
			label: 'NPINumber',
		},
		// {
		//   key: "MasterID2",
		//   label: "MasterID2",
		// },
		{
			key: 'EnumerationDate',
			label: 'EnumerationDate',
		},
		//  {
		// key: "DeactivationReasonCode",
		// label: "DeactivationReasonCode",
		//  },
		{
			key: 'Gender',
			label: 'Gender',
		},
		{
			key: 'degree',
			label: 'Degree',
		},
		//  {
		// key: "TaxonomyCode",
		// label: "TaxonomyCode",
		//  },
		{
			key: 'LicenseNumber',
			label: 'LicenseNumber',
		},
		{
			key: 'LicenseIssueState',
			label: 'LicenseIssueState',
		},
		//  {
		// key: "DeactivationDate",
		// label: "DeactivationDate",
		//  },
		//  {
		// key: "AMA_Description",
		// label: "AMA_Description",
		//  },
		//  {
		// key: "NUCCDescription",
		// label: "NUCCDescription",
		//  },
		{
			key: 'emailstatus',
			label: 'Status',
		},
	];
	const executiveHeaders = [
		{
			key: 'FullName',
			label: 'FullName',
		},
		{
			key: 'FirstName',
			label: 'FirstName',
		},
		{
			key: 'MiddleName',
			label: 'MiddleName',
		},
		{
			key: 'LastName',
			label: 'LastName',
		},
		{
			key: 'EmailAddress',
			label: 'EmailAddress',
		},
		{
			key: 'PhoneNumber',
			label: 'PhoneNumber',
		},

		{
			key: 'Address1',
			label: 'Address1',
		},
		{
			key: 'Address2',
			label: 'Address2',
		},

		{
			key: 'City',
			label: 'City',
		},
		{
			key: 'State',
			label: 'State',
		},
		{
			key: 'Country',
			label: 'Country',
		},

		{
			key: 'ZIPCode',
			label: 'ZIPCode',
		},
		{
			key: 'WebAddress',
			label: 'WebAddress',
		},

		//  {
		// key: "FaxNumber",
		// label: "FaxNumber",
		//  },
		// {
		// 	key: 'MasterID',
		// 	label: 'MasterID',
		// },
		//  {
		// key: "ISP",
		// label: "ISP",
		//  },

		// {
		//   label: "NPINumber",
		//   label: "NPINumber",
		// },
		// {
		//   key: "MasterID2",
		//   label: "MasterID2",
		// },
		//  {
		// key: "EnumerationDate",
		// label: "EnumerationDate",
		//  },
		//  {
		// key: "DeactivationReasonCode",
		// label: "DeactivationReasonCode",
		//  },
		//  {
		// key: "Gender",
		// label: "Gender",
		//  },
		// {
		//   label: "degree",
		//   label: "degree",
		// },
		//  {
		// key: "TaxonomyCode",
		// label: "TaxonomyCode",
		//  },
		//  {
		// key: "LicenseNumber",
		// label: "LicenseNumber",
		//  },
		//  {
		// key: "LicenseIssueState",
		// label: "LicenseIssueState",
		//  },
		//  {
		// key: "DeactivationDate",
		// label: "DeactivationDate",
		//  },
		//  {
		// key: "AMA_Description",
		// label: "AMA_Description",
		//  },
		//  {
		// key: "NUCCDescription",
		// label: "NUCCDescription",
		//  },
		// {
		//   label: "Action",
		//   label: "Action",
		// },
		{
			key: 'Title',
			label: 'Title',
		},
		{
			key: 'LevelID',
			label: 'LevelID',
		},
		{
			key: 'Department',
			label: 'Department',
		},
		{
			key: 'Hospital_Doctor',
			label: 'Hospital/Clinic',
		},
		{
			key: 'FirmType',
			label: 'FirmType',
		},
		{
			key: 'HospitalType',
			label: 'HospitalType',
		},
		{
			key: 'BedsRange',
			label: 'BedsRange',
		},
		{
			key: 'Ownership',
			label: 'Ownership',
		},
		{
			key: 'Market_Concentration_Index',
			label: 'Market_Concentration_Index',
		},
		{
			key: 'HospitalType',
			label: 'HospitalType',
		},
		{
			key: 'CompanyStatus',
			label: 'CompanyStatus',
		},
		{
			key: 'emailstatus',
			label: 'Status',
		},
	];
	let dataType;
	if (typesOfFilter === PHYSICIAN_FILTER) {
		dataType = '0';
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		dataType = '1';
	}

	setIsLoading(true);
	try {
		const res = await downloadLeads({ ...values, dataType: dataType });

		if (res.data.message === 'Successful!') {
			const formatedData = res.data.leads.map((item, i) => {
				return {
					...item,
					emailValid: item.emailValid === true ? 'True' : 'False',
					emailstatus:
						item?.debounceCode === '1'
							? 'valid'
							: item?.debounceCode === '2'
							? 'catchall'
							: 'invalid',
				};
			});
			var userDownload = true;
			if (res.data.leads.length === 0) {
				// NotificationManager.info(`Invalid Lead, your credit is safe!`);
				userDownload = false;
			}
			if (!values?.verifyAll) {
				NotificationManager.success(
					`${
						res.data?.acceptAll + res.data?.validOnly
					} leads Successfully Downloaded...Accepted Lead: ${
						res?.data?.acceptAll
					}, Valid Leads: ${res?.data?.validOnly}`
				);
				setDownload(false);
			} else {
				NotificationManager.success(
					`${res.data?.validOnly} leads Successfully Downloaded...Valid Leads: ${res?.data?.validOnly}`
				);
				setDownload(false);
			}
			if (userDownload) {
				if (typesOfFilter === PHYSICIAN_FILTER) {
					downloadCSV(
						formatedData,
						physicianHeaders,
						String(values?.downloadName)
					);
				}
				if (typesOfFilter === EXECUTIVE_FILTER) {
					downloadCSV(
						formatedData,
						executiveHeaders,
						String(values?.downloadName)
					);
				}
			}
			getAvailableLeads();
			var role = localStorage.getItem('role');
			if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
				getCredits(setCredits);
			}
		}
	} catch (e) {
		var role = localStorage.getItem('role');
		if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
			getCredits(setCredits);
		}
	} finally {
		setIsLoading(false);
	}
};
export const downloadSpecificLeadsReducer = async (
	filter,
	typesOfFilter,
	numberOfLeads,
	verifyAll,
	setIsLoading,
	setDownload,
	getAllContacts,
	fileName,
	setFileName,
	verified,
	getCredits,
	setCredits,
	getAvailableLeads,
	credits
) => {
	var role = localStorage.getItem('role');
	if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
		setCredits(Number(credits) - Number(numberOfLeads));
	}

	setIsLoading(true);
	try {
		let dataType;
		let filterOptions = {};
		for (const [label, value] of Object.entries(filter)) {
			if (value) {
				if (typeof value === 'object' && value.length === 0) continue;
				filterOptions[label] = value;
			}
		}

		if (typesOfFilter === PHYSICIAN_FILTER) {
			filterOptions['type'] = ['0'];
			dataType = '0';
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			filterOptions['type'] = ['1'];
			dataType = '1';
		}
		const subRes = await filterContacts(filterOptions, 1, numberOfLeads);
		const bytes = CryptoJS.AES.decrypt(
			subRes.data.encryptedData,
			'docdbisky987'
		);
		const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
		const leadIds = JSON.parse(decryptedData).map((item) => item._id);

		const physicianHeaders = [
			{
				key: 'FullName',
				label: 'FullName',
			},
			{
				key: 'FirstName',
				label: 'FirstName',
			},
			{
				key: 'MiddleName',
				label: 'MiddleName',
			},
			{
				key: 'LastName',
				label: 'LastName',
			},
			{
				key: 'Specialty',
				label: 'Specialty',
			},
			{
				key: 'SpecialtyGroup1',
				label: 'Specialty Group',
			},
			{
				key: 'SpecialtyType',
				label: 'HCP Type',
			},
			{
				key: 'Hospital_Doctor',
				label: 'Hospital/Clinic',
			},
			{
				key: 'EmailAddress',
				label: 'EmailAddress',
			},
			{
				key: 'PhoneNumber',
				label: 'Phone Number',
			},
			{
				key: 'Address1',
				label: 'Address 1',
			},
			{
				key: 'Address2',
				label: 'Address 2',
			},

			{
				key: 'City',
				label: 'City',
			},
			{
				key: 'State',
				label: 'State',
			},
			{
				key: 'Country',
				label: 'Country',
			},

			{
				key: 'ZIPCode',
				label: 'ZIPCode',
			},
			{
				key: 'WebAddress',
				label: 'WebAddress',
			},

			{
				key: 'FaxNumber',
				label: 'FaxNumber',
			},
			//  {
			// key: "ISP",
			// label: "ISP",
			//  },

			{
				key: 'NPINumber',
				label: 'NPINumber',
			},
			// {
			//   key: "MasterID2",
			//   label: "MasterID2",
			// },
			{
				key: 'EnumerationDate',
				label: 'Enumeration Date',
			},
			//  {
			// key: "DeactivationReasonCode",
			// label: "DeactivationReasonCode",
			//  },
			{
				key: 'Gender',
				label: 'Gender',
			},
			{
				key: 'degree',
				label: 'Degree',
			},
			//  {
			// key: "TaxonomyCode",
			// label: "TaxonomyCode",
			//  },
			{
				key: 'LicenseNumber',
				label: 'License Number',
			},
			{
				key: 'LicenseIssueState',
				label: 'License Issue State',
			},
			//  {
			// key: "DeactivationDate",
			// label: "DeactivationDate",
			//  },
			//  {
			// key: "AMA_Description",
			// label: "AMA_Description",
			//  },
			//  {
			// key: "NUCCDescription",
			// label: "NUCCDescription",
			//  },
			// {
			//   label: "Action",
			//   label: "Action",
			// },
			{
				key: 'emailstatus',
				label: 'Status',
			},
			// { key: 'emailValid', label: 'emailValid' },
		];
		const executiveHeaders = [
			{
				key: 'FullName',
				label: 'FullName',
			},
			{
				key: 'FirstName',
				label: 'FirstName',
			},
			{
				key: 'MiddleName',
				label: 'MiddleName',
			},
			{
				key: 'LastName',
				label: 'LastName',
			},
			{
				key: 'EmailAddress',
				label: 'EmailAddress',
			},
			{
				key: 'PhoneNumber',
				label: 'PhoneNumber',
			},
			{
				key: 'Address1',
				label: 'Address 1',
			},
			{
				key: 'Address2',
				label: 'Address 2',
			},

			{
				key: 'City',
				label: 'City',
			},
			{
				key: 'State',
				label: 'State',
			},
			{
				key: 'Country',
				label: 'Country',
			},

			{
				key: 'ZIPCode',
				label: 'ZIPCode',
			},
			{
				key: 'WebAddress',
				label: 'WebAddress',
			},

			//  {
			// key: "FaxNumber",
			// label: "FaxNumber",
			//  },
			// {
			// 	key: 'MasterID',
			// 	label: 'MasterID',
			// },
			//  {
			// key: "ISP",
			// label: "ISP",
			//  },

			// {
			//   label: "NPINumber",
			//   label: "NPINumber",
			// },
			// {
			//   key: "MasterID2",
			//   label: "MasterID2",
			// },
			//  {
			// key: "EnumerationDate",
			// label: "EnumerationDate",
			//  },
			//  {
			// key: "DeactivationReasonCode",
			// label: "DeactivationReasonCode",
			//  },
			//  {
			// key: "Gender",
			// label: "Gender",
			//  },
			// {
			//   label: "degree",
			//   label: "degree",
			// },
			//  {
			// key: "TaxonomyCode",
			// label: "TaxonomyCode",
			//  },
			//  {
			// key: "LicenseNumber",
			// label: "LicenseNumber",
			//  },
			//  {
			// key: "LicenseIssueState",
			// label: "LicenseIssueState",
			//  },
			//  {
			// key: "DeactivationDate",
			// label: "DeactivationDate",
			//  },
			//  {
			// key: "AMA_Description",
			// label: "AMA_Description",
			//  },
			//  {
			// key: "NUCCDescription",
			// label: "NUCCDescription",
			//  },
			// {
			//   label: "Action",
			//   label: "Action",
			// },
			{
				key: 'Title',
				label: 'Title',
			},
			{
				key: 'LevelID',
				label: 'LevelID',
			},
			{
				key: 'Department',
				label: 'Department',
			},
			{
				key: 'Hospital_Doctor',
				label: 'Hospital/Clinic',
			},
			{
				key: 'FirmType',
				label: 'FirmType',
			},
			{
				key: 'HospitalType',
				label: 'HospitalType',
			},
			{
				key: 'BedsRange',
				label: 'BedsRange',
			},
			{
				key: 'Ownership',
				label: 'Ownership',
			},
			{
				key: 'Market_Concentration_Index',
				label: 'Market_Concentration_Index',
			},
			{
				key: 'HospitalType',
				label: 'HospitalType',
			},
			{
				key: 'CompanyStatus',
				label: 'CompanyStatus',
			},
			{
				key: 'emailstatus',
				label: 'Status',
			},
			// { key: 'emailValid', label: 'emailValid' },
		];

		const res = await downloadLeads({
			leads_ids: leadIds,
			verifyAll: verifyAll,
			dataType: dataType,
			downloadName: fileName,
			isVerify: verified,
		});
		if (res.data.message === 'Successful!') {
			const formatedData = res.data.leads.map((item, i) => {
				return {
					...item,
					emailValid: item.emailValid === true ? 'True' : 'False',
					emailstatus:
						item?.debounceCode === '1'
							? 'valid'
							: item?.debounceCode === '2'
							? 'catchall'
							: 'invalid',
				};
			});
			var userDownload = true;
			if (res.data.leads.length === 0) {
				NotificationManager.info(`Invalid Lead, your credit is safe!`);
				userDownload = false;
			}
			if (!verifyAll) {
				NotificationManager.success(
					`${
						res.data?.acceptAll + res.data?.validOnly
					} leads Successfully Downloaded...Accepted Lead: ${
						res?.data?.acceptAll
					}, Valid Leads: ${res?.data?.validOnly}`
				);
				setDownload(false);
			} else {
				NotificationManager.success(
					`${res.data?.validOnly} leads Successfully Downloaded...Valid Leads: ${res?.data?.validOnly}`
				);
				setDownload(false);
			}
			if (userDownload) {
				if (typesOfFilter === PHYSICIAN_FILTER) {
					downloadCSV(formatedData, physicianHeaders, String(fileName));
				}
				if (typesOfFilter === EXECUTIVE_FILTER) {
					downloadCSV(formatedData, executiveHeaders, String(fileName));
				}
			}
			getAvailableLeads();
			var role = localStorage.getItem('role');
			if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
				getCredits(setCredits);
			}
			getAllContacts();
			setFileName('');
			setIsLoading(false);
		}
	} catch (e) {
		setIsLoading(false);
		setDownload(false);
		var role = localStorage.getItem('role');
		if (role !== 'ADMIN' && role !== 'SUB_ADMIN') {
			getCredits(setCredits);
		}
		getAllContacts();
		setFileName('');
	}
};

export const getAllListContacts = async (
	folder_id,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getAllListContactsRequest(folder_id);
		if (res.data) {
			const formatedData = res.data.leads.map((item, index) => {
				return {
					...item,
					isUnlocked: res.data.unlockIndex[index],
					Name: item.FirstName + ' ' + item.LastName,
				};
			});

			updateState({ leads: formatedData, unlockIndex: res.data.unlockIndex });
		}
	} catch (e) {
	} finally {
		setIsLoading(false);
	}
};
