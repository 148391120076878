import { NotificationManager } from "react-notifications";
import { getDashboardData } from "../APIs";

export const getDashboardDataReducer = async (
  year,
  setDashboardData,
  setIsDashBoardLoading
) => {
  setIsDashBoardLoading(true);
  try {
    const res = await getDashboardData(year);
    setDashboardData(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsDashBoardLoading(false);
  }
};
