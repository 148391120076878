/** @format */

import { NotificationManager } from 'react-notifications';
import {
	getCreditsRequest,
	requestLogRequest,
	requestLogRequestCom,
	sendRequestCredits,
	acceptMemberCreditRequest,
	denyMemberCreditRequest,
	getCompanyMembersCreditRequestAdmin,
	buyMoreCredits,
} from '../APIs';

export const getCredits = async (upDateState, setTypeOfUser = () => {}) => {
	try {
		const res = await getCreditsRequest();
		if (res.data || res.data === 0) {
			upDateState(res.data.credits);
			setTypeOfUser(res.data.type);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	}
};

export const requestCredits = async (values, resetForm, setIsLoading, cb) => {
	setIsLoading(true);
	try {
		const res = await sendRequestCredits(values);
		if (res.data) {
			NotificationManager.success(res.data);
			resetForm({ values: '' });
			cb();
		}
	} catch (e) {
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const requestLog = async (updateState, setIsLoading) => {
	setIsLoading(true);
	try {
		const res = await requestLogRequest();
		updateState(res.data);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const requestLogCom = async (updateState, setIsLoading) => {
	setIsLoading(true);
	try {
		const res = await requestLogRequestCom();

		updateState(res.data);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const acceptMemberCreditRequestReducer = async (
	id,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await acceptMemberCreditRequest(id);
		if (res.data === 'Request Approved!') {
			NotificationManager.success(res.data);
		}
		requestLogCom(updateState, setIsLoading);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const denyMemberCreditRequestReducer = async (
	id,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await denyMemberCreditRequest(id);
		if (res.data === 'Request Declined!') {
			NotificationManager.success(res.data);
		}
		requestLogCom(updateState, setIsLoading);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const getCompanyMembersCreditRequestAdminReducer = async (
	id,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getCompanyMembersCreditRequestAdmin(id);
		if (res.data) {
			updateState(res.data);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const buyMoreCreditsReducer = async (values, setIsLoading) => {
	setIsLoading(true);
	try {
		const res = await buyMoreCredits(values);
		if (res.data) {
			window.location = res.data.link;
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
