/** @format */

import logo from '../../assests/image/companylogo.png';
import { useContext, useState } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import { FORGET_PASSWORD } from '../../Context/Types';
import Info from './Info';
import { NotificationManager } from 'react-notifications';

const ForgotPassword = () => {
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);

	const [initialValues, setInitialValues] = useState({
		email: '',
	});

	const handleSubmit = (values) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(initialValues?.email)) {
			NotificationManager.error('Invalid email format');
			return;
		}

		dispatch({
			type: FORGET_PASSWORD,
			payload: initialValues,
			setIsLoading: setIsLoading,
		});
	};

	return (
		<>
			<div className='min-h-screen w-full grid grid-cols-2 lg:grid-cols-3 bg-white text-left'>
				<div class='min-h-screen max-w-[36rem] mx-auto flex flex-col size-full bg-white col-span-2'>
					<header class='mb-auto z-50 w-full py-4'>
						<nav class='px-4 sm:px-6 lg:px-8'>
							<img src={logo} alt='HealthDBi' className='h-12 w-auto' />
						</nav>
					</header>

					<main id='content'>
						<div class='py-10 px-4 sm:px-6 lg:px-8'>
							<h1 class='block text-xl font-bold text-gray-800'>
								Forgot password
							</h1>
							<p class='mt-0.5 text-gray-600 text-sm'>
								Put your account email address.
							</p>

							<div className='mt-4'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Email:
								</h2>
								<input
									type='text'
									value={initialValues?.email}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											email: e.target.value,
										})
									}
									placeholder='Enter your email'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>

							<button
								onClick={handleSubmit}
								class='mt-5 w-full !py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
							>
								{isLoading ? (
									<div
										class='animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full'
										role='status'
										aria-label='loading'
									>
										<span class='sr-only'>Loading...</span>
									</div>
								) : (
									'Submit'
								)}
							</button>
						</div>
					</main>
					<footer class='mt-auto text-left pb-5'>
						<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
							<p class='text-sm text-gray-700'>
								If you don't see it in a few minutes, check your spam folder.
							</p>
						</div>
					</footer>
				</div>

				<Info />
			</div>
		</>
	);
};

export default ForgotPassword;
