/** @format */

import React from 'react';
import { usePagination, DOTS } from './usePagination';

const Pagination = ({
	onPageChange,
	totalPages,
	siblingCount = 1,
	currentPage,
}) => {
	const paginationRange = usePagination({
		totalPages,
		currentPage,
		siblingCount,
	});

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];
	return (
		<ul className='flex justify-center list-none'>
			<li
				className={`px-3 h-8 text-center hover:bg-gray-200 w-8 mx-1 flex text-slate-800 items-center justify-center rounded-md text-xs ${
					currentPage === 1
						? 'pointer-events-none opacity-50'
						: 'cursor-pointer'
				}`}
				onClick={onPrevious}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
					className='flex-shrink-0 w-4 h-4'
				>
					<path d='m15 18-6-6 6-6' />
				</svg>
			</li>
			{paginationRange.map((pageNumber, index) => {
				if (pageNumber === DOTS) {
					return (
						<li
							key={index}
							className='w-8 px-3 h-8 text-center mx-1 text-slate-800 flex items-center justify-center rounded-full text-xs dots'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
								className='flex-shrink-0 w-4 h-4'
							>
								<circle cx='12' cy='15' r='1' />
								<circle cx='19' cy='15' r='1' />
								<circle cx='5' cy='15' r='1' />
							</svg>
						</li>
					);
				}
				return (
					<li
						key={index}
						className={`px-3 h-8 w-8 cursor-pointer text-slate-800 font-medium text-center mx-1 flex items-center justify-center rounded-md text-xs ${
							pageNumber === currentPage
								? 'border border-gray-300 hover:bg-transparent'
								: 'hover:bg-gray-200'
						}`}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</li>
				);
			})}
			<li
				className={`px-3 h-8 text-center hover:bg-gray-200 w-8 mx-1 flex text-slate-800 items-center justify-center rounded-md text-xs ${
					currentPage === lastPage
						? 'pointer-events-none opacity-50'
						: 'cursor-pointer'
				}`}
				onClick={onNext}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
					className='flex-shrink-0 w-4 h-4'
				>
					<path d='m9 18 6-6-6-6' />
				</svg>
			</li>
		</ul>
	);
};

export default Pagination;
