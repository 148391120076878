/** @format */

import React, { useContext, useEffect, useState } from 'react';
import {
	DELETE_SUBSCRIPTION,
	GET_ALL_SUBSCRIPTION_PLAN,
	CHANGE_COMPANY_SUBSCRIPTION,
	HOME_PAGE_SHOW,
} from '../../../Context/Types';
import { AuthContext } from '../../../Context/AuthContext';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import TableSkeltonLoader from '../../../Elements/TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../../Elements/Misc/DTable';
import OverlayModal from '../../../Elements/Misc/Modal';
import AddPackage from './AddPackage';

const Subscriptions = (props) => {
	const { dispatch } = useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [model, setmodel] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(() => {
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_ALL_SUBSCRIPTION_PLAN,
				id: props.selectedCompanyByAdmin,
				flag: '',
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_ALL_SUBSCRIPTION_PLAN,
				id: '',
				flag: 'COMMON',
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		}
	}, []);

	const handleChangeSubscription = (id, type) => {
		dispatch({
			type: CHANGE_COMPANY_SUBSCRIPTION,
			id: props?.selectedCompanyByAdmin,
			sub_id: id,
			isAnnual: type,
		});
	};

	const handleDelete = (id) => {
		dispatch({
			type: DELETE_SUBSCRIPTION,
			payload: id,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	};
	const handleHomePageShow = (row, status) => {
		dispatch({
			type: HOME_PAGE_SHOW,
			payload: row,
			status: status,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	};

	const columns = [
		{ title: 'Name' },
		{ title: 'Users Allowed' },
		{ title: 'Credits' },
		{ title: 'Price' },
		{ title: 'Additional Cost' },
		{ title: 'Last Updated' },
		{ title: 'Actions' },
	];
	if (props.selectedCompanyByAdmin === null) {
		columns.splice(6, 0, { title: 'Show in Website' });
	} else {
		columns.splice(6, 0, { title: 'Current Plan' });
	}

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Name') {
			return row?.title;
		} else if (col.title === 'Last Updated') {
			return new Date(row?.updatedAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		} else if (col.title === 'Users Allowed') {
			return row?.no_of_user;
		} else if (col.title === 'Credits') {
			return (
				<>
					<p>Monthly: {row?.monthly_credits}</p>
					<p>Yearly: {row?.annually_credits}</p>
				</>
			);
		} else if (col.title === 'Price') {
			return (
				<>
					<p>Monthly: ${row?.monthly_amount}</p>
					<p>Yearly: ${row?.annually_amount}</p>
				</>
			);
		} else if (col.title === 'Additional Cost') {
			return (
				<>
					<p>Credit: ${row?.cost_per_credit}</p>
					<p>User: ${row?.cost_per_user}</p>
				</>
			);
		} else if (col.title === 'Show in Website') {
			return (
				<>
					<div class='flex justify-start items-center'>
						<label class='text-sm text-gray-700 me-2'>No</label>

						<label class='switch'>
							<input
								type='checkbox'
								checked={row?.homepage}
								onChange={() => handleHomePageShow(row, !row.homepage)}
							/>
							<span class='slider round'></span>
						</label>

						<label class='relative text-sm text-gray-700 ms-2'>Yes</label>
					</div>
				</>
			);
		} else if (col.title === 'Current Plan') {
			return row?.title === props?.selectedCompany?.plan?.subscription_type
				? 'Yes'
				: 'No';
		} else if (col.title === 'Actions') {
			return (
				<div className='flex gap-4 items-center'>
					{props.selectedCompanyByAdmin &&
						(row?.title !== props?.selectedCompany?.plan?.subscription_type ||
							props?.selectedCompany?.isCancelled) && (
							<div
								onClick={() => {
									handleChangeSubscription(row._id, false);
								}}
								className='cursor-pointer flex items-center gap-1 text-teal-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<circle cx='12' cy='12' r='10' />
									<path d='M8 12h8' />
									<path d='M12 8v8' />
								</svg>
								Assign Monthly
							</div>
						)}
					{props.selectedCompanyByAdmin &&
						(row?.title !== props?.selectedCompany?.plan?.subscription_type ||
							props?.selectedCompany?.isCancelled) && (
							<div
								onClick={() => {
									handleChangeSubscription(row._id, true);
								}}
								className='cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<circle cx='12' cy='12' r='10' />
									<path d='M8 12h8' />
									<path d='M12 8v8' />
								</svg>
								Assign Annual
							</div>
						)}
					{(!props.selectedCompanyByAdmin ||
						(props.selectedCompanyByAdmin && row?.type !== 'COMMON')) && (
						<div
							onClick={() => {
								setmodel(true);
								setSelectedItem(row._id);
							}}
							className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
								<path d='m15 5 4 4' />
							</svg>
							Edit
						</div>
					)}
					{(!props?.selectedCompanyByAdmin ||
						(props?.selectedCompanyByAdmin && row?.type !== 'COMMON')) && (
						<div
							onClick={() => handleDelete(row._id)}
							className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<path d='M3 6h18' />
								<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
								<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
								<line x1='10' x2='10' y1='11' y2='17' />
								<line x1='14' x2='14' y1='11' y2='17' />
							</svg>
							Delete (Temp)
						</div>
					)}
				</div>
			);
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				<div className={`flex items-center px-4 pt-3 justify-end`}>
					<button
						className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2 disabled:opacity-60 disabled:pointer-events-none'
						onClick={() => {
							setmodel(true);
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-4'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						{props?.selectedCompanyByAdmin
							? 'Add Customized Plan'
							: 'Add New Plan'}
					</button>
				</div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={3} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>

			<OverlayModal
				isOpen={model}
				onClose={() => {
					setmodel(false);
				}}
				addPlan={true}
			>
				<div className='w-[700px] max-w-1/2'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							{selectedItem ? 'Edit Plan' : 'Add Plan'}
						</h3>
						<div
							onClick={() => {
								setmodel(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<AddPackage
						close={() => {
							setmodel(false);
						}}
						id={selectedItem}
						selectedCompanyByAdmin={props.selectedCompanyByAdmin}
						setId={setSelectedItem}
						setmodel={setmodel}
						updateState={setRows}
						setRowLoading={setIsLoading}
					/>
				</div>
			</OverlayModal>
		</>
	);
};

export default Subscriptions;
