/** @format */

import React, { useState, createContext, useContext } from 'react';

import {
	getCities,
	getCountry,
	getFirmType,
	getHospitalType,
	getLicenseState,
	getOwnership,
	getSpeciality,
	getSpecialityGroup1,
	getSpecialityType,
	getStates,
	getTitle,
	getZipCode,
} from '../filterCompo/filterAPI';

import {
	getGender,
	getGeographicClassfication,
	getLevelID,
} from '../filterCompo/frontendOptions';

import {
	ADDRESS1,
	CLINIC_NAME,
	CONTACT_NAME,
	LOCATION_CITY,
	LOCATION_STATE,
	LOCATION_ZIP_CODE,
	PHYSICIAN_FILTER,
	LOCATION_COUNTRY,
	SPECIALITY_SPECIALITY,
	SPECIALITY_SPECIALITY_GROUP1,
	SPECIALITY_SPECIALITY_TYPE,
	GENDER,
	TITLE,
	HOSPITAL_TYPE,
	FIRM_TYPE,
	LEVEL_ID,
	OWNERSHIP,
	GEOGRAPHIC_LOCATION,
	EXPERIENCE,
	LICENSE_STATE,
	NPI_NUMBER,
	DEGREE,
	EMAIL_TYPE,
	HOSPITAL_NAME,
	DEPARTMENT,
	WEB_ADDRESS,
	BED_RANGE,
	TABLE_TITLE,
	TABLE_HOSPITAL_NAME,
	TABLE_HOSPITAL_TYPE,
	TABLE_DEPARTMENT,
	TABLE_CITY,
	TABLE_STATE,
	TABLE_SPECIALITY,
	TABLE_SPECIALITY_GROUP,
	TABLE_SPECIALITY_TYPE,
	TABLE_WEBADDRESS,
	TABLE_FIRMTYPE,
	TABLE_OWNERSHIP,
	TABLE_BEDRANGE,
	TABLE_DEGREE,
	TABLE_NPI,
	OFFICE_TYPE,
} from '../filterCompo/filterTypes';

export const MainContext = createContext();
const filterKeyToGlobalFilterKey = {
	contact_name: [CONTACT_NAME],
	clinic_name: [CLINIC_NAME],
	address: [ADDRESS1],
	city: [LOCATION_CITY],
	state: [LOCATION_STATE],
	country: [LOCATION_COUNTRY],
	zipcode: [LOCATION_ZIP_CODE],
	speciality: [SPECIALITY_SPECIALITY],
	specialityType: [SPECIALITY_SPECIALITY_TYPE],
	specialityGroup: [SPECIALITY_SPECIALITY_GROUP1],
	gender: [GENDER],
	title: [TITLE],
	hospitalType: [HOSPITAL_TYPE],
	firmType: [FIRM_TYPE],
	levelID: [LEVEL_ID],
	ownership: [OWNERSHIP],
	geographic: [GEOGRAPHIC_LOCATION],
	experience: [EXPERIENCE],
	licenseState: [LICENSE_STATE],
	npi: [NPI_NUMBER],
	degree: [DEGREE],
	hospitalName: [HOSPITAL_NAME],
	department: [DEPARTMENT],
	webaddress: [WEB_ADDRESS],
	bedrange: [BED_RANGE],
	emailtype: [EMAIL_TYPE],

	tabletitle: [TABLE_TITLE],
	tablehospitalName: [TABLE_HOSPITAL_NAME],
	tablehospitalType: [TABLE_HOSPITAL_TYPE],
	tabledepartment: [TABLE_DEPARTMENT],
	tablecity: [TABLE_CITY],
	tablestate: [TABLE_STATE],
	tablespeciality: [TABLE_SPECIALITY],
	tablespecialityGroup: [TABLE_SPECIALITY_GROUP],
	tablespecialityType: [TABLE_SPECIALITY_TYPE],
	tablewebaddress: [TABLE_WEBADDRESS],
	tablefirmType: [TABLE_FIRMTYPE],
	tableownership: [TABLE_OWNERSHIP],
	tablebedrange: [TABLE_BEDRANGE],
	tabledegree: [TABLE_DEGREE],
	tablenpi: [TABLE_NPI],
	officetype: [OFFICE_TYPE],
};
const globalFilterKeyTofilterKey = {
	[CONTACT_NAME]: 'contact_name',
	[CLINIC_NAME]: 'clinic_name',
	[ADDRESS1]: 'address',
	[LOCATION_CITY]: 'city',
	[LOCATION_STATE]: 'state',
	[LOCATION_COUNTRY]: 'country',
	[LOCATION_ZIP_CODE]: 'zipcode',
	[SPECIALITY_SPECIALITY]: 'speciality',
	[SPECIALITY_SPECIALITY_TYPE]: 'specialityType',
	[SPECIALITY_SPECIALITY_GROUP1]: 'specialityGroup',
	[GENDER]: 'gender',
	[TITLE]: 'title',
	[HOSPITAL_TYPE]: 'hospitalType',
	[FIRM_TYPE]: 'firmType',
	[LEVEL_ID]: 'levelID',
	[OWNERSHIP]: 'ownership',
	[GEOGRAPHIC_LOCATION]: 'geographic',
	[EXPERIENCE]: 'experience',
	[LICENSE_STATE]: 'licenseState',
	[NPI_NUMBER]: 'npi',
	[DEGREE]: 'degree',
	[HOSPITAL_NAME]: 'hospitalName',
	[DEPARTMENT]: 'department',
	[WEB_ADDRESS]: 'webaddress',
	[BED_RANGE]: 'bedrange',
	[EMAIL_TYPE]: 'emailtype',

	[TABLE_TITLE]: 'tabletitle',
	[TABLE_HOSPITAL_NAME]: 'tablehospitalName',
	[TABLE_HOSPITAL_TYPE]: 'tablehospitalType',
	[TABLE_DEPARTMENT]: 'tabledepartment',
	[TABLE_CITY]: 'tablecity',
	[TABLE_STATE]: 'tablestate',
	[TABLE_SPECIALITY]: 'tablespeciality',
	[TABLE_SPECIALITY_GROUP]: 'tablespecialityGroup',
	[TABLE_SPECIALITY_TYPE]: 'tablespecialityType',
	[TABLE_WEBADDRESS]: 'tablewebaddress',
	[TABLE_FIRMTYPE]: 'tablefirmType',
	[TABLE_OWNERSHIP]: 'tableownership',
	[TABLE_BEDRANGE]: 'tablebedrange',
	[TABLE_DEGREE]: 'tabledegree',
	[TABLE_NPI]: 'tablenpi',
	[OFFICE_TYPE]: 'officetype',
};

const globalFilterKeyToApi = {
	[CONTACT_NAME]: '',
	[CLINIC_NAME]: '',
	[ADDRESS1]: '',
	[LOCATION_CITY]: getCities,
	[LOCATION_STATE]: getStates,
	[LOCATION_COUNTRY]: getCountry,
	[LOCATION_ZIP_CODE]: getZipCode,
	[SPECIALITY_SPECIALITY]: getSpeciality,
	[SPECIALITY_SPECIALITY_TYPE]: getSpecialityType,
	[SPECIALITY_SPECIALITY_GROUP1]: getSpecialityGroup1,
	[GENDER]: getGender,
	[TITLE]: getTitle,
	[HOSPITAL_TYPE]: getHospitalType,
	[FIRM_TYPE]: getFirmType,
	[LEVEL_ID]: getLevelID,
	[OWNERSHIP]: getOwnership,
	[GEOGRAPHIC_LOCATION]: getGeographicClassfication,
	[EXPERIENCE]: '',
	[LICENSE_STATE]: getLicenseState,
	[NPI_NUMBER]: '',
	[DEGREE]: '',
	[HOSPITAL_NAME]: '',
	[DEPARTMENT]: '',
	[WEB_ADDRESS]: '',
	[BED_RANGE]: '',
};

const FilterContext = ({ children }) => {
	const [globalSelectFilter, setGlobalSelectFilter] = useState({
		[CONTACT_NAME]: [],
		[CLINIC_NAME]: [],
		[ADDRESS1]: [],
		[LOCATION_CITY]: [],
		[LOCATION_STATE]: [],
		[LOCATION_COUNTRY]: [],
		[LOCATION_ZIP_CODE]: [],
		[SPECIALITY_SPECIALITY]: [],
		[SPECIALITY_SPECIALITY_TYPE]: [],
		[SPECIALITY_SPECIALITY_GROUP1]: [],
		[GENDER]: [],
		[TITLE]: [],
		[HOSPITAL_TYPE]: [],
		[FIRM_TYPE]: [],
		[LEVEL_ID]: [],
		[OWNERSHIP]: [],
		[GEOGRAPHIC_LOCATION]: [],
		[EXPERIENCE]: [],
		[LICENSE_STATE]: [],
		[NPI_NUMBER]: [],
		[DEGREE]: [],
		[HOSPITAL_NAME]: [],
		[DEPARTMENT]: [],
		[WEB_ADDRESS]: [],
		[BED_RANGE]: [],
		[OFFICE_TYPE]: [],
		[EMAIL_TYPE]: [],

		[TABLE_TITLE]: '',
		[TABLE_HOSPITAL_NAME]: '',
		[TABLE_HOSPITAL_TYPE]: '',
		[TABLE_DEPARTMENT]: '',
		[TABLE_CITY]: '',
		[TABLE_STATE]: '',
		[TABLE_SPECIALITY]: '',
		[TABLE_SPECIALITY_GROUP]: '',
		[TABLE_SPECIALITY_TYPE]: '',
		[TABLE_WEBADDRESS]: '',
		[TABLE_FIRMTYPE]: '',
		[TABLE_OWNERSHIP]: '',
		[TABLE_BEDRANGE]: '',
		[TABLE_DEGREE]: '',
		[TABLE_NPI]: '',
	});

	return (
		<MainContext.Provider
			value={{
				globalSelectFilter,
				setGlobalSelectFilter,
				filterKeyToGlobalFilterKey,
				globalFilterKeyTofilterKey,
				globalFilterKeyToApi,
			}}
		>
			{children}
		</MainContext.Provider>
	);
};

export default FilterContext;
