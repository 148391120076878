/** @format */

import { useRef } from 'react';
import OverlayModal from '../../../Elements/Misc/Modal';
import { useReactToPrint } from 'react-to-print';

const ViewInvoice = (props) => {
	console.log(props.selectedInvoice);
	const ref = useRef();
	const handleDownload = useReactToPrint({
		content: () => ref.current,
	});

	return (
		<OverlayModal isOpen={props.open} onClose={props.close} addPlan={true}>
			<div class='relative flex flex-col bg-white rounded-xl pointer-events-auto m-0 border-none'>
				<div class='absolute top-2 end-2'>
					<button
						type='button'
						class='size-8 inline-flex justify-center items-center gap-x-2 rounded-full border-none bg-gray-200 text-gray-800 hover:bg-gray-300 focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
						aria-label='Close'
						onClick={props.close}
					>
						<span class='sr-only'>Close</span>
						<svg
							class='shrink-0 size-4'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</button>
				</div>

				<div ref={ref} class='p-4 sm:p-7 overflow-y-auto'>
					<div class='text-center'>
						<h3
							id='hs-ai-modal-label'
							class='text-lg font-semibold text-gray-800'
						>
							Invoice from HealthDBi
						</h3>
						<p class='text-sm text-gray-700'>
							Invoice ID: {props?.selectedInvoice?._id}
						</p>
					</div>

					<div class='mt-5 grid grid-cols-2 sm:grid-cols-3 gap-5'>
						<div>
							<span class='block text-xs uppercase text-gray-700'>
								Amount paid:
							</span>
							<span class='block text-sm font-medium text-gray-800'>
								${props?.selectedInvoice?.amount}
							</span>
						</div>

						<div>
							<span class='block text-xs uppercase text-gray-700'>
								Invoice Date:
							</span>
							<span class='block text-sm font-medium text-gray-800'>
								{props?.selectedInvoice?.createdAt}
							</span>
						</div>

						<div>
							<span class='block text-xs uppercase text-gray-700'>
								Invoice Status:
							</span>

							<span
								class={`block text-sm font-medium ${
									props?.selectedInvoice?.status
										? 'text-green-600'
										: 'text-red-600'
								}`}
							>
								{props?.selectedInvoice?.status ? 'PAID' : 'UNPAID'}
							</span>
						</div>
					</div>
					<div className='border flex items-center justify-between mt-4'>
						<div className='p-2 text-xs font-medium text-left'>
							<div className='font-bold'>From:</div>
							<div>{props?.selectedInvoice?.from?.name}</div>
							<div>447 Broadway, 2nd floor, #713 NewYork, NY 10013, USA</div>
							<div>team@healthdbi.com</div>
						</div>
						<div className='w-[1px] h-12 bg-gray-200' />
						<div className='p-2 text-xs text-right font-medium'>
							<div className='font-bold'>To:</div>
							<div>{props?.selectedInvoice?.company?.name}</div>

							<div>{props?.selectedInvoice?.company?.email}</div>
							<div>{props?.selectedInvoice?.company?.mobile}</div>
						</div>
					</div>
					<div className='mt-3 w-full'>
						<table className='w-full'>
							<thead className='bg-gray-200'>
								<tr className='text-xs font-semibold text-gray-800'>
									<th className='p-1.5'>Type</th>
									<th className='p-1.5'>Description</th>
									<th className='p-1.5'>Validity</th>
									<th className='p-1.5'>Credits</th>
									<th className='p-1.5'>Max Members</th>
									<th className='p-1.5'>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr className='text-xs font-medium text-gray-800'>
									<td className='p-1.5'>
										{props?.selectedInvoice?.item.subscription_type}
									</td>
									<td className='p-1.5'>
										{props?.selectedInvoice?.item.subscription_description}
									</td>
									<td className='p-1.5'>
										{props?.selectedInvoice?.item.subscription_validity} days
									</td>
									<td className='p-1.5'>
										{props?.selectedInvoice?.item.subscription_credits}
									</td>
									<td className='p-1.5'>
										{props?.selectedInvoice?.item.subscription_max_members}
									</td>
									<td className='p-1.5'>${props?.selectedInvoice?.amount}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class='mt-3'>
						<h4 class='text-xs font-semibold uppercase text-gray-800'>
							Summary
						</h4>
						<ul class='mt-2 flex flex-col'>
							<li class='inline-flex items-center gap-x-2 py-1.5 px-4 text-xs border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'>
								<div class='flex items-center justify-between w-full'>
									<span>Payment to Front</span>
									<span>${props?.selectedInvoice?.amount}</span>
								</div>
							</li>
							<li class='inline-flex items-center gap-x-2 py-1.5 px-4 text-xs border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'>
								<div class='flex items-center justify-between w-full'>
									<span>VAT</span>
									<span>$0</span>
								</div>
							</li>
							<li class='inline-flex items-center gap-x-2 py-1.5 px-4 text-xs font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'>
								<div class='flex items-center justify-between w-full'>
									<span>Amount paid</span>
									<span>${props?.selectedInvoice?.amount}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class='mt-2 flex justify-end gap-x-2 px-4'>
					<button
						class='py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
						onClick={handleDownload}
					>
						<svg
							class='shrink-0 size-4'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<polyline points='6 9 6 2 18 2 18 9' />
							<path d='M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2' />
							<rect width='12' height='8' x='6' y='14' />
						</svg>
						Print / Download
					</button>
				</div>

				<div class='mt-3 mb-1'>
					<p class='text-sm text-gray-700'>
						If you have any questions, please contact us at{' '}
						<a
							class='inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium'
							href='mailto:team@healthdbi.com'
						>
							team@healthdbi.com
						</a>
					</p>
				</div>
			</div>
		</OverlayModal>
	);
};

export default ViewInvoice;
