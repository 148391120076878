/** @format */

import React, { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
	let length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
	totalPages,
	siblingCount = 1,
	currentPage,
}) => {
	const paginationRange = useMemo(() => {
		const totalPageCount = totalPages;
		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount;

		/*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(
			currentPage + siblingCount,
			totalPageCount
		);

		const shouldShowLeftDots = leftSiblingIndex > 1;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = 1 + 2 * siblingCount;
			let leftRange = range(1, leftItemCount);
			if (leftItemCount + 1 === totalPageCount) {
				return [...leftRange, totalPageCount];
			}
			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 1 + 2 * siblingCount;
			let rightRange = range(
				totalPageCount - rightItemCount + 1,
				totalPageCount
			);
			if (rightItemCount === totalPageCount) {
				return [...rightRange];
			}
			if (totalPageCount - 3 === 1) {
				return [firstPageIndex, ...rightRange];
			}
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (!shouldShowLeftDots && !shouldShowRightDots) {
			let middleRange = range(1, totalPageCount);
			return [...middleRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex);
			if (
				leftSiblingIndex - 1 === 1 &&
				rightSiblingIndex + 1 === totalPageCount
			) {
				return [firstPageIndex, ...middleRange, lastPageIndex];
			} else if (
				leftSiblingIndex - 1 === 1 &&
				rightSiblingIndex + 1 !== totalPageCount
			) {
				return [firstPageIndex, ...middleRange, DOTS, lastPageIndex];
			} else if (
				leftSiblingIndex - 1 !== 1 &&
				rightSiblingIndex + 1 === totalPageCount
			) {
				return [firstPageIndex, DOTS, ...middleRange, lastPageIndex];
			} else {
				return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
			}
		}
	}, [totalPages, siblingCount, currentPage]);
	return paginationRange;
};
