/** @format */

import { useContext, useEffect, useState } from 'react';
import OverlayModal from '../../../Elements/Misc/Modal';
import { AuthContext } from '../../../Context/AuthContext';
import { EDIT_PROFILE } from '../../../Context/Types';
import { CircularProgress } from '@mui/material';
import useAuth from '../../../hooks/useAuth';

const EditProfile = (props) => {
	const { auth } = useAuth();
	const { dispatch, profile } = useContext(AuthContext);
	const [loading, setIsLoading] = useState(false);
	const [profileData, setProfileData] = useState({
		name: '',
		company_name: '',
		mobile: '',
	});

	useEffect(() => {
		setProfileData({
			name: profile?.name,
			company_name: profile?.company_name,
			mobile: profile?.mobile,
		});
	}, [profile]);

	const handleSubmit = () => {
		dispatch({
			type: EDIT_PROFILE,
			payload: profileData,
			setIsLoading: setIsLoading,
			//setIsEdit: setIsEdit,
		});
		props.close();
	};

	return (
		<OverlayModal isOpen={props.open} onClose={props.close}>
			<div className='w-96'>
				<div className='flex justify-between items-center gap-4'>
					<h3 className='text-sm font-semibold text-gray-800'>
						Update Profile
					</h3>
					<div
						onClick={props.close}
						className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</div>
				</div>
				<div className='mt-4'>
					<div>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Name:
						</h2>
						<input
							type='text'
							value={profileData?.name}
							onChange={(e) =>
								setProfileData({ ...profileData, name: e.target.value })
							}
							placeholder='Enter your name'
							required
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>
					{auth?.role === 'company' && (
						<div className='mt-3'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Company Name:
							</h2>
							<input
								type='text'
								value={profileData?.company_name}
								onChange={(e) =>
									setProfileData({
										...profileData,
										company_name: e.target.value,
									})
								}
								placeholder='Enter your company name'
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
					)}
					{auth?.role === 'company' && (
						<div className='mt-3'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Mobile:
							</h2>
							<input
								type='text'
								value={profileData?.mobile}
								onChange={(e) =>
									setProfileData({ ...profileData, mobile: e.target.value })
								}
								placeholder='Enter your number'
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
					)}
					<button
						disabled={
							profileData?.name?.trim() === '' ||
							profileData?.company_name?.trim() === ''
						}
						onClick={handleSubmit}
						className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
					>
						Save{' '}
						{loading && <CircularProgress sx={{ color: 'white' }} size={14} />}
					</button>
				</div>
			</div>
		</OverlayModal>
	);
};

export default EditProfile;
