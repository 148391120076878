/** @format */

import React from 'react';
import moneybag from '../../assests/image/moneybag.png';
import { useNavigate } from 'react-router-dom';
import OverlayModal from '../Misc/Modal';

const UpgradeNow = (props) => {
	const navigate = useNavigate();
	return (
		<>
			<OverlayModal isOpen={props.open} onClose={props.close}>
				<div className='flex gap-3 flex-wrap'>
					<div className='w-60 h-60 overflow-hidden relative rounded-lg flex justify-center items-center'>
						<img src={moneybag} className='relative w-32 z-1' />
						<div className='w-96 h-96 !absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center starburst starburst--expire animate-round'></div>
					</div>
					<div className='w-80 relative'>
						<div className='flex justify-between items-center gap-4'>
							<h3 className='text-sm font-semibold text-gray-800'>
								Subscription Expired
							</h3>
							<div
								onClick={props.close}
								className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='w-3 h-3'
								>
									<path d='M18 6 6 18' />
									<path d='m6 6 12 12' />
								</svg>
							</div>
						</div>

						<div className='mt-4 text-center'>
							<h3 className='text-lg font-semibold text-gray-800'>
								Oops, your subscription has expired.
							</h3>
							<p className='text-xs font-medium text-gray-600'>
								Renew now to keep uninterrupted access.
							</p>
						</div>
						<div
							class='bg-blue-100 border-blue-400 text-sm text-blue-600 rounded-lg px-2 py-2 mt-3'
							role='alert'
						>
							<div class='flex gap-2'>
								<svg
									class='flex-shrink-0 w-3 h-3 mt-1'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								>
									<circle cx='12' cy='12' r='10'></circle>
									<path d='M12 16v-4'></path>
									<path d='M12 8h.01'></path>
								</svg>

								<p class='text-xs text-start font-medium'>
									Your subscription starts on the payment date, not the invoice
									date. For example, if you pay on Oct 2, your subscription runs
									from Oct 2 to Nov 2, regardless of the original invoice dates.
								</p>
							</div>
						</div>

						<button
							onClick={() => {
								navigate('/billing');
								props.close();
							}}
							className='absolute left-0 bottom-0 w-full rounded-lg outline-none focus:outline-none border-none text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 text-sm font-medium'
						>
							Upgrade Now
						</button>
					</div>
				</div>
			</OverlayModal>
			{/* <PopUpModel open={props.open} title={props.title} close={props.close}>
				<div>
					<div className='text-center'>
						<img src={moneybag} className='Upgrade-img' />
					</div>
					<div className='text-center mt-4'>
						<div className='text-design'>
							Whoops! Your Subscription is Expired!
						</div>
					
					</div>
					<div className='text-center mt-4'>
						<Button
							variant='contained'
							className='text-Upgrade-button'
							onClick={() => {
								navigate('/profile', { state: { title: 'Subscription' } });
								props.close();
							}}
						>
							Upgrade Now!
						</Button>
					</div>
				</div>
			</PopUpModel> */}
		</>
	);
};

export default UpgradeNow;
