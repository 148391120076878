/** @format */

import NotificationManager from 'react-notifications/lib/NotificationManager';
import {
	buySubscription,
	getAllSubscriptionPlans,
	getSubscriptionPlan,
	postSubscriptionPlan,
	getSubscriptionFeatures,
	updateSubscriptionPlan,
	deleteSubscriptionPlan,
	getCurrentPlan,
	getCompanySubscriptionHistory,
	changeCompanySubscription,
	cancelSubscription,
} from '../APIs';
// import { formatDays } from "../HelperFunctions";

export const getAllSubscriptionReducer = async (
	id,
	flag,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getAllSubscriptionPlans(id);
		let formatedData = res.data.data;
		if (flag) {
			formatedData = formatedData.filter((item) => item.type === flag);
		}
		updateState(formatedData);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const getSubscriptionReducer = async (
	id,
	updateState,
	setSelectedOption,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getSubscriptionPlan(id);
		const item = res.data.data;
		updateState(item);

		setSelectedOption(
			item.features.map((item) => {
				return {
					value: item._id,
					label: item.description,
				};
			})
		);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const getSubscriptionFeaturesReducer = async (updateState) => {
	try {
		const res = await getSubscriptionFeatures();
		const formatedData = res.data.map((item) => {
			return {
				value: item._id,
				label: item.description,
			};
		});
		updateState(formatedData);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	}
};

export const postSubscriptionReducer = async (
	values,
	updateState,
	setRowLoading,
	setmodel,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await postSubscriptionPlan(values);
		if (res.data.message === 'SUBSCRIPTION CREATED SUCCESSFULLY') {
			NotificationManager.success(res.data.message);
			setmodel(false);
			// getAllSubscriptionReducer(updateState, setRowLoading);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const updateSubscriptionReducer = async (
	id,
	values,
	updateState,
	setRowLoading,
	setmodel,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await updateSubscriptionPlan(id, values);
		if (res.data.message === 'SUBSCRIPTION UPDATED SUCCESSFULLY') {
			NotificationManager.success(res.data.message);
			setmodel(false);
			// getAllSubscriptionReducer(updateState, setRowLoading);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const showOnHomePageReducer = async (
	payload,
	status,
	updateState,
	setIsLoading
) => {
	try {
		const res = await updateSubscriptionPlan(payload._id, {
			...payload,
			homepage: status === true ? true : false,
		});
		if (res.data.message === 'SUBSCRIPTION UPDATED SUCCESSFULLY') {
			NotificationManager.success(res.data.message);
			getAllSubscriptionReducer('', 'COMMON', updateState, setIsLoading);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
	}
};
export const deleteSubscriptionReducer = async (
	id,
	upDateState,
	setIsLoading
) => {
	try {
		const res = await deleteSubscriptionPlan(id);
		if (res.data.message === 'SUBSCRIPTION DELETED SUCCESSFULLY') {
			NotificationManager.success(res.data.message);
			// getAllSubscriptionReducer(upDateState, setIsLoading);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	}
};

export const buyPLan = async (payload, navigate, setIsLoading) => {
	setIsLoading(true);
	try {
		const res = await buySubscription(payload);
		debugger;
		if (res.data) {
			window.location = res.data.link;
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const getCurrentPlanReducer = async (
	updateState,
	setUpgradeNowModal
) => {
	try {
		const res = await getCurrentPlan();
		if (res.data) {
			updateState(res.data);
			if (res.data.isExpired) {
				setUpgradeNowModal(true);
			}
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	}
};
export const getCompanySubscriptionHistoryReducer = async (
	id,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getCompanySubscriptionHistory(id);
		if (res.data) {
			updateState(res.data);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const changeCompanySubscriptionReducer = async (
	id,
	sub_id,
	isAnnual
) => {
	try {
		const res = await changeCompanySubscription(id, sub_id, isAnnual);
		if (res.data) {
			NotificationManager.success(res.data);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	}
};
export const cancelSubscriptionReducer = async (
	values,
	callback,
	setIsLoading,
	logoutHandler
) => {
	setIsLoading(true);
	try {
		const res = await cancelSubscription(values);
		if (res.data) {
			NotificationManager.success(res.data);
			callback();
			logoutHandler();
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
