/** @format */

import React, { useContext, useEffect, useState } from 'react';
import OverlayModal from '../../Elements/Misc/Modal';
import { AuthContext } from '../../Context/AuthContext';
import Banner from '../../assests/image/banner.png';
import {
	DOWNLOAD_LEADS,
	FILTER_CONTACTS,
	UNLOCK_FIELD,
	GET_PROFILE,
} from '../../Context/Types';
import ReCAPTCHA from 'react-google-recaptcha';
import { CircularProgress } from '@mui/material';
import TableSkeltonLoader from '../../Elements/TableSkeltonLoader/TableSkeltonLoader';
import { createResizableColumn } from '../../helper/tableResizer';
import useAuth from '../../hooks/useAuth';
import debounce from 'debounce-promise';
import Pagination from './Pagination';
import { profileVisitDec } from '../../Context/APIs';

const DataTable = (props) => {
	const {
		dispatch,
		filter,
		setFilter,
		setSortField,
		sortField,
		setAllContacts,
		searchTerm,
		selectedLeads,
		setSelectedLeads,
		allContacts,
		setfilteredRows,
		profile,
		isLoadingDataTable,
		numOfRecordsFound,
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		sortColumn,
		setSortColumn,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		unlockLoading,
	} = useContext(AuthContext);

	const [searchValue, setSearchValue] = useState('');

	const [toggle, setToggle] = useState({
		fieldFilter: '',
		headerName: '',
		field: '',
	});
	const [viewEmail, setViewEmail] = useState(false);
	const [download, setDownload] = useState(true);
	const [width, setWidth] = useState({
		width: 0,
		pageX: 0,
		headerName: '',
	});
	const [lock, setLock] = useState([]);
	const [tooltip, setTooltip] = useState(false);
	const [sortValue, setSortValue] = useState('');
	const [selectall, setselectall] = useState([]);
	const [downloading, setDownloading] = useState(false);
	const [downloadId, setDownloadId] = useState('');
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);
	let captchaRef;
	const [profileData, setProfileData] = useState({});
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const [profileAcc, setProfileAcc] = useState(1);

	const toggleProfileDropdown = async (data) => {
		if (data) {
			setProfileData(data);
		} else {
			setTimeout(() => {
				setProfileData(data);
			}, 2000);
		}
		setIsProfileOpen(!isProfileOpen);
		setViewEmail(false);
		if (isProfileOpen === false) {
			await handleChangeProfileVisit();
		}
	};

	const handleChangeProfileVisit = async () => {
		if (
			profile?.plan?.subscription_type === 'Free Trail' &&
			profile?.profileVisit > 0
		) {
			await profileVisitDec();
			dispatch({ type: GET_PROFILE });
		}
	};

	useEffect(() => {
		if (!!allContacts || allContacts?.length > 0) {
			const table = document.getElementById('resizeMe');
			const cols = table?.querySelectorAll('th');
			if (table && cols) {
				[]?.forEach?.call(cols, function (col) {
					const resizer = document?.createElement('div');
					resizer?.classList?.add('resizer');
					resizer.style.height = `${table?.offsetHeight}px`;
					col.appendChild(resizer);
					createResizableColumn(col, resizer);
				});
			}
		}
	}, [allContacts]);

	const handleChangePage = (event, newPage) => {
		console.log(event, newPage);
		setIsCaptchaVerified(false);
		setCaptchaToken(null);
		setPage(newPage + 1);
	};

	const handlePageChange = (page) => {
		setIsCaptchaVerified(false);
		setCaptchaToken(null);
		setPage(page + 1);
	};

	const handleChangeRowsPerPage = (count) => {
		setRowsPerPage(count);
		setPage(1);
	};

	const handleSearchChange = (e) => {
		setSearchValue(e.target.value);
	};

	const handleFilter = (key) => {
		setFilter((prev) => {
			return { ...prev, [key.filterField]: [searchValue] };
		});
		dispatch({
			type: FILTER_CONTACTS,
		});
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setFirstTimeApiCall(false);
		setToggle({
			fieldFilter: '',
			headerName: '',
			field: '',
		});
	};

	const selectCheckbox = (data, checked) => {
		if (data === 'all') {
			if (checked) {
				setselectall(allContacts.map((d) => d._id));
				setSelectedLeads(allContacts.map((d) => d._id));
			} else {
				setselectall([]);
				setSelectedLeads([]);
			}
			return;
		}
		if (selectall.includes(data)) {
			if (!checked) {
				setselectall(selectall.filter((d) => d !== data));
				setSelectedLeads(selectall.filter((d) => d !== data));
			}
			return;
		}
		setselectall((prev) => [...prev, data]);
		setSelectedLeads((prev) => [...prev, data]); //global state to have selected leads
	};

	const getInitialsFromFullName = (fullName) => {
		if (!fullName) return '';

		const nameParts = fullName.split(' ');
		if (nameParts.length === 1) {
			return nameParts[0].charAt(0).toUpperCase();
		}

		const initials = nameParts[0].charAt(0) + nameParts[1].charAt(0);
		return initials.toUpperCase();
	};

	function getFieldName(toggle) {
		const fieldMap = {
			FullName: 'contact_name_sort',
			Specialty: 'specialty_sort',
			Hospital_Doctor: 'hospital_doctor_sort',
			State: 'state_sort',
			City: 'city_sort',
			SpecialtyGroup1: 'specialityGroup_sort',
			SpecialtyType: 'specialityType_sort',
			Title: 'title_sort',
			Department: 'department_sort',
			HospitalType: 'hospitalType_sort',
			WebAddress: 'webaddress_sort',
			BedsRange: 'bedrange_sort',
			Ownership: 'ownership_sort',
			FirmType: 'firmType_sort',
		};

		return fieldMap[toggle?.field] || '';
	}

	const sortRow = (asc, desc, toggle) => {
		let fieldName = getFieldName(toggle);
		if (asc) {
			setSortField({
				[fieldName]: 1,
			});
		}
		if (desc) {
			setSortField({
				[fieldName]: -1,
			});
		}
		// dispatch({
		// 	type: FILTER_CONTACTS,
		// });
		// setToggle({
		// 	fieldFilter: '',
		// 	headerName: '',
		// 	field: '',
		// });
		//setAllContacts(row);
	};

	useEffect(() => {
		if (sortColumn.field === toggle?.field) {
			if (sortColumn.direction === '0') {
				setSortValue(true);
			} else {
				setSortValue(false);
			}
		} else {
			setSortValue('');
		}
	}, [sortColumn, toggle]);

	const getAllContacts = debounce(() => {
		dispatch({
			type: FILTER_CONTACTS,
		});
	}, 300);

	useEffect(() => {
		getAllContacts();
	}, [rowsPerPage, page]);

	const [_idLoad, set_idLoad] = useState('');
	const { auth } = useAuth();

	const UnlockFilled = (_id, clickedOn) => {
		if (!unlockLoading) {
			set_idLoad(_id);
			dispatch({
				type: UNLOCK_FIELD,
				payload: _id,
				clickedOn: clickedOn,
				setLock: setLock,
			});
		}
	};

	const onChangeCaptcha = (value) => {
		setIsCaptchaVerified(true);
		setCaptchaToken(value);
	};

	const formatPhoneNumber = (str) => {
		if (typeof str !== 'string' || str.length <= 3) return str;
		return str.slice(0, 3) + '*'.repeat(str.length - 3);
	};

	const formatEmail = (str) => {
		if (
			typeof str !== 'string' ||
			str.trim() === '' ||
			str.toLowerCase() === 'null'
		)
			return str;

		const [localPart, domain] = str.split('@');
		if (!domain) return str;
		const [domainName, topLevelDomain] = domain.split('.');
		if (!topLevelDomain) return str;

		const obscuredLocalPart = localPart[0] + '*'.repeat(localPart.length - 1);
		const obscuredDomainName =
			domainName[0] + '*'.repeat(domainName.length - 1);

		return `${obscuredLocalPart}@${obscuredDomainName}.${topLevelDomain}`;
	};

	const formatEmptyEmail = (fullName) => {
		if (
			typeof fullName !== 'string' ||
			fullName.trim() === '' ||
			fullName.toLowerCase() === 'null'
		)
			return fullName;

		const trimmedName = fullName.trim().toLowerCase();
		const firstLetter = trimmedName.charAt(0);
		const obscuredPart = '*'.repeat(trimmedName.length - 1);

		// Randomly select a domain segment
		const domainSegments = ['g', 'y', 'h'];
		const randomDomainSegment =
			domainSegments[Math.floor(Math.random() * domainSegments.length)];

		return `${firstLetter}${obscuredPart}@${randomDomainSegment}****.com`;
	};

	const formatKey = (key) => {
		if (key === 'Hospital_Doctor') {
			return 'HOSPITAL / CLINIC';
		} else if (key === 'FaxNumber') {
			return 'FAX NUMBER';
		} else if (key === 'NPINumber') {
			return 'NPI NUMBER';
		} else if (key === 'degree') {
			return 'CREDENTIAL';
		} else if (key === 'ISP') {
			return 'ISP';
		} else if (key === 'LevelID') {
			return 'LEVEL ID';
		} else if (key === 'CBSA') {
			return 'CBSA';
		} else if (key === 'FIPS_County_Code') {
			return 'FIPS COUNTRY CODE';
		} else if (key === 'NumberOFBeds') {
			return 'NUMBER OF BEDS';
		} else if (key === 'Office_Type') {
			return 'PRACTICE TYPE';
		}
		const result = key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1'); // Add space before each capital letter
		return result.toUpperCase(); // Capitalize the first letter
	};

	const transformValue = (key, value) => {
		if (key === 'Gender') {
			return value === 'M' ? 'Male' : value === 'F' ? 'Female' : value;
		}
		return value;
	};

	const renderKeyValuePairs = (data, keysToShow) => {
		if (data.Type === '1') {
			keysToShow = keysToShow.filter(
				(key) =>
					![
						'Specialty',
						'SpecialtyGroup1',
						'SpecialtyGroup2',
						'SpecialtyType',
					].includes(key)
			);
		}

		return Object.entries(data)
			.filter(
				([key, value]) =>
					keysToShow.includes(key) &&
					value !== '' &&
					value !== 'NULL' &&
					value !== null &&
					value !== undefined
			)
			.map(([key, value]) => (
				<div key={key} className='flex'>
					<p className='text-xs text-gray-700 font-medium w-44'>{`${formatKey(
						key
					)}: `}</p>
					<p className='text-xs text-gray-900 font-medium ml-1 text-wrap w-60'>
						{transformValue(key, value)}
					</p>
				</div>
			));
	};

	const KeyValueComponent = ({ data, keys }) => {
		return <div>{renderKeyValuePairs(data, keys)}</div>;
	};

	const personalKeys = [
		'FaxNumber',
		'Gender',
		'degree',
		'Specialty',
		'Hospital_Doctor',
		'WebAddress',
		'ISP',
		'EnumerationDate',
		'TaxonomyCode',
		'LicenseNumber',
		'LicenseIssueState',
		'FirmType',
		'HospitalType',
		'CompanyStatus',
		'Ownership',
		'IDN_Integration_Level',
		'CBSA',
		'Geographic_Classification',
		'FIPS_County_Code',
		'Market_Concentration_Index',
		'Accreditation_Agency',
		'Average_Daily_Census',
		'Sole_Community_Hospital',
		'Medicare_Administrative_Contractors',
		'BedsRange',
		'NumberOFBeds',
		'Office_Type',
	];

	const otherKeys = {
		'ICD 10 Codes (International Classification of Diseases)': 'Coming Soon!',
		'HCPCS/CPT Code (Current Procedural Terminology)': 'Coming Soon!',
		'Total Claims': 'Coming Soon!',
		'Total Prescribed Days': 'Coming Soon!',
		'Total Drug Cost': 'Coming Soon!',
		'Prescribing Drug Name': 'Coming Soon!',
		'Medicare/Medicaid': 'Coming Soon!',
	};

	return (
		<>
			{isLoadingDataTable ? (
				<div
					className='relative'
					style={{ overflow: 'hidden', height: '72vh' }}
				>
					<TableSkeltonLoader countRow={16} />
				</div>
			) : (
				<div
					className='relative'
					style={{ height: '72vh', overflow: 'auto', scrollbarWidth: 'none' }}
				>
					<div className='flex flex-col'>
						<div
							className='-m-1.5 overflow-x-auto'
							style={{ scrollbarWidth: 'none' }}
						>
							<div className='p-1.5 min-w-full inline-block align-middle'>
								<div className='overflow-hidden'>
									<table class='min-w-full'>
										<thead class='bg-gray-300 sticky top-0 z-1'>
											<tr>
												<th scope='col' class='px-2 text-start'>
													<div className='custom-checkbox'>
														<input
															type='checkbox'
															checked={
																selectall?.length !== 0 &&
																selectall?.length === allContacts?.length
															}
															onClick={(e) => {
																selectCheckbox('all', e.target.checked);
															}}
														/>
														<div class='check'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																class='w-3 h-3 mb-1'
																viewBox='0 0 20 20'
																fill='currentColor'
																stroke='currentColor'
																stroke-width='1'
															>
																<path
																	fill-rule='evenodd'
																	d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																	clip-rule='evenodd'
																></path>
															</svg>
														</div>
													</div>
												</th>

												{props?.columns?.map((data) => {
													if (data?.field === '_id' || data?.hide === true)
														return null;
													else
														return (
															<th
																scope='col'
																class={`px-2 whitespace-nowrap ${
																	data?.headerName === 'Action'
																		? 'text-center'
																		: 'text-start'
																}`}
																key={data?.headerName}
															>
																<span
																	onClick={() => {
																		if (
																			data?.headerName !== 'Action' &&
																			data?.headerName !== 'Phone' &&
																			data?.headerName !== 'Email'
																		) {
																			setToggle({
																				filterField: data?.filterField,
																				headerName: data?.headerName,
																				field: data?.field,
																			});
																		}
																	}}
																	class={`text-xs font-semibold uppercase text-gray-800 ${
																		data?.headerName !== 'Action' &&
																		data?.headerName !== 'Phone' &&
																		data?.headerName !== 'Email'
																			? 'cursor-pointer hover:text-blue-600'
																			: ''
																	}`}
																>
																	{data.headerName}
																</span>
															</th>
														);
												})}
											</tr>
										</thead>

										<tbody>
											{allContacts?.length > 0 ? (
												allContacts?.map((row) => (
													<tr
														id='step9'
														key={row?._id}
														className={`font-medium border-b border-gray-300 cursor-pointer ${
															selectall?.includes(row?._id) ||
															selectall?.includes('all') ||
															profileData?._id === row?._id
																? 'bg-blue-100'
																: 'hover:bg-gray-100'
														}`}
													>
														<td class='text-start px-2 py-1.5 w-8'>
															<div className='custom-checkbox'>
																<input
																	type='checkbox'
																	checked={
																		selectall?.includes(row?._id) ||
																		selectall?.includes('all')
																	}
																	onChange={(e) => {
																		selectCheckbox(row?._id, e.target.checked);
																	}}
																/>
																<div class='check'>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		class='w-3 h-3 mb-1'
																		viewBox='0 0 20 20'
																		fill='currentColor'
																		stroke='currentColor'
																		stroke-width='1'
																	>
																		<path
																			fill-rule='evenodd'
																			d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																			clip-rule='evenodd'
																		></path>
																	</svg>
																</div>
															</div>
														</td>

														{props?.columns?.map((key) => {
															if (key?.field === '_id' || key?.hide === true)
																return null;
															else if (key?.field === 'Action') {
																return (
																	<td class='size-px whitespace-nowrap'>
																		<div
																			class='mx-auto py-1.5'
																			onClick={() => {
																				if (!downloading) {
																					setDownloadId(row?._id);
																					dispatch({
																						type: DOWNLOAD_LEADS,
																						payload: {
																							leads_ids: [row._id],
																							verifyAll: false,
																							downloadName: `Single - ${new Date().toISOString()}`,
																							isVerify: true,
																						},
																						close: () => {},
																						setIsLoading: setDownloading,
																						setDownload,
																					});
																					UnlockFilled(row._id, 'email');
																				}
																			}}
																		>
																			{downloading &&
																			downloadId === row?._id ? (
																				<CircularProgress
																					size={12}
																					style={{ color: '#2F5EFF' }}
																				/>
																			) : (
																				<svg
																					xmlns='http://www.w3.org/2000/svg'
																					width='24'
																					height='24'
																					viewBox='0 0 24 24'
																					fill='none'
																					stroke='currentColor'
																					stroke-width='2'
																					stroke-linecap='round'
																					stroke-linejoin='round'
																					class='mx-auto cursor-pointer text-blue-600 w-4 h-4'
																				>
																					<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
																					<polyline points='7 10 12 15 17 10' />
																					<line
																						x1='12'
																						x2='12'
																						y1='15'
																						y2='3'
																					/>
																				</svg>
																			)}
																		</div>
																	</td>
																);
															} else if (key?.field === 'PhoneNumber') {
																return (
																	<td
																		onClick={() => toggleProfileDropdown(row)}
																		class='size-px whitespace-nowrap px-2 py-1.5 text-xs'
																	>
																		<div className='text-start text-gray-800 relative'>
																			{formatPhoneNumber(
																				String(row[key?.field])
																			)}
																		</div>
																	</td>
																);
															} else if (key?.field === 'EmailAddress') {
																if (String(row[key?.field]) === '') {
																	return (
																		<td
																			onClick={() => toggleProfileDropdown(row)}
																			class='size-px whitespace-nowrap px-2 py-1.5 text-xs'
																		>
																			<div className='text-start text-gray-800 relative'>
																				{formatEmptyEmail(row['FullName'])}
																			</div>
																		</td>
																	);
																} else {
																	return (
																		<td
																			onClick={() => toggleProfileDropdown(row)}
																			class='size-px whitespace-nowrap px-2 py-1.5 text-xs'
																		>
																			<div className='text-start text-gray-800 relative'>
																				{formatEmail(String(row[key?.field]))}
																			</div>
																		</td>
																	);
																}
															}
															// else if (key?.field === 'EmailAddress') {
															// 	return (
															// 		<td class='size-px text-start whitespace-nowrap px-2 py-1.5 text-xs'>
															// 			{lock?.filter((d) => d?._id === row?._id).length ? (
															// 				!lock.filter((d) => d?._id === row?._id)[0]
															// 					.debounceCode ||
															// 				lock?.filter((d) => d?._id === row?._id)[0]
															// 					?.debounceCode === '1' ||
															// 				lock?.filter((d) => d?._id === row?._id)[0]
															// 					?.debounceCode === '2' ? (
															// 					<div
															// 						onClick={() => {
															// 							navigator.clipboard.writeText(
															// 								lock.filter((d) => d._id === row._id)[0]
															// 									.EmailAddress
															// 							);
															// 							NotificationManager.info('Copied!');
															// 						}}
															// 						className='flex gap-1 items-center text-blue-600 cursor-pointer'
															// 					>
															// 						<svg
															// 							xmlns='http://www.w3.org/2000/svg'
															// 							width='24'
															// 							height='24'
															// 							viewBox='0 0 24 24'
															// 							fill='none'
															// 							stroke='currentColor'
															// 							stroke-width='2'
															// 							stroke-linecap='round'
															// 							stroke-linejoin='round'
															// 							class='w-3 h-3 text-blue-600'
															// 						>
															// 							<rect
															// 								width='8'
															// 								height='4'
															// 								x='8'
															// 								y='2'
															// 								rx='1'
															// 								ry='1'
															// 							/>
															// 							<path d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2' />
															// 						</svg>
															// 						{String(
															// 							lock.filter((d) => d._id === row._id)[0]
															// 								.EmailAddress
															// 						).length > 20
															// 							? String(
															// 									lock.filter((d) => d._id === row._id)[0]
															// 										.EmailAddress
															// 							  ).slice(0, 20) + '...'
															// 							: String(
															// 									lock.filter((d) => d._id === row._id)[0]
															// 										.EmailAddress
															// 							  )}
															// 					</div>
															// 				) : unlockLoading &&
															// 				  String(_idLoad) === String(row?._id) ? (
															// 					<CircularProgress
															// 						size={12}
															// 						style={{ color: '#2F5EFF' }}
															// 					/>
															// 				) : (
															// 					<div className='text-red-600 cursor-default'>
															// 						Invalid Email
															// 					</div>
															// 				)
															// 			) : unlockLoading &&
															// 			  String(_idLoad) === String(row?._id) ? (
															// 				<CircularProgress
															// 					size={12}
															// 					style={{ color: '#2F5EFF' }}
															// 				/>
															// 			) : row?.isUnlocked ? (
															// 				row?.EmailAddress
															// 			) : (
															// 				<span
															// 					onClick={() => {
															// 						if (
															// 							lock?.filter((d) => d?._id === row?._id)
															// 								?.length
															// 						)
															// 							return;
															// 						if (!lock.some((d) => d?._id === row?._id)) {
															// 							UnlockFilled(row?._id, 'email');
															// 						}
															// 					}}
															// 					className='text-blue-600 hover:underline hover:underline-offset-2 cursor-pointer'
															// 				>
															// 					Verify Email
															// 				</span>
															// 			)}
															// 		</td>
															// 	);
															// }
															// else if (key?.field === 'PhoneNumber') {
															// 	return (
															// 		<td class='size-px text-start whitespace-nowrap px-2 py-1.5 text-xs'>
															// 			{lock?.filter((d) => d?._id === row?._id)
															// 				?.length ? (
															// 				!lock?.filter((d) => d?._id === row?._id)[0]
															// 					?.PhoneNumber?.length ||
															// 				lock?.filter((d) => d?._id === row?._id)[0]
															// 					?.PhoneNumber?.length === 0 ? (
															// 					'-'
															// 				) : (
															// 					<div
															// 						onClick={() => {
															// 							navigator.clipboard.writeText(
															// 								lock.filter((d) => d?._id === row?._id)[0]
															// 									?.PhoneNumber
															// 							);
															// 							NotificationManager.info('Copied!');
															// 						}}
															// 						className='flex items-center gap-1 cursor-pointer text-blue-600'
															// 					>
															// 						<svg
															// 							xmlns='http://www.w3.org/2000/svg'
															// 							width='24'
															// 							height='24'
															// 							viewBox='0 0 24 24'
															// 							fill='none'
															// 							stroke='currentColor'
															// 							stroke-width='2'
															// 							stroke-linecap='round'
															// 							stroke-linejoin='round'
															// 							class='w-3 h-3 text-blue-600'
															// 						>
															// 							<rect
															// 								width='8'
															// 								height='4'
															// 								x='8'
															// 								y='2'
															// 								rx='1'
															// 								ry='1'
															// 							/>
															// 							<path d='M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2' />
															// 						</svg>
															// 						{String(
															// 							lock?.filter((d) => d?._id === row?._id)[0]
															// 								?.PhoneNumber
															// 						)}
															// 					</div>
															// 				)
															// 			) : unlockLoading &&
															// 			  String(_idLoad) === String(row?._id) ? (
															// 				<CircularProgress
															// 					size={12}
															// 					style={{ color: '#2F5EFF' }}
															// 				/>
															// 			) : (
															// 				<span
															// 					onClick={() => {
															// 						if (
															// 							lock?.filter((d) => d?._id === row?._id)
															// 								?.length
															// 						)
															// 							return;
															// 						if (!lock?.some((d) => d?._id === row?._id)) {
															// 							UnlockFilled(row?._id, 'phone');
															// 						}
															// 					}}
															// 					className='cursor-pointer text-blue-600 hover:underline hover:underline-offset-2'
															// 				>
															// 					Verify Phone
															// 				</span>
															// 			)}
															// 		</td>
															// 	);
															// }
															else if (
																!row[key?.field] ||
																row[key?.field] === 'NULL'
															) {
																return (
																	<td
																		onClick={() => toggleProfileDropdown(row)}
																		class='size-px whitespace-nowrap px-2 py-1.5 text-xs text-gray-800'
																	></td>
																);
															} else
																return (
																	<td
																		id='openpro'
																		onClick={() => toggleProfileDropdown(row)}
																		class='size-px whitespace-nowrap px-2 py-1.5 text-xs'
																	>
																		{/* <CustomTooltip data={row[key?.field]} /> */}
																		<div className='text-start text-gray-800 relative'>
																			{String(row[key?.field]).length > 15
																				? String(row[key?.field]).slice(0, 15) +
																				  '...'
																				: String(row[key?.field])}
																		</div>
																	</td>
																);
														})}
													</tr>
												))
											) : (
												<div className='flex justify-center items-center h-[20vh]'>
													<div>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'
															stroke='currentColor'
															stroke-width='2'
															stroke-linecap='round'
															stroke-linejoin='round'
															className='w-6 h-6 mx-auto text-gray-600'
														>
															<path d='m2 2 20 20' />
															<path d='M8.35 2.69A10 10 0 0 1 21.3 15.65' />
															<path d='M19.08 19.08A10 10 0 1 1 4.92 4.92' />
														</svg>
														<h1 className='text-base font-semibold text-gray-800 mt-2'>
															No Data
														</h1>
													</div>
												</div>
											)}
										</tbody>
									</table>
									{isCaptchaVerified || Number(page) % 3 !== 0 ? (
										<></>
									) : (
										Number(page) % 3 === 0 && (
											<div className='absolute top-0 w-full h-full backdrop-blur flex justify-center items-center'>
												<ReCAPTCHA
													ref={(r) => {
														if (r) {
															return (captchaRef = r);
														}
													}}
													sitekey='6Le3ILclAAAAAEOX1VwVcAPdkfMO61jhjCGFoAr5'
													onChange={onChangeCaptcha}
												/>
											</div>
										)
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<Pagination
				rowsPerPageOptions={[15, 20, 25]}
				count={numOfRecordsFound}
				rowsPerPage={rowsPerPage}
				page={page - 1}
				disable={isCaptchaVerified || Number(page) % 3 !== 0}
				handlePageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>

			<OverlayModal
				isOpen={!(toggle?.headerName === '') ? true : false}
				onClose={() => {
					setTimeout(() => {
						setToggle({
							fieldFilter: '',
							headerName: '',
							field: '',
						});
					}, 300);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Table Filter "{toggle?.headerName}"
						</h3>
						<div
							onClick={() => {
								setTimeout(() => {
									setToggle({
										fieldFilter: '',
										headerName: '',
										field: '',
									});
								}, 300);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<div className='mt-4'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Choose Sorting:
						</h2>
						<ul class='flex mt-1'>
							<li class='inline-flex items-center gap-x-2.5 py-1.5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg'>
								<div
									onClick={() => {
										sortRow(true, '', toggle);
										setSortColumn({
											field: toggle?.field,
											direction: '0',
										});
									}}
									class='relative flex gap-2 items-center w-full cursor-pointer'
								>
									<div class='flex items-center'>
										<input
											id='hs-horizontal-list-group-item-radio-3'
											name='hs-horizontal-list-group-item-radio'
											type='radio'
											checked={sortValue === true}
											class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
										/>
									</div>
									<p
										for='hs-horizontal-list-group-item-radio-3'
										class='mr-2 text-xs text-gray-800'
									>
										Ascending
									</p>
								</div>
							</li>
							<li class='inline-flex items-center gap-x-2.5 py-1.5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg'>
								<div
									onClick={() => {
										sortRow('', true, toggle);
										setSortColumn({
											field: toggle?.field,
											direction: '1',
										});
									}}
									class='relative flex gap-2 items-center w-full cursor-pointer'
								>
									<div class='flex items-center'>
										<input
											id='hs-radio-1'
											name='hs-horizontal-list-group-item-radio'
											type='radio'
											checked={sortValue === false}
											class='!border-gray-300 cursor-pointer rounded-full disabled:opacity-50'
										/>
									</div>
									<p for='hs-radio-1' class='mr-2 text-xs text-gray-800'>
										Descending
									</p>
								</div>
							</li>
						</ul>
					</div>
					<div className='mt-3'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Search:
						</h2>
						<input
							type='text'
							value={searchValue}
							onChange={(e) => handleSearchChange(e)}
							placeholder='Search...'
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs disabled:opacity-90'
						/>
					</div>
					<div className='flex items-center justify-end gap-2 mt-3'>
						<button
							onClick={() => {
								setSortField({});
								setSortValue();
								setSortColumn({});
								setToggle({
									fieldFilter: '',
									headerName: '',
									field: '',
								});
							}}
							className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
						>
							Reset
						</button>
						<button
							onClick={() => {
								handleFilter(toggle);
							}}
							// disabled={!searchValue || sortCol.field}
							className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
						>
							Search
						</button>
					</div>
				</div>
			</OverlayModal>

			{isProfileOpen && (
				<div
					className='fixed inset-0 bg-slate-900 opacity-60 z-50'
					onClick={toggleProfileDropdown}
				></div>
			)}
			<div
				id='hs-overlay-top'
				className={`hs-overlay ${
					isProfileOpen ? 'translate-x-0' : 'translate-x-full'
				} fixed top-0 right-0 transition-all duration-300 transform min-h-screen w-1/3 z-max bg-white border-b`}
				tabIndex='-1'
			>
				<div className='flex justify-between items-center py-2 px-3 border-b'>
					<h3 className='font-bold text-sm text-gray-800'>Profile Details</h3>
					<button
						type='button'
						onClick={toggleProfileDropdown}
						className='flex justify-center items-center hover:border-none outline-none focus:outline-none w-5 h-5 text-sm font-semibold rounded-lg border-none bg-gray-200 text-gray-800 hover:bg-gray-300 disabled:opacity-50 disabled:pointer-events-none'
						data-hs-overlay='#hs-overlay-top'
					>
						<span className='sr-only'>Close modal</span>
						<svg
							className='flex-shrink-0 w-3 h-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						>
							<path d='M18 6 6 18' />
							<path d='m6 6 12 12' />
						</svg>
					</button>
				</div>
				{profile?.profileVisit <= 0 ? (
					<div className='mx-auto px-2 my-10'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-8 h-8 text-gray-600 mx-auto'
						>
							<path d='M16 18a4 4 0 0 0-8 0' />
							<circle cx='12' cy='11' r='3' />
							<rect width='18' height='18' x='3' y='4' rx='2' />
							<line x1='8' x2='8' y1='2' y2='4' />
							<line x1='16' x2='16' y1='2' y2='4' />
						</svg>
						<h1 className='text-sm font-semibold text-gray-800 mt-3'>
							Reached your daily limit
						</h1>
						<p className='text-gray-700 text-xs font-medium px-3 mt-1'>
							You've viewed all your profiles for today. Come back tomorrow! or
							Upgrade your account to continue browsing profiles.
						</p>
					</div>
				) : (
					<div
						className='px-3 my-2 text-start overflow-y-auto'
						style={{ height: '90vh', userSelect: 'none' }}
					>
						{profile?.plan?.subscription_type === 'Free Trail' && (
							<div class='bg-blue-100 border-blue-400 text-sm text-blue-600 rounded-lg px-2 py-2 mb-3'>
								<div class='flex items-start gap-2'>
									<svg
										class='flex-shrink-0 w-3 h-3 mt-0.5'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									>
										<circle cx='12' cy='12' r='10'></circle>
										<path d='M12 16v-4'></path>
										<path d='M12 8h.01'></path>
									</svg>

									<p class='text-xs text-start font-medium'>
										You have <strong>{profile?.profileVisit}</strong> profile
										views left for today.
									</p>
								</div>
							</div>
						)}
						<div className='relative'>
							<img src={Banner} alt='banner' className='rounded-lg' />
							<div className='absolute -bottom-14 left-1 flex items-end gap-2'>
								<div
									style={{ width: '5rem' }}
									className='border-2 border-white bg-blue-600 w-20 px-4 h-20 rounded-full text-white text-lg font-semibold flex justify-center items-center'
								>
									{getInitialsFromFullName(profileData?.FullName)}
								</div>
								<div className='pb-2 flex gap-8 items-center justify-between'>
									<div>
										<h1 className='text-start text-base text-gray-800 font-semibold'>
											{profileData?.FullName}
										</h1>
										<p className='text-start text-gray-600 text-xs font-medium'>
											{profileData?.Type === '0'
												? profileData?.Specialty
												: profileData?.Title}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className='mt-20 text-xs'>
							<div className='flex items-center gap-x-8 gap-y-2 flex-wrap'>
								<div className='flex items-center gap-2 text-gray-800 font-medium'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-4 h-4'
									>
										<rect width='20' height='16' x='2' y='4' rx='2' />
										<path d='m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7' />
									</svg>
									{viewEmail ? (
										profileData?.EmailAddress === '' ||
										profileData?.EmailAddress === 'NULL' ? (
											'Bad Email'
										) : (
											profileData?.EmailAddress
										)
									) : (
										<p
											onClick={() => setViewEmail(true)}
											className='text-xs text-blue-600 hover:underline cursor-pointer'
										>
											View Email
										</p>
									)}
								</div>
								{profileData?.PhoneNumber && (
									<div className='flex items-center gap-2 text-gray-800 font-medium'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-4 h-4'
										>
											<path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z' />
										</svg>
										{profileData?.PhoneNumber}
									</div>
								)}
							</div>
							<div className='mt-1'>
								{profileData?.NPINumber &&
									profileData?.NPINumber !== 'NULL' && (
										<div className='flex items-center gap-2 text-gray-800 font-medium'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												class='w-4 h-4'
											>
												<rect width='18' height='18' x='3' y='3' rx='2' />
												<path d='M17 12h-2l-2 5-2-10-2 5H7' />
											</svg>
											<p>
												<strong>NPI: </strong>
												{profileData?.NPINumber}
											</p>
										</div>
									)}
							</div>
							<div
								className='my-3 bg-slate-300 w-full'
								style={{ height: '1px' }}
							/>
							<div
								onClick={() => setProfileAcc(profileAcc === 1 ? 0 : 1)}
								className='flex cursor-pointer justify-between items-center gap-4 w-full text-sm font-semibold text-gray-800'
							>
								All Details
								{profileAcc !== 1 ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-4 h-4'
									>
										<path d='M5 12h14' />
										<path d='M12 5v14' />
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-4 h-4'
									>
										<path d='M5 12h14' />
									</svg>
								)}
							</div>
							{profileAcc === 1 && (
								<div className='mt-2'>
									<div className='flex'>
										<p className='text-xs text-gray-700 font-medium w-44'>
											ADDRESS:
										</p>
										<p className='text-xs text-gray-900 font-medium ml-1 text-wrap w-60'>
											{[
												profileData?.Address1,
												profileData?.Address2,
												profileData?.City,
												profileData?.State,
												profileData?.Country,
												profileData?.ZIPCode,
											]
												.filter((item) => item && item !== 'NULL')
												.join(', ')}
										</p>
									</div>
									<KeyValueComponent data={profileData} keys={personalKeys} />
								</div>
							)}
							<div
								className='my-3 bg-slate-300 w-full'
								style={{ height: '1px' }}
							/>
							<div
								onClick={() => setProfileAcc(profileAcc === 2 ? 0 : 2)}
								className='flex cursor-pointer justify-between items-center gap-4 w-full text-sm font-semibold text-gray-800'
							>
								Other Intelligence (Coming Soon)
								{profileAcc !== 2 ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-4 h-4'
									>
										<path d='M5 12h14' />
										<path d='M12 5v14' />
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-4 h-4'
									>
										<path d='M5 12h14' />
									</svg>
								)}
							</div>
							{profileAcc === 2 && (
								<div className='mt-2'>
									{Object.entries(otherKeys).map(([key, value]) => (
										<div key={key} className='flex'>
											<p className='text-xs text-gray-700 font-medium w-60'>
												{key}:
											</p>
											<p className='text-xs text-gray-900 font-medium ml-1 text-wrap w-40'>
												{value}
											</p>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
export default DataTable;
