/** @format */

export const LOGIN_USER = 'login user';
export const SIGNUP_USER = 'signup user';
export const RESEND_EMAIL = 'resend email';
export const FORGET_PASSWORD = 'forget password';
export const GET_ALL_CONTACTS = 'get all contacts';
export const FILTER_CONTACTS = 'filter contacts';
export const FILTER_CONTACTS_STATS = 'filter contacts stats';
export const FILTER_CONTACTS_STATS2 = 'filter contacts stats22';
export const GET_ALL_TEAM_MEMBERS = 'team members';
export const INVITE_TEAM_MEMBER = 'invite team member';
export const GET_SUBSCRIPTION = 'get subscription';
export const GET_DOWLOAD_LEADS = 'get download leads';
export const GET_DOWNLOAD_QUEUE = 'get download queue';
export const GET_ONE_DOWLOAD_LIST = 'get one download list';
export const CREATE_SUBSCRIPTION = 'create subscription';
export const UPDATE_SUBSCRIPTION = 'update subscription';
export const DELETE_SUBSCRIPTION = 'delete subscription';
export const HOME_PAGE_SHOW = 'show on homepage';
export const GET_SUBSCRIPTION_FEATURES = 'get subscription features';
export const GET_ALL_CATEGORIES = 'get all categories';
export const GET_ACTIVE_CATEGORIES = 'get active categories';
export const GET_CATEGORY = 'get category';
export const CREATE_CATEGORY = 'create category';
export const UPDATE_CATEGORY = 'update category';
export const DELETE_CATEGORY = 'delete category';
export const GET_SAVE_SEARCH = 'get save search';
export const DELETE_SAVE_SEARCH = 'delete save search';
export const SAVE_SEARCH_FILTER = 'save search filter';
export const BUY_PLAN = 'buy plan';
export const UNLOCK_FIELD = 'unlock field';
export const UNLOCK_MY_LIST_FIELD = 'unlock my field';
export const DOWNLOAD_LEADS = 'download leads';
export const DOWNLOAD_LEADS_SPECIFIC = 'download leads specific';
export const CHANGE_PASSWORD = 'change password';
export const CREATE_LIST = 'create list';
export const GET_ALL_LIST = 'get all list';
export const ADD_ITEM_TO_LIST = 'add leads to my list';
export const QUICK_SEARCH = 'quick search';
export const DELETE_LIST = 'delete list';
export const RESET_PASSWORD = 'reset password';
export const UPLOAD_LEAD_CSV = 'upload lead csv';
export const GET_LEAD_BY_CATEGORY = 'get lead by category';
export const GET_COMPANY_BY_TYPE = 'get company by type';
export const GET_COMPANY_INFO_ADMIN = 'get company info admin';
export const GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN =
	'get company info another format admin';
export const EDIT_COMPANY_INFO_ADMIN = 'edit company info admin';
export const GET_COMPANY_CREDITS_ADMIN = 'get company credits admin';
export const INVITE_COMPANY_MEMBER_ADMIN = 'invite company member admin';
export const GET_COMPANY_MEMBERS_ADMIN = 'get company members admin';
export const GET_COMPANY_ACTIVITY_LOGS_ADMIN =
	'get company activity logs admin';
export const EDIT_LIST_NAME = 'edit list name';
export const GET_CREDITS = 'get credits';
export const TEAM_SIGNUP = 'team signup';
export const GET_ALL_LIST_CONTACTS = 'get leads from my list';
export const GET_MEMBER = 'get invited team member';
export const GET_PROFILE = 'get profile';
export const EDIT_PROFILE = 'edit profile';
export const CREATE_AND_SAVE_ITEM_TO_LIST = 'create and save item to list';
export const GET_UNLOCKED_LEADS = 'get unlocked leads';
export const REQUEST_CREDITS = 'request credits';
export const GET_MEMBER_CREDITS_REQUEST = 'member request';
export const GET_COMPANY_CREDITS_REQUEST = 'company request';
export const GET_COMPANY_CREDITS_REQUEST_ADMIN = 'company request admin';
export const GET_ACTIVITY_LOGS = 'get activity logs';
export const GET_ALL_SUBSCRIPTION_PLAN = 'get all subscription plans';
export const GET_TRANSACTIONS = 'get transactions';
export const GET_COMPANY_TRANSACTIONS_ADMIN = 'get company transactions admin';
export const UPLOAD_EXCLUSION_LEADS = 'upload exclusion leads';
export const GET_EXCLUSION_LIST = 'get exclusion list';
export const GET_EXCLUSION_LEADS = 'get exclusion leads';
export const DELETE_EXCLUSION = 'delete exclusion';
export const CREATE_COMPANY_ADMIN = 'create company admin';
export const GET_INVOICES = 'get invoices';
export const GET_INVOICES_ADMIN = 'get invoices admin';
export const ACCEPT_MEMBER_CREDIT_REQUEST = 'accept member credit request';
export const DENY_MEMBER_CREDIT_REQUEST = 'deny member credit request';
export const DELETE_MEMBER = 'delete member';
export const DELETE_INVITE = 'delete invite';
export const DELETE_INVITE_ADMIN = 'delete invite admin';
export const ASSIGN_CREDITS = 'assign credits';
export const ASSIGN_CREDITS_TO_COMPANY = 'assign credits to company';
export const ASSIGN_USERS_TO_COMPANY = 'assign users to company';
export const REVOKE_CREDITS = 'revoke credits';
export const GET_CURRENT_PLAN = 'get current plan';
export const BLOCK_UNBLOCK_MEMBER = 'block unblock member';
export const BLOCK_UNBLOCK_COMPANY = 'block unblock company';
export const SUSPEND_UNSUSPEND_COMPANY = 'suspend unsuspend company';
export const BUY_MORE_CREDITS = 'buy more credits';
export const POST_POTENTIAL_CUSTOMERS = 'post potential customers';
export const GET_POTENTIAL_CUSTOMERS = 'get potential customers';
export const PAY_INVOICE = 'pay invoice';
export const MARK_AS_PAID_INVOICE = 'mark as paid invoice';
export const GET_DASHBOARD_DATA = 'get dashboard data';
export const GET_ALL_COMPANY = 'get all company';
export const VERIFY_COMPANY_EMAIL = 'verify company email';
export const CHANGE_COMPANY_SUBSCRIPTION = 'change company subscription';
export const CANCEL_SUBSCRIPTION = 'cancel subscription';
export const CREATE_SUB_ADMIN = 'create sub admin';
export const GET_ALL_SUB_ADMIN = 'get all sub admin';
export const GET_SUB_ADMIN = 'get sub admin';
export const DELETE_SUB_ADMIN = 'delete sub admin';
export const GET_ADMIN_TABS = 'get admin tabs';
export const EDIT_SUB_ADMIN = 'edit sub admin';
export const EXTEND_FREE_TRIAL = 'extend free trial';
export const GET_COMPANY_SUBSCRIPTION_HISTORY =
	'get company subscription history';
export const GET_MEMBER_CREDITS_REQUEST_ADMIN =
	'get member credits request admin';
export const GET_UNPAID_INVOICE_NOTIFICATION =
	'get unpaid invoice notification';
export const GET_MEMBER_ACTIVITY_LOG = 'get member activity log';
export const LOG_OUT = 'log out';
